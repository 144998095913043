// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Molecule_Sticker from "../../molecule/Molecule_Sticker.res.mjs";
import * as Particle_Border_Ds from "../../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Background_Ds from "../../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var defaultStickers_text = Caml_option.some(Molecule_Sticker.Molecule_Sticker_Ds.base);

var defaultStickers_time = Caml_option.some(Molecule_Sticker.Molecule_Sticker_Ds.time);

var defaultStickers_disabledTime = Caml_option.some(Molecule_Sticker.Molecule_Sticker_Ds.disabledTime);

function defaultStickers_direct(dotColor) {
  return Caml_option.some(Molecule_Sticker.Molecule_Sticker_Ds.direct(dotColor));
}

function defaultStickers_live(dotColor) {
  return Caml_option.some(Molecule_Sticker.Molecule_Sticker_Ds.live(dotColor));
}

var defaultStickers = {
  text: defaultStickers_text,
  time: defaultStickers_time,
  genre: undefined,
  disabledTime: defaultStickers_disabledTime,
  direct: defaultStickers_direct,
  live: defaultStickers_live
};

function emptyStickers_direct(param) {
  
}

function emptyStickers_live(param) {
  
}

var emptyStickers = {
  text: undefined,
  time: undefined,
  genre: undefined,
  disabledTime: undefined,
  direct: emptyStickers_direct,
  live: emptyStickers_live
};

var withGenreStickers_text = defaultStickers_text;

var withGenreStickers_genre = Caml_option.some(Molecule_Sticker.Molecule_Sticker_Ds.genre);

var withGenreStickers_direct = defaultStickers_direct;

var withGenreStickers_live = defaultStickers_live;

var withGenreStickers = {
  text: withGenreStickers_text,
  time: undefined,
  genre: withGenreStickers_genre,
  disabledTime: undefined,
  direct: withGenreStickers_direct,
  live: withGenreStickers_live
};

var hoverStickers_text = defaultStickers_text;

var hoverStickers_time = defaultStickers_time;

var hoverStickers_disabledTime = defaultStickers_disabledTime;

var hoverStickers_direct = defaultStickers_direct;

var hoverStickers_live = defaultStickers_live;

var hoverStickers = {
  text: hoverStickers_text,
  time: hoverStickers_time,
  genre: undefined,
  disabledTime: hoverStickers_disabledTime,
  direct: hoverStickers_direct,
  live: hoverStickers_live
};

function getStickers(image, genreStickerBehavior) {
  if (genreStickerBehavior === "DisplayGenreSticker") {
    return withGenreStickers;
  }
  if (typeof image !== "object") {
    switch (image) {
      case "PortraitTeaser" :
      case "Square" :
          break;
      default:
        return defaultStickers;
    }
  } else {
    switch (image.TAG) {
      case "TeaserGuide" :
          return {
                  text: Caml_option.some(Molecule_Sticker.Molecule_Sticker_Ds.Guide.base),
                  time: Caml_option.some(Molecule_Sticker.Molecule_Sticker_Ds.Guide.time),
                  genre: Caml_option.some(Molecule_Sticker.Molecule_Sticker_Ds.Guide.genre),
                  disabledTime: Caml_option.some(Molecule_Sticker.Molecule_Sticker_Ds.Guide.disabledTime),
                  direct: (function (dotColor) {
                      return Caml_option.some(Molecule_Sticker.Molecule_Sticker_Ds.Guide.direct(dotColor));
                    }),
                  live: (function (dotColor) {
                      return Caml_option.some(Molecule_Sticker.Molecule_Sticker_Ds.live(dotColor));
                    })
                };
      case "TeaserHover" :
          return hoverStickers;
      default:
        return defaultStickers;
    }
  }
  return {
          text: defaultStickers_text,
          time: undefined,
          genre: undefined,
          disabledTime: undefined,
          direct: defaultStickers_direct,
          live: defaultStickers_live
        };
}

var Sticker = {
  defaultStickers: defaultStickers,
  emptyStickers: emptyStickers,
  withGenreStickers: withGenreStickers,
  hoverStickers: hoverStickers,
  getStickers: getStickers
};

function getHoverPictureKind(pictureKind) {
  if (typeof pictureKind === "object") {
    return {
            TAG: "TeaserHover",
            _0: "Standard"
          };
  }
  switch (pictureKind) {
    case "BigTeaser" :
        return {
                TAG: "TeaserHover",
                _0: "Big"
              };
    case "PortraitTeaser" :
        return {
                TAG: "TeaserHover",
                _0: "Portrait"
              };
    default:
      return {
              TAG: "TeaserHover",
              _0: "Standard"
            };
  }
}

function make(pictureKind) {
  return {
          title: Caml_option.some(Atom_Text_Ds.Teaser.Hover.make("Title")),
          subtitle: Caml_option.some(Atom_Text_Ds.Teaser.Hover.make("Subtitle")),
          description: Caml_option.some(Atom_Text_Ds.Teaser.Hover.make("Desc")),
          background: Particle_Background_Ds.teaserHoverNewOverlay,
          favorite: "Available",
          sticker: getStickers(getHoverPictureKind(pictureKind), "HideGenreSticker")
        };
}

function article(pictureKind) {
  var init = make(getHoverPictureKind(pictureKind));
  return {
          title: init.title,
          subtitle: init.subtitle,
          description: init.description,
          background: init.background,
          favorite: "NotAvailable",
          sticker: init.sticker
        };
}

function search(pictureKind) {
  return make(getHoverPictureKind(pictureKind));
}

function overrideByTeaser(t, teaser, pictureKind) {
  var match = teaser.program.kind;
  if (match !== undefined && (match === "EXTERNAL" || match === "INT")) {
    return article(getHoverPictureKind(pictureKind));
  } else {
    return t;
  }
}

var Hover = {
  make: make,
  article: article,
  search: search,
  overrideByTeaser: overrideByTeaser
};

var defaultLegend_blockspace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeHorizontal("Zero"), undefined, undefined, Particle_Spacer_Ds.makeHorizontal("XS"));

var defaultLegend = {
  blockspace: defaultLegend_blockspace
};

var category_containerCtaStyle = Atom_Cta_Ds.Teaser.teaser;

var category_innerSpace = Caml_option.some(Particle_Screen_Constants.xsInnerSpace);

var category_sticker = getStickers("StandardTeaser", "HideGenreSticker");

var category = {
  background: undefined,
  blockSpace: undefined,
  border: undefined,
  containerCtaStyle: category_containerCtaStyle,
  direction: "column",
  focus: "Default",
  hover: undefined,
  image: "StandardTeaser",
  innerSpace: category_innerSpace,
  legend: defaultLegend,
  sticker: category_sticker,
  subtitle: undefined,
  title: undefined,
  mobileHoverButton: undefined,
  mobileBorder: undefined
};

function classic(image, genreStickerBehaviorOpt) {
  var genreStickerBehavior = genreStickerBehaviorOpt !== undefined ? genreStickerBehaviorOpt : "HideGenreSticker";
  return {
          background: Caml_option.some(Particle_Background_Ds.teaserClassic),
          blockSpace: undefined,
          border: undefined,
          containerCtaStyle: Atom_Cta_Ds.Teaser.teaser,
          direction: "column",
          focus: "WithMoreOptionsButton",
          hover: make(image),
          image: image,
          innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace),
          legend: defaultLegend,
          sticker: getStickers(image, genreStickerBehavior),
          subtitle: Caml_option.some(Atom_Text_Ds.Teaser.make("Subtitle")),
          title: Caml_option.some(Atom_Text_Ds.Teaser.make("Title")),
          mobileHoverButton: Caml_option.some(Atom_Text_Ds.Teaser.make("MobileHoverButton")),
          mobileBorder: Particle_Border_Ds.Teaser.classic
        };
}

var collection_containerCtaStyle = Atom_Cta_Ds.Teaser.teaser;

var collection_hover = make("StandardTeaser");

var collection_innerSpace = Caml_option.some(Particle_Screen_Constants.xsInnerSpace);

var collection_sticker = getStickers("StandardTeaser", "HideGenreSticker");

var collection_subtitle = Caml_option.some(Atom_Text_Ds.Teaser.makeCollection("Subtitle"));

var collection_title = Caml_option.some(Atom_Text_Ds.Teaser.makeCollection("Title"));

var collection = {
  background: undefined,
  blockSpace: undefined,
  border: undefined,
  containerCtaStyle: collection_containerCtaStyle,
  direction: "column",
  focus: "WithMoreOptionsButton",
  hover: collection_hover,
  image: "StandardTeaser",
  innerSpace: collection_innerSpace,
  legend: defaultLegend,
  sticker: collection_sticker,
  subtitle: collection_subtitle,
  title: collection_title,
  mobileHoverButton: undefined,
  mobileBorder: undefined
};

function search$1(image) {
  var init = classic(image, undefined);
  return {
          background: init.background,
          blockSpace: init.blockSpace,
          border: init.border,
          containerCtaStyle: init.containerCtaStyle,
          direction: init.direction,
          focus: init.focus,
          hover: search(image),
          image: init.image,
          innerSpace: init.innerSpace,
          legend: init.legend,
          sticker: init.sticker,
          subtitle: init.subtitle,
          title: init.title,
          mobileHoverButton: init.mobileHoverButton,
          mobileBorder: init.mobileBorder
        };
}

function playNext(image) {
  var init = classic(image, undefined);
  return {
          background: Caml_option.some(Particle_Background_Ds.teaserPlayNext),
          blockSpace: init.blockSpace,
          border: init.border,
          containerCtaStyle: init.containerCtaStyle,
          direction: init.direction,
          focus: init.focus,
          hover: init.hover,
          image: init.image,
          innerSpace: init.innerSpace,
          legend: init.legend,
          sticker: init.sticker,
          subtitle: Caml_option.some(Atom_Text_Ds.Teaser.makePlayNext("Subtitle")),
          title: Caml_option.some(Atom_Text_Ds.Teaser.makePlayNext("Title")),
          mobileHoverButton: Caml_option.some(Atom_Text_Ds.Teaser.makePlayNext("MobileHoverButton")),
          mobileBorder: Particle_Border_Ds.Corporate.active
        };
}

function afp(image) {
  return {
          background: undefined,
          blockSpace: undefined,
          border: undefined,
          containerCtaStyle: Atom_Cta_Ds.Teaser.teaser,
          direction: "column",
          focus: "WithMoreOptionsButton",
          hover: article(image),
          image: image,
          innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace),
          legend: defaultLegend,
          sticker: getStickers(image, "HideGenreSticker"),
          subtitle: Caml_option.some(Atom_Text_Ds.Teaser.makeAfp("Subtitle")),
          title: Caml_option.some(Atom_Text_Ds.Teaser.makeAfp("Title")),
          mobileHoverButton: undefined,
          mobileBorder: undefined
        };
}

function white(image) {
  return {
          background: undefined,
          blockSpace: undefined,
          border: undefined,
          containerCtaStyle: Atom_Cta_Ds.Teaser.teaserInfo,
          direction: "column",
          focus: "WithMoreOptionsButton",
          hover: make(image),
          image: image,
          innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace),
          legend: defaultLegend,
          sticker: getStickers(image, "HideGenreSticker"),
          subtitle: Caml_option.some(Atom_Text_Ds.Teaser.makeWhite("Subtitle")),
          title: Caml_option.some(Atom_Text_Ds.Teaser.makeWhite("Title")),
          mobileHoverButton: Caml_option.some(Atom_Text_Ds.Teaser.makeWhite("MobileHoverButton")),
          mobileBorder: Particle_Border_Ds.Teaser.info
        };
}

function info(image) {
  var newrecord = Caml_obj.obj_dup(defaultLegend);
  return {
          background: Caml_option.some(Particle_Background_Ds.teaserInfo),
          blockSpace: undefined,
          border: undefined,
          containerCtaStyle: Atom_Cta_Ds.Teaser.teaserInfo,
          direction: "column",
          focus: "WithMoreOptionsButton",
          hover: make(image),
          image: image,
          innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace),
          legend: (newrecord.justifyContent = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "flexStart")), newrecord),
          sticker: getStickers(image, "HideGenreSticker"),
          subtitle: Caml_option.some(Atom_Text_Ds.Teaser.makeInfo("Subtitle")),
          title: Caml_option.some(Atom_Text_Ds.Teaser.makeInfo("Title")),
          mobileHoverButton: Caml_option.some(Atom_Text_Ds.Teaser.makeInfo("MobileHoverButton")),
          mobileBorder: Particle_Border_Ds.Teaser.info
        };
}

function genre(domain) {
  return {
          background: undefined,
          blockSpace: undefined,
          border: undefined,
          containerCtaStyle: Atom_Cta_Ds.Teaser.makeTeaserLandscapeGenre(domain),
          direction: "column",
          focus: "Default",
          hover: undefined,
          image: "StandardTeaser",
          innerSpace: undefined,
          legend: defaultLegend,
          sticker: emptyStickers,
          subtitle: undefined,
          title: Caml_option.some(Atom_Text_Ds.Teaser.makeGenre),
          mobileHoverButton: undefined,
          mobileBorder: undefined
        };
}

function user(image) {
  var init = classic(image, undefined);
  return {
          background: init.background,
          blockSpace: init.blockSpace,
          border: init.border,
          containerCtaStyle: Atom_Cta_Ds.teaserUser,
          direction: init.direction,
          focus: init.focus,
          hover: undefined,
          image: init.image,
          innerSpace: init.innerSpace,
          legend: {
            blockspace: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeHorizontal("Zero"))
          },
          sticker: init.sticker,
          subtitle: init.subtitle,
          title: init.title,
          mobileHoverButton: init.mobileHoverButton,
          mobileBorder: init.mobileBorder
        };
}

var standardImage = {
  TAG: "TeaserGuide",
  _0: "Standard"
};

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeBottom("M"), Particle_Spacer_Ds.makeBottom("L"), undefined, Particle_Spacer_Ds.make("Zero", "M", "S", "S"));

var newrecord = Caml_obj.obj_dup(defaultLegend);

var standard_blockSpace = Caml_option.some(blockSpace);

var standard_containerCtaStyle = Atom_Cta_Ds.TeaserGuide.teaserContainerStandard;

var standard_hover = make(standardImage);

var standard_innerSpace = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS"));

var standard_legend = (newrecord.justifyContent = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")), newrecord);

var standard_sticker = getStickers(standardImage, "HideGenreSticker");

var standard_subtitle = Caml_option.some(Atom_Text_Ds.Teaser.makeGuide("Subtitle"));

var standard_title = Caml_option.some(Atom_Text_Ds.Teaser.makeGuide("Title"));

var standard = {
  background: undefined,
  blockSpace: standard_blockSpace,
  border: undefined,
  containerCtaStyle: standard_containerCtaStyle,
  direction: "row",
  focus: "WithMoreOptionsButton",
  hover: standard_hover,
  image: standardImage,
  innerSpace: standard_innerSpace,
  legend: standard_legend,
  sticker: standard_sticker,
  subtitle: standard_subtitle,
  title: standard_title,
  mobileHoverButton: undefined,
  mobileBorder: undefined
};

var bigImage = {
  TAG: "TeaserGuide",
  _0: "Big"
};

var big_blockSpace = Caml_option.some(blockSpace);

var big_containerCtaStyle = Atom_Cta_Ds.TeaserGuide.teaserContainerBig;

var big_hover = standard_hover;

var big_innerSpace = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XXS"));

var big_legend = standard_legend;

var big_sticker = getStickers(bigImage, "HideGenreSticker");

var big_subtitle = standard_subtitle;

var big_title = standard_title;

var big = {
  background: undefined,
  blockSpace: big_blockSpace,
  border: undefined,
  containerCtaStyle: big_containerCtaStyle,
  direction: "column",
  focus: "WithMoreOptionsButton",
  hover: big_hover,
  image: bigImage,
  innerSpace: big_innerSpace,
  legend: big_legend,
  sticker: big_sticker,
  subtitle: big_subtitle,
  title: big_title,
  mobileHoverButton: undefined,
  mobileBorder: undefined
};

function make$1(style) {
  if (style.TAG === "Concert") {
    if (style._0 === "Standard") {
      return standard;
    } else {
      return big;
    }
  } else if (style._0 === "Standard") {
    return standard;
  } else {
    return big;
  }
}

var Guide = {
  standardImage: standardImage,
  horizontalPaddingValue: "S",
  blockSpace: blockSpace,
  standard: standard,
  bigImage: bigImage,
  big: big,
  make: make$1
};

function withBorder(kind, t) {
  var makeBorderFromColor = function (color) {
    if (color === "Replay") {
      return Particle_Border_Ds.Teaser.directDefault;
    } else {
      return Particle_Border_Ds.Teaser.directConcert;
    }
  };
  var tmp;
  if (typeof kind === "object") {
    var variant = kind.NAME;
    tmp = variant === "Progress" ? Particle_Border_Ds.makeProgression(kind.VAL) : (
        variant === "Live" || variant === "Direct" ? makeBorderFromColor(kind.VAL) : undefined
      );
  } else {
    tmp = undefined;
  }
  return {
          background: t.background,
          blockSpace: t.blockSpace,
          border: tmp,
          containerCtaStyle: t.containerCtaStyle,
          direction: t.direction,
          focus: t.focus,
          hover: t.hover,
          image: t.image,
          innerSpace: t.innerSpace,
          legend: t.legend,
          sticker: t.sticker,
          subtitle: t.subtitle,
          title: t.title,
          mobileHoverButton: t.mobileHoverButton,
          mobileBorder: t.mobileBorder
        };
}

function setDisable(t) {
  return {
          background: Caml_option.some(Particle_Background_Ds.disabledTeaser),
          blockSpace: t.blockSpace,
          border: t.border,
          containerCtaStyle: t.containerCtaStyle,
          direction: t.direction,
          focus: t.focus,
          hover: undefined,
          image: t.image,
          innerSpace: t.innerSpace,
          legend: t.legend,
          sticker: t.sticker,
          subtitle: Core__Option.map(t.subtitle, Atom_Text_Ds.Teaser.disable),
          title: Core__Option.map(t.title, Atom_Text_Ds.Teaser.disable),
          mobileHoverButton: undefined,
          mobileBorder: t.mobileBorder
        };
}

export {
  Sticker ,
  getHoverPictureKind ,
  Hover ,
  defaultLegend ,
  category ,
  classic ,
  collection ,
  search$1 as search,
  playNext ,
  afp ,
  white ,
  info ,
  genre ,
  user ,
  Guide ,
  withBorder ,
  setDisable ,
}
/* defaultLegend Not a pure module */
