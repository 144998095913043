'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../../user/User.res.mjs";
import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as Util_Fn from "../../../util/Util_Fn.res.mjs";
import * as Atom_Cta from "../../../design-system/atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../../design-system/atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../../design-system/atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../../../design-system/atom/Atom_Text.res.mjs";
import * as Type_Club from "../../../club/Type_Club.res.mjs";
import * as User_Type from "../../../user/User_Type.res.mjs";
import * as Atom_Column from "../../../design-system/atom/Atom_Column.res.mjs";
import * as Atom_Loader from "../../../design-system/atom/Atom_Loader.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Layout_Auth from "../layout/Layout_Auth.res.mjs";
import * as Atom_Text_Ds from "../../../design-system/atom/Atom_Text_Ds.res.mjs";
import * as Core__Result from "@rescript/core/src/Core__Result.res.mjs";
import * as User_Provider from "../../../user/User_Provider.res.mjs";
import * as User_Api__Sso__Me from "../../../user/User_Api/User_Api__Sso__Me.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Constants from "../../../design-system/particle/Particle_Screen_Constants.res.mjs";

function isVerifiedUser(response) {
  if (response.TAG !== "Ok") {
    return response._0;
  }
  var user = response._0;
  var userAgeVerificationStatus = Type_Club.User.getAgeVerificationStatus(user);
  if (userAgeVerificationStatus === "NotVerified") {
    return "Retry";
  }
  User_Provider.persistUser(User_Type.addUserDetailFromClub(User.get(), user));
  return "Success";
}

async function fetchUserData(abortController, locale) {
  var me = await User_Api__Sso__Me.get({
        signal: abortController.signal,
        locale: locale
      });
  return Core__Result.mapError(me, (function (error) {
                if (typeof error !== "object" && error === "RequestAborted") {
                  return "Aborted";
                } else {
                  return "Error";
                }
              }));
}

function make(param) {
  var onError = param.onError;
  var onTimeout = param.onTimeout;
  var onSuccess = param.onSuccess;
  var match = Context.I18n.getState();
  var locale = match.locale;
  var labels = match.labels;
  var match$1 = labels.mona.login;
  var match$2 = labels.mona.verificationPending;
  var status = User_Provider.Hook.useStatus();
  var abortController = new AbortController();
  React.useEffect((function () {
          switch (status) {
            case "Initialized" :
                var match = Util_Fn.withBackoff(5, 1000, 2.0, (function () {
                        return fetchUserData(abortController, locale);
                      }), isVerifiedUser, (function (response) {
                        var tmp;
                        tmp = response.TAG === "Ok" ? Type_Club.User.getAgeVerificationToken(response._0) : undefined;
                        onSuccess(tmp);
                      }), onTimeout, onError, (function () {
                        abortController.abort();
                      }));
                var cancel = match.cancel;
                match.run(0);
                return (function () {
                          cancel();
                        });
            case "NotInitialized" :
            case "ForceReInit" :
                return ;
            
          }
        }), [
        status,
        locale,
        onSuccess,
        onTimeout,
        onError
      ]);
  return JsxRuntime.jsxs(Layout_Auth.make, {
              title: match$2.title,
              footerLinkLabel: match$1.footerLinkLabel,
              footerLinkUrl: match$1.footerLinkUrl,
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsxs(Atom_Column.make, {
                              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                              children: [
                                JsxRuntime.jsx(Atom_Text.make, {
                                      children: Caml_option.some(match$2.subtitle),
                                      style: Atom_Text_Ds.User.text("Auth")
                                    }),
                                JsxRuntime.jsx(Atom_Text.make, {
                                      children: Caml_option.some(match$2.text),
                                      style: Atom_Text_Ds.User.text("Auth")
                                    })
                              ],
                              innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                            }),
                        JsxRuntime.jsx(Atom_Row.make, {
                              children: JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                                    label: match$2.buttonLabel,
                                    onClick: param.redirectToHome,
                                    variant: "outline"
                                  }),
                              gap: Caml_option.some(Layout_Auth.Form_Css.formBottomGap),
                              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter)
                            })
                      ],
                      innerSpace: Caml_option.some(Layout_Auth.Form_Css.wrapperInnerSpace),
                      width: Caml_option.some(Layout_Auth.Form_Css.wrapperWidth)
                    }),
                JsxRuntime.jsx(Atom_Cell.make, {
                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                      children: JsxRuntime.jsx(Atom_Loader.make, {})
                    })
              ]
            });
}

export {
  isVerifiedUser ,
  fetchUserData ,
  make ,
}
/* User Not a pure module */
