// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Util_Dom from "../../../util/Util_Dom.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Util_Array from "../../../util/Util_Array.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../../selector/Identifiers.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Organism_Slider_Css from "./Organism_Slider_Css.res.mjs";
import * as UseScreenResolution from "../../../hook/UseScreenResolution.res.mjs";
import * as Webapi__Dom__Element from "rescript-webapi/src/Webapi/Dom/Webapi__Dom__Element.res.mjs";
import * as Particle_Accessibility from "../../particle/Particle_Accessibility.res.mjs";

function getCenterValuePosition(sliderSize, itemRef) {
  var itemWidth = Core__Option.mapOr(Core__Option.map(Util_Dom.getDomRef(itemRef), Util_Dom.getElementRect), 0, (function (param) {
          return param.width;
        }));
  var sliderCenterValue = sliderSize.left + sliderSize.width / 2;
  return sliderCenterValue - itemWidth / 2;
}

function slideNext(positionLeft, remainingScroll, value) {
  var nextScrollValue = value - positionLeft;
  if (nextScrollValue > remainingScroll) {
    return remainingScroll;
  } else {
    return nextScrollValue;
  }
}

function slidePrevious(positionRight, remainingScroll, value) {
  var nextScrollValue = - (positionRight - value);
  if (remainingScroll < Math.abs(nextScrollValue)) {
    return - remainingScroll;
  } else if (nextScrollValue === 0) {
    return - positionRight;
  } else {
    return nextScrollValue;
  }
}

function slideCenter(sliderSize, itemRef, value) {
  return value - getCenterValuePosition(sliderSize, itemRef);
}

function reducer(state, action) {
  if (action.TAG === "UpdateContainerHeight") {
    return {
            displayedArrows: state.displayedArrows,
            containerHeight: action._0
          };
  } else {
    return {
            displayedArrows: action._0,
            containerHeight: state.containerHeight
          };
  }
}

function updateArrowVisibility(dispatch, sliderRef) {
  var sliderElement = Util_Dom.getDomRef(sliderRef);
  var match = Util_Dom.isScrolledMax(0, sliderElement);
  var nextArrow = match[1];
  var arrowState = match[0] ? (
      nextArrow ? "Previous" : "NextAndPrevious"
    ) : (
      nextArrow ? "None" : "Next"
    );
  return dispatch({
              TAG: "UpdateArrow",
              _0: arrowState
            });
}

function handleArrowClick(sliderSize, sliderRef, selectElements, updateArrowVisibility, onDirectionChange, buttonType, param) {
  var element = Util_Dom.getDomRef(sliderRef);
  if (element === undefined) {
    return ;
  }
  var element$1 = Caml_option.valFromOption(element);
  var currentScroll = element$1.scrollLeft;
  var maxScroll = element$1.scrollWidth - element$1.clientWidth | 0;
  var match;
  match = buttonType === "Next" ? [
      (function (arr) {
          return Util_Array.getLastElement(arr);
        }),
      (function (extra) {
          return slideNext(sliderSize.left, maxScroll - currentScroll, extra);
        }),
      (function (prim) {
          return prim.left;
        })
    ] : [
      (function (arr) {
          return Util_Array.getFirstElement(arr);
        }),
      (function (extra) {
          return slidePrevious(sliderSize.right, currentScroll, extra);
        }),
      (function (prim) {
          return prim.right;
        })
    ];
  if (onDirectionChange !== undefined) {
    onDirectionChange(buttonType);
  }
  var keepVisibleElements = function (elements) {
    return elements.filter(function (node) {
                return Util_Dom.Visibility.check(Webapi__Dom__Element.ofNode(node), sliderSize, "Horizontal");
              });
  };
  Core__Option.forEach(Core__Option.map(Core__Option.map(Core__Option.map(Core__Option.map(Core__Option.flatMap(match[0](keepVisibleElements(Array.prototype.slice.call(selectElements(element$1)))), Webapi__Dom__Element.ofNode), (function (prim) {
                          return prim.getBoundingClientRect();
                        })), match[2]), match[1]), (function (__x) {
              Util_Dom.scrollBy(Caml_option.some(element$1), __x);
            })), (function () {
          updateArrowVisibility();
        }));
}

function Organism_Slider_Arrows(props) {
  var onDirectionChange = props.onDirectionChange;
  var arrowPosition = props.arrowPosition;
  var getContainerHeight = props.getContainerHeight;
  var selectElements = props.selectElements;
  var verticalPadding = props.verticalPadding;
  var sliderRef = props.sliderRef;
  var sliderSize = props.sliderSize;
  var match = React.useReducer(reducer, {
        displayedArrows: "None",
        containerHeight: undefined
      });
  var dispatch = match[1];
  var match$1 = match[0];
  var containerHeight = match$1.containerHeight;
  var updateArrowVisibility$1 = React.useCallback((function () {
          updateArrowVisibility(dispatch, sliderRef);
        }), [
        dispatch,
        sliderRef,
        sliderSize
      ]);
  var handleArrowClick$1 = React.useMemo((function () {
          return function (extra, extra$1) {
            return handleArrowClick(sliderSize, sliderRef, selectElements, updateArrowVisibility$1, onDirectionChange, extra, extra$1);
          };
        }), [
        sliderSize,
        sliderRef,
        selectElements,
        updateArrowVisibility$1,
        props.scrollOffset,
        onDirectionChange
      ]);
  var handleScreenResize = React.useMemo((function () {
          return function (screenSize) {
            dispatch({
                  TAG: "UpdateContainerHeight",
                  _0: getContainerHeight(screenSize)
                });
          };
        }), [
        dispatch,
        getContainerHeight
      ]);
  UseScreenResolution.make(undefined, handleScreenResize);
  React.useLayoutEffect((function () {
          var onScrollListener = function (param) {
            updateArrowVisibility$1();
          };
          Core__Option.forEach(Util_Dom.getDomRef(sliderRef), (function (element) {
                  element.addEventListener("scroll", onScrollListener);
                }));
          var isMount = {
            contents: true
          };
          if (isMount.contents) {
            updateArrowVisibility$1();
          }
          return (function () {
                    Core__Option.forEach(Util_Dom.getDomRef(sliderRef), (function (element) {
                            element.removeEventListener("scroll", onScrollListener);
                          }));
                    isMount.contents = false;
                  });
        }), [
        sliderRef,
        updateArrowVisibility$1
      ]);
  var previousButton = JsxRuntime.jsx(Atom_Cell.make, {
        children: JsxRuntime.jsx(Atom_Cta.Button.make, {
              accessibility: Particle_Accessibility.notFocusable,
              identifier: Caml_option.some(Identifiers.Slider.arrowPrev),
              onClick: (function (__x) {
                  handleArrowClick$1("Previous", __x);
                }),
              style: Atom_Cta_Ds.Slider.navPrev
            }),
        rules: Caml_option.some(Organism_Slider_Css.getNavRules(containerHeight, verticalPadding, "Left", arrowPosition))
      });
  var nextButton = JsxRuntime.jsx(Atom_Cell.make, {
        children: JsxRuntime.jsx(Atom_Cta.Button.make, {
              accessibility: Particle_Accessibility.notFocusable,
              identifier: Caml_option.some(Identifiers.Slider.arrowNext),
              onClick: (function (__x) {
                  handleArrowClick$1("Next", __x);
                }),
              style: Atom_Cta_Ds.Slider.navNext
            }),
        rules: Caml_option.some(Organism_Slider_Css.getNavRules(containerHeight, verticalPadding, "Right", arrowPosition))
      });
  switch (match$1.displayedArrows) {
    case "Next" :
        if (containerHeight !== undefined) {
          return nextButton;
        } else {
          return null;
        }
    case "Previous" :
        if (containerHeight !== undefined) {
          return previousButton;
        } else {
          return null;
        }
    case "NextAndPrevious" :
        if (containerHeight !== undefined) {
          return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                      children: [
                        previousButton,
                        nextButton
                      ]
                    });
        } else {
          return null;
        }
    case "None" :
        return null;
    
  }
}

var make = Organism_Slider_Arrows;

export {
  getCenterValuePosition ,
  slideNext ,
  slidePrevious ,
  slideCenter ,
  reducer ,
  updateArrowVisibility ,
  handleArrowClick ,
  make ,
}
/* react Not a pure module */
