import { type ArteVpProps, type ExternalInterface, type OnVideoLoadedEvent, type PreviewData } from '@artegeie/arte-vp';
import { type VideoKind } from '@artegeie/design-system/type';
import dynamic from 'next/dynamic';
import React, { type CSSProperties, type ReactElement, useEffect } from 'react';
import { env } from '@replay/env-generator';
import { type Translate } from '@replay/i18n/types/translations';
import { useGetAutoplayValues } from '../../shared/useAutoPlayChange';
import { useGetVideoQualityValue } from '../../shared/useVideoQualityChange';
import { Locale } from '@replay/i18n/types/locale';
import { type FromPage, localeToLang, modeFromVideoKind } from '@replay/types/Player';
import { setPlayerStartQuality } from '../program/createPlayerEvent';
import { PlayerStyle } from './PlayerStyle';
import { useStartAuth } from '../../shared/useRedirect';
import { type ApiPlayerConfigData, type ApiPlayerPlaylistItems } from '../../shared/api-player/commons';
import { eventStore } from '@replay/tracking/eventStore';
import { ServerSideTracking } from '@replay/types/Stats';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ArteVp = dynamic<ArteVpProps>(() => import('@artegeie/arte-vp') as any, {
    ssr: false,
});

// Prevent scroll on "space" key press
// Only player should be managed with that
const preventSpacePress = (e: GlobalEventHandlersEventMap['keydown']) => {
    if (e.key === ' ') {
        e.preventDefault();
    }
};

type ListenerActions = 'remove' | 'add';
const listenSpacebarEvent = (action: ListenerActions): void => {
    if (!global.window) {
        return;
    }
    if (action === 'remove') {
        global.window.removeEventListener('keydown', preventSpacePress);
    } else {
        global.window.addEventListener('keydown', preventSpacePress);
    }
};

const style: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 3,
};

type Props = {
    autoPlayNext: boolean;
    config: string;
    fromDomain: FromPage;
    playlistItems: ArteVpProps['playlistItems'];
    locale: Locale;
    onVideoLoaded?: (event: OnVideoLoadedEvent) => void;
    previewData: PreviewData;
    tcStartFrom: number | null;
    translate: Translate;
    videoKind: VideoKind;
    getExternalInterface: ArteVpProps['getExternalInterface'];
    forcedMute?: boolean;
    loop?: boolean;
    serverSideTracking?: ServerSideTracking;
    apiPlayerConfig?: ApiPlayerConfigData;
    apiPlayerPlaylist?: ApiPlayerPlaylistItems;
    hasIllico: boolean;
};

const validateConfig = ({
    config,
    apiPlayerConfig,
    apiPlayerPlaylist,
}: {
    config: string;
    apiPlayerConfig?: ApiPlayerConfigData;
    apiPlayerPlaylist?: ApiPlayerPlaylistItems;
}) => {
    const sanitizeUrl = (url: string) => url.replace(/https?:\/\/api(?:\-internal)?\.arte\.tv\/api\/player\//, '');
    if (!apiPlayerConfig)
        return {
            apiPlayerConfig: undefined,
            apiPlayerPlaylist: undefined,
        };

    if (!config.includes('/LIVE')) {
        if (sanitizeUrl(apiPlayerConfig.attributes?.metadata.config.url || '') !== sanitizeUrl(config)) {
            return {
                apiPlayerConfig: undefined,
                apiPlayerPlaylist: undefined,
            };
        }
    }

    const toPlayerProps = apiPlayerConfig as ArteVpProps['apiPlayerData'];
    const toPlaylistItems = apiPlayerPlaylist as ArteVpProps['playlistItems'];

    return {
        apiPlayerConfig: toPlayerProps,
        apiPlayerPlaylist: toPlaylistItems,
    };
};

export const Player = ({
    autoPlayNext = true,
    loop = false,
    config,
    fromDomain,
    getExternalInterface,
    locale,
    videoKind,
    onVideoLoaded = () => {
        return undefined;
    },
    previewData,
    translate,
    tcStartFrom,
    playlistItems,
    forcedMute = false,
    serverSideTracking,
    apiPlayerConfig,
    apiPlayerPlaylist,
    hasIllico,
}: Props): ReactElement => {
    const autoplayStatus = useGetAutoplayValues(videoKind, fromDomain);
    const videoQualityPreference = useGetVideoQualityValue();
    const playerMode = modeFromVideoKind(videoKind);
    const { sendPlayerEvent } = eventStore();
    const { startAuth } = useStartAuth(locale, serverSideTracking);

    const handlePlayerFocus = () => {
        listenSpacebarEvent('add');
    };
    const handlePlayerUnFocus = () => {
        listenSpacebarEvent('remove');
    };
    const handleVideoLoaded = (event: OnVideoLoadedEvent): void => {
        listenSpacebarEvent('add');
        onVideoLoaded(event);
    };
    useEffect(() => {
        return () => listenSpacebarEvent('remove');
    }, []);

    const externalInterfaceGetter = (externalInterface: ExternalInterface) => {
        if (env.NEXT_PUBLIC_FEATURE_FLAGS_SETTINGS_VIDEO_QUALITY) {
            externalInterface.on('readyToChangeQuality', () => {
                setPlayerStartQuality(externalInterface, videoQualityPreference);
            });
        }

        externalInterface.on('firstFrame', () => {});

        if (typeof getExternalInterface === 'function') {
            getExternalInterface(externalInterface);
        }
    };

    const redirectToAgeVerification = () => {
        startAuth({
            shouldVerifyAge: true,
        });
    };

    const redirectToInfoPage = () => {
        try {
            window.location.assign(new URL(translate('replay_internal.formaters.program.playerFskFaqUrl')).toString());
        } catch (e) {
            console.warn('Could not redirect to FSK FAQ', e);
        }
    };

    const { apiPlayerConfig: ensuredApiPlayerConfig, apiPlayerPlaylist: ensuredApiPlayerPlaylist } = validateConfig({
        config,
        apiPlayerConfig,
        apiPlayerPlaylist,
    });

    return (
        <div
            role="region"
            style={{ width: '100%' }}
            aria-label={translate('replay_internal.formaters.program.videoplayer')}
            id="video_player"
            tabIndex={-1}
        >
            <div className="video-embed">
                <div style={style}>
                    <PlayerStyle />
                    <ArteVp
                        jsonUrl={config}
                        apiPlayerData={ensuredApiPlayerConfig}
                        apiPlayerPlaylistData={ensuredApiPlayerPlaylist}
                        autoplay={autoplayStatus}
                        autoPlayNext={autoPlayNext}
                        playlistItems={playlistItems}
                        mode={playerMode}
                        onFocus={handlePlayerFocus}
                        onUnfocus={handlePlayerUnFocus}
                        onVideoLoaded={handleVideoLoaded}
                        previewData={previewData}
                        tcStartFrom={tcStartFrom}
                        getExternalInterface={externalInterfaceGetter}
                        mute={forcedMute}
                        lang={localeToLang(locale)}
                        redirectToAgeVerification={redirectToAgeVerification}
                        redirectToInfoPage={redirectToInfoPage}
                        loop={loop}
                        sendTracking={(event) => {
                            sendPlayerEvent(serverSideTracking, event);
                        }}
                        illico={hasIllico}
                    />
                </div>
            </div>
        </div>
    );
};
