// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../../provider/Context.res.mjs";
import * as UseForm from "../../../hook/UseForm.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as UseUserFetch from "../../../hook/UseUserFetch.res.mjs";
import * as Atom_Textfield from "../../atom/Atom_Textfield.res.mjs";
import * as Layout_Account from "../../layout/Layout_Account.res.mjs";
import * as User_Api__Sso__Me from "../../../user/User_Api/User_Api__Sso__Me.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

function get(values, field) {
  if (field === "Email") {
    return values.email;
  } else {
    return values.emailConfirmation;
  }
}

function set(values, field, value) {
  if (field === "Email") {
    return {
            email: value,
            emailConfirmation: values.emailConfirmation
          };
  } else {
    return {
            email: values.email,
            emailConfirmation: value
          };
  }
}

var FormFields = {
  get: get,
  set: set
};

var initialState = {
  email: "",
  emailConfirmation: ""
};

var Form = UseForm.Make({
      set: set,
      get: get
    });

function makeSchema(errorConfirmationMessage) {
  return Form.Schema.Validation.schema([
              Form.Schema.Validation.nonEmpty("Email"),
              Form.Schema.Validation.email("Email"),
              Form.Schema.Validation.nonEmpty("EmailConfirmation"),
              Form.Schema.Validation.email("EmailConfirmation"),
              Form.Schema.Validation.confirmation(errorConfirmationMessage, "Email", "EmailConfirmation")
            ]);
}

async function handleSubmit(param, setLoading, locale, setResponse) {
  var controller = new AbortController();
  var signal = controller.signal;
  var match = param.state.values;
  setLoading();
  var response = await User_Api__Sso__Me.newEmail({
        args: {
          email: match.email
        },
        signal: signal,
        locale: locale
      });
  setResponse(response);
  return {
          TAG: "Ok",
          _0: undefined
        };
}

function Page_MyAccount_Email$Email(props) {
  var match = Context.I18n.getState();
  var locale = match.locale;
  var labels = match.labels;
  var editLabel = labels.common.edit;
  var saveLabel = labels.common.save;
  var userEditEmailDescriptionLabel = labels.mona.editEmail.description;
  var userEditEmailPlaceholderLabel = labels.mona.editEmail.placeholder;
  var userEditEmailErrorAlreadyRegisteredLabel = labels.mona.editEmail.errorAlreadyRegistered;
  var userEditEmailSuccessLabel = labels.mona.editEmail.success;
  var userErrorGenericLabel = labels.mona.editEmail.errorGeneric;
  var userEditEmailPlaceholderConfirmationLabel = labels.mona.editEmail.placeholderConfirmation;
  var formValidationEmailConfirmation = labels.formValidation.emailConfirmation;
  var match$1 = UseUserFetch.make();
  var setSuccess = match$1.setSuccess;
  var setLoading = match$1.setLoading;
  var setError = match$1.setError;
  var setResponse = function (t) {
    if (t.TAG === "Ok") {
      return setSuccess(userEditEmailSuccessLabel);
    }
    var match = t._0;
    if (typeof match !== "object" || !(match.TAG === "BadRequest" && match._0 === "Other")) {
      return setError(userErrorGenericLabel);
    } else {
      return setError(userEditEmailErrorAlreadyRegisteredLabel);
    }
  };
  var schema = makeSchema(formValidationEmailConfirmation);
  var form = Form.use(initialState, schema, (function (__x) {
          return handleSubmit(__x, setLoading, locale, setResponse);
        }), undefined, labels, undefined);
  var match$2 = form.values;
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    form.submit();
  };
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(editLabel),
                      kind: "h1",
                      style: Atom_Text_Ds.User.title("MyAccount")
                    }),
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(userEditEmailDescriptionLabel),
                      style: Atom_Text_Ds.User.text("MyAccount")
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                              autoComplete: "email",
                              type_: "email",
                              value: match$2.email,
                              label: userEditEmailPlaceholderLabel,
                              pattern: "Email",
                              onChange: (function (__x) {
                                  form.handleChange("Email", __x);
                                }),
                              required: true,
                              id: "email",
                              name: userEditEmailPlaceholderLabel,
                              errorMessage: form.getFieldError({
                                    TAG: "Field",
                                    _0: "Email"
                                  }),
                              inputRef: form.getFieldRef({
                                    TAG: "Field",
                                    _0: "Email"
                                  })
                            }),
                        JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                              autoComplete: "email",
                              type_: "email",
                              value: match$2.emailConfirmation,
                              label: userEditEmailPlaceholderConfirmationLabel,
                              pattern: "Email",
                              onChange: (function (__x) {
                                  form.handleChange("EmailConfirmation", __x);
                                }),
                              required: true,
                              id: "emailConfirmation",
                              name: userEditEmailPlaceholderConfirmationLabel,
                              errorMessage: form.getFieldError({
                                    TAG: "Field",
                                    _0: "EmailConfirmation"
                                  }),
                              inputRef: form.getFieldRef({
                                    TAG: "Field",
                                    _0: "EmailConfirmation"
                                  })
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                    }),
                match$1.component,
                JsxRuntime.jsx(Atom_Row.make, {
                      children: JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                            label: saveLabel,
                            variant: "base",
                            type_: "submit"
                          })
                    })
              ],
              innerSpace: Caml_option.some(Particle_Screen_Constants.mInnerSpace),
              kind: {
                TAG: "Form",
                _0: onSubmit
              },
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

var Email = {
  FormFields: FormFields,
  initialState: initialState,
  Form: Form,
  makeSchema: makeSchema,
  handleSubmit: handleSubmit,
  make: Page_MyAccount_Email$Email
};

function make(param) {
  return JsxRuntime.jsx(Layout_Account.WithAvatar.make, {
              children: JsxRuntime.jsx(Page_MyAccount_Email$Email, {})
            });
}

export {
  Email ,
  make ,
}
/* Form Not a pure module */
