'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as Belt_Id from "@rescript/std/lib/es6/belt_Id.js";
import * as UseRefs from "./UseRefs.res.mjs";
import * as Belt_Map from "@rescript/std/lib/es6/belt_Map.js";
import * as SetFocus from "./SetFocus.res.mjs";
import * as Util_Dom from "../util/Util_Dom.res.mjs";
import * as Util_Array from "../util/Util_Array.res.mjs";
import * as ReactUpdate from "rescript-react-update/src/ReactUpdate.res.mjs";
import * as Type_Schema from "../type/Type_Schema.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Core__String from "@rescript/core/src/Core__String.res.mjs";
import * as Type_SchemaI18n from "../type/Type_SchemaI18n.res.mjs";

function Make(Config) {
  var Schema = Type_Schema.Make(Config);
  var cmp = function (a, b) {
    var fieldNameA = Schema.getFieldName(a);
    var fieldNameB = Schema.getFieldName(b);
    return Core__String.compare(fieldNameA, fieldNameB) | 0;
  };
  var FieldComparator = Belt_Id.MakeComparableU({
        cmp: cmp
      });
  var getInitialFieldsState = function (schema) {
    var getFieldFromValidator = function (validator) {
      switch (validator.TAG) {
        case "Confirmation" :
        case "EitherStringNonEmpty" :
            return {
                    TAG: "Field",
                    _0: validator.field1
                  };
        default:
          return {
                  TAG: "Field",
                  _0: validator.field
                };
      }
    };
    return Belt_Map.fromArray(schema.map(function (validator) {
                    var field = getFieldFromValidator(validator);
                    return [
                            field,
                            "Pristine"
                          ];
                  }), FieldComparator);
  };
  var reducer = function (onSubmit, i18n, schema, getRef, state, action) {
    if (typeof action !== "object") {
      if (action === "TrySubmit") {
        return {
                TAG: "SideEffects",
                _0: (function (self) {
                    self.send({
                          TAG: "ValidateForm",
                          _0: true
                        });
                  })
              };
      } else {
        return {
                TAG: "UpdateWithSideEffects",
                _0: {
                  fieldsState: state.fieldsState,
                  formState: "Submitting",
                  values: state.values,
                  validationType: state.validationType
                },
                _1: (function (self) {
                    var isMounted = {
                      contents: true
                    };
                    var run = async function () {
                      var result = await onSubmit({
                            state: self.state
                          });
                      if (result.TAG === "Ok") {
                        if (isMounted.contents) {
                          console.log("Form is valid");
                          return self.send({
                                      TAG: "SetFormState",
                                      _0: "Valid"
                                    });
                        } else {
                          return ;
                        }
                      }
                      var match = result._0;
                      if (match.TAG !== "Field") {
                        if (isMounted.contents) {
                          return self.send({
                                      TAG: "SetFormState",
                                      _0: "Errored"
                                    });
                        } else {
                          return ;
                        }
                      }
                      var field = match.field;
                      var fieldsState = Belt_Map.set(state.fieldsState, {
                            TAG: "Field",
                            _0: field
                          }, {
                            TAG: "Error",
                            _0: match.error
                          });
                      if (isMounted.contents) {
                        self.send({
                              TAG: "SetFormState",
                              _0: "Errored"
                            });
                        self.send({
                              TAG: "SelectField",
                              _0: field
                            });
                        return self.send({
                                    TAG: "SetFieldsState",
                                    _0: fieldsState
                                  });
                      }
                      
                    };
                    run();
                    return (function () {
                              console.log("unmount");
                              isMounted.contents = false;
                            });
                  })
              };
      }
    }
    switch (action.TAG) {
      case "ValidateField" :
          var field = action._0;
          return {
                  TAG: "SideEffects",
                  _0: (function (self) {
                      var fieldState = Schema.validateOne(field, self.state.values, i18n, schema);
                      var newFieldState;
                      if (fieldState !== undefined) {
                        var message = fieldState[1];
                        newFieldState = typeof message !== "object" ? "Valid" : ({
                              TAG: "Error",
                              _0: message._0
                            });
                      } else {
                        newFieldState = "Valid";
                      }
                      var newFieldsState = Belt_Map.set(state.fieldsState, field, newFieldState);
                      self.send({
                            TAG: "SetFieldsState",
                            _0: newFieldsState
                          });
                    })
                };
      case "ValidateForm" :
          var shouldSubmit = action._0;
          return {
                  TAG: "SideEffects",
                  _0: (function (self) {
                      var recordState = Schema.validate(i18n, self.state.values, schema);
                      if (typeof recordState !== "object") {
                        var newFieldsState2 = Belt_Map.map(self.state.fieldsState, (function (param) {
                                return "Valid";
                              }));
                        self.send({
                              TAG: "SetFieldsState",
                              _0: newFieldsState2
                            });
                        self.send({
                              TAG: "SetFormState",
                              _0: "Valid"
                            });
                        if (shouldSubmit) {
                          self.send("Submit");
                        }
                        
                      } else {
                        var erroredFields = recordState._0;
                        var newFieldsState = Belt_Map.merge(Belt_Map.fromArray(Util_Array.NonEmpty.toArray(Util_Array.NonEmpty.map(erroredFields, (function (param) {
                                            return [
                                                    param[0],
                                                    {
                                                      TAG: "Error",
                                                      _0: param[1]
                                                    }
                                                  ];
                                          }))), FieldComparator), state.fieldsState, (function (param, a, b) {
                                if (a !== undefined) {
                                  return a;
                                } else if (b !== undefined) {
                                  return b;
                                } else {
                                  return ;
                                }
                              }));
                        var firstErrorField = Util_Array.NonEmpty.head(erroredFields)[0];
                        self.send({
                              TAG: "SelectField",
                              _0: firstErrorField._0
                            });
                        self.send({
                              TAG: "SetFieldsState",
                              _0: newFieldsState
                            });
                        self.send({
                              TAG: "SetFormState",
                              _0: "Errored"
                            });
                      }
                    })
                };
      case "SetFieldsState" :
          return {
                  TAG: "Update",
                  _0: {
                    fieldsState: action._0,
                    formState: state.formState,
                    values: state.values,
                    validationType: state.validationType
                  }
                };
      case "FieldChangeValue" :
          var field$1 = action._0;
          return {
                  TAG: "UpdateWithSideEffects",
                  _0: {
                    fieldsState: state.fieldsState,
                    formState: state.formState === "Errored" ? "Errored" : "Dirty",
                    values: Curry._3(Config.set, state.values, field$1, action._1),
                    validationType: state.validationType
                  },
                  _1: (function (self) {
                      var match = self.state.validationType;
                      if (match === "OnChange") {
                        self.send({
                              TAG: "ValidateField",
                              _0: {
                                TAG: "Field",
                                _0: field$1
                              }
                            });
                      }
                      
                    })
                };
      case "SetFormState" :
          return {
                  TAG: "Update",
                  _0: {
                    fieldsState: state.fieldsState,
                    formState: action._0,
                    values: state.values,
                    validationType: state.validationType
                  }
                };
      case "SelectField" :
          var field$2 = action._0;
          return {
                  TAG: "SideEffects",
                  _0: (function (param) {
                      Core__Option.forEach(Core__Option.map(getRef({
                                    TAG: "Field",
                                    _0: field$2
                                  }), Util_Dom.getDomRef), SetFocus.setFocus);
                    })
                };
      
    }
  };
  var makeI18n = function (labels, formaters) {
    if (labels === undefined) {
      return Type_SchemaI18n.default;
    }
    if (formaters === undefined) {
      return Type_SchemaI18n.default;
    }
    var match = labels.formValidation;
    var formValidationStringMax = formaters.formValidationStringMax;
    var formValidationStringMin = formaters.formValidationStringMin;
    var formValidationRequired = match.required;
    var formValidationEmail = match.email;
    var formValidationEitherRequired = match.eitherRequired;
    return {
            confirmation: Type_SchemaI18n.default.confirmation,
            email: (function (param) {
                return formValidationEmail;
              }),
            stringNonEmpty: (function (param) {
                return formValidationRequired;
              }),
            eitherStringNonEmpty: (function (param, param$1) {
                return formValidationEitherRequired;
              }),
            stringRegExp: Type_SchemaI18n.default.stringRegExp,
            stringMin: (function (_value, min) {
                return formValidationStringMin(min);
              }),
            stringMax: (function (_value, max) {
                return formValidationStringMax(max);
              }),
            date: match.date,
            required: formValidationRequired,
            password: match.password
          };
  };
  var useRefs = function () {
    return UseRefs.make(FieldComparator);
  };
  var use = function (initialValues, schema, onSubmit, validationTypeOpt, labels, formaters) {
    var validationType = validationTypeOpt !== undefined ? validationTypeOpt : "OnSubmit";
    var i18n = makeI18n(labels, formaters);
    var initialState_fieldsState = getInitialFieldsState(schema);
    var initialState = {
      fieldsState: initialState_fieldsState,
      formState: "Pristine",
      values: initialValues,
      validationType: validationType
    };
    var match = UseRefs.make(FieldComparator);
    var getRef = match[0];
    var match$1 = ReactUpdate.useReducer((function (extra, extra$1) {
            return reducer(onSubmit, i18n, schema, getRef, extra, extra$1);
          }), initialState);
    var send = match$1[1];
    var state = match$1[0];
    var getFieldState = function (field) {
      return Core__Option.getOr(Belt_Map.get(state.fieldsState, field), "Pristine");
    };
    var getFieldError = function (field) {
      var error = getFieldState(field);
      if (typeof error !== "object") {
        return ;
      } else {
        return error._0;
      }
    };
    return {
            values: state.values,
            formState: state.formState,
            getFieldState: getFieldState,
            getFieldError: getFieldError,
            getFieldRef: match[1],
            handleChange: (function (field, value) {
                send({
                      TAG: "FieldChangeValue",
                      _0: field,
                      _1: value
                    });
              }),
            submit: (function () {
                send("TrySubmit");
              })
          };
  };
  return {
          Schema: Schema,
          Validation: undefined,
          FieldComparator: FieldComparator,
          getInitialFieldsState: getInitialFieldsState,
          reducer: reducer,
          makeI18n: makeI18n,
          useRefs: useRefs,
          use: use
        };
}

export {
  Make ,
}
/* UseRefs Not a pure module */
