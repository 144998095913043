// Generated by ReScript, PLEASE EDIT WITH CARE


var $$default = {
  accessibility: {
    close: "",
    closeSubheader: "",
    newWindow: "",
    readLess: "",
    readMore: "",
    seeMore: "",
    showVideosSortingOptions: "",
    sortVideoBy: ""
  },
  collection: {
    teaser: {
      more: {
        label: ""
      }
    }
  },
  common: {
    add: "",
    all_videos: "",
    confirm: "",
    download: "",
    downloadAll: "",
    edit: "",
    filter_by: "",
    firstBroadcast: "",
    fskSubHeader: "",
    fskSubHeaderLink: "",
    loading: "",
    moreInfos: "",
    press: "",
    read_less: "",
    read_more: "",
    remove: "",
    safariVersionDeprecated: "",
    save: "",
    see_more: "",
    setting: "",
    subHeader: ""
  },
  cookie: {
    banner: {
      welcomeLabel: "",
      welcomeSubtitle: "",
      acceptButton: "",
      acceptButtonTitle: "",
      refuseButton: "",
      refuseButtonTitle: "",
      settingsButton: "",
      settingsButtonTitle: "",
      choiceTitle: "",
      choiceDescription: ""
    }
  },
  error: {
    buttonLabel500: "",
    buttonUrl500: "",
    errorCode: "",
    iaSticker: "",
    subtitle404: "",
    title500: ""
  },
  feedback: {
    buttonLabel: "",
    closeLabel: "",
    dataLabel: "",
    dataLink: "",
    description: "",
    dislikedLabel: "",
    emailLabel: "",
    faqLabel: "",
    faqLink: "",
    genericError: "",
    labelFive: "",
    labelFour: "",
    labelOne: "",
    labelThree: "",
    labelTwo: "",
    likedLabel: "",
    ratingLabel: "",
    saveLabel: "",
    successButton: "",
    successDescription: "",
    successTitle: "",
    title: ""
  },
  formValidation: {
    date: "",
    eitherRequired: "",
    email: "",
    emailConfirmation: "",
    password: "",
    passwordConfirmation: "",
    required: ""
  },
  guide: {
    concerts: {
      soonColumnTitle: ""
    },
    dayAbbreviation: "",
    hoursMinutes: "",
    monthAbbreviation: "",
    tv: {
      daySlider: {
        dateFormat: "",
        dayFormat: "",
        redirectChooseDate: "",
        redirectProgram: "",
        today: "",
        tomorrow: "",
        yesterday: ""
      },
      daytime: "",
      evening: "",
      morning: ""
    }
  },
  header: {
    club: {
      login: "",
      pseudo: ""
    },
    editProfile: {
      href: "",
      title: ""
    },
    help: {
      href: "",
      title: ""
    },
    installApp: "",
    myAdvantages: {
      href: "",
      title: ""
    },
    myVideosLink: {
      href: "",
      label: ""
    },
    redirectionAnchor: {
      redirectMainLabel: "",
      redirectPlayerLabel: "",
      redirectSearchLabel: ""
    },
    registration: {
      title: ""
    },
    searchNavigation: {
      closeLabel: "",
      displayLabel: "",
      href: ""
    },
    settings: {
      href: "",
      title: ""
    }
  },
  label: {
    live: ""
  },
  mona: {
    advantage: {
      subscribedLabel: ""
    },
    deleteAccount: {
      description: "",
      title: ""
    },
    editEmail: {
      description: "",
      errorAlreadyRegistered: "",
      errorGeneric: "",
      placeholder: "",
      placeholderConfirmation: "",
      success: ""
    },
    editPassword: {
      newPasswordPlaceholder: "",
      newPasswordPlaceholderConfirmation: "",
      oldPasswordPlaceholder: "",
      success: "",
      title: ""
    },
    events: {
      errorAlreadyParticipated: "",
      errorInvalidEvent: "",
      errorParticipationEnded: "",
      eventCta: "",
      eventCtaEnded: "",
      eventCtaParticipated: "",
      formDescription: "",
      formTitle: "",
      listTitle: "",
      moreInfos: "",
      participatedToastMessage: "",
      rules: "",
      rulesLabel: ""
    },
    fsk: {
      description: "",
      title: "",
      verified16: "",
      verified18: "",
      verifybutton: ""
    },
    login: {
      emailNotVerified: "",
      footerLinkLabel: "",
      footerLinkUrl: "",
      forgotPassword: "",
      invalidCredentials: "",
      landingButton: "",
      landingTitle: "",
      loginButton: "",
      magicLinkButton: "",
      magicLinkTitle: "",
      newPasswordTitle: "",
      passwordPlaceholder: "",
      requestPasswordButton: "",
      requestPasswordCancelButton: "",
      requestPasswordResetButton: "",
      requestPasswordSubtitle: "",
      requestPasswordSuccessButton: "",
      requestPasswordSuccessTitle: "",
      requestPasswordTitle: "",
      title: ""
    },
    magicLink: {
      reSendMailButton: "",
      subtitle: ""
    },
    myVideos: {
      emptyFavoriteTeaserTitle: "",
      emptyHistoryTeaserTitle: "",
      emptyPersonalZoneTeaserTitle: "",
      purgeFavoriteModalText: "",
      purgeFavoriteModalTitle: "",
      purgeHistoryModalText: "",
      purgeHistoryModalTitle: "",
      purgeModalCancel: "",
      purgeModalConfirm: ""
    },
    navigation: {
      changeAvatar: "",
      connectMyTv: "",
      disconnect: "",
      disconnection: "",
      events: "",
      eventsFavorite: "",
      magazine: "",
      myAccount: "",
      myAccountDescription: "",
      myVideosTitle: ""
    },
    profile: {
      addressDescription: "",
      addressTitle: "",
      avatarListTitle: "",
      backLabel: "",
      birthdayDayLabel: "",
      birthdayMonthLabel: "",
      birthdayTitle: "",
      birthdayYearLabel: "",
      cityPlaceholder: "",
      countryLabel: "",
      emailPlaceholder: "",
      errorMessage: "",
      firstNamePlaceholder: "",
      genderMadamValue: "",
      genderPlaceHolder: "",
      genderSirValue: "",
      lastNamePlaceholder: "",
      postCodePlaceholder: "",
      streetPlaceholder: "",
      sucessMessage: "",
      title: ""
    },
    register: {
      acceptTosCguLabel: "",
      acceptTosCguLink: "",
      acceptTosDataLabel: "",
      acceptTosDataLink: "",
      emailAccessibilityLabel: "",
      emailActionLabel: "",
      footerLabel: "",
      footerLinkLabel: "",
      footerLinkUrl: "",
      passwordAccessibilityLabel: "",
      passwordAccessibilityLabelOff: "",
      passwordAccessibilityLabelOn: "",
      passwordActionLabelOff: "",
      passwordActionLabelOn: "",
      registerButton: "",
      title: "",
      verifyDescription: "",
      verifyFooter: "",
      verifyMainButton: "",
      verifySecondaryButton: "",
      verifySubtitle: ""
    },
    subHeader: {
      accountCreatedLinkLabel: "",
      accountCreatedLinkUrl: "",
      accountCreatedText: "",
      accountLoggedInText: "",
      expiredMagicLinkText: "",
      updatedPasswordSuccessText: ""
    },
    tvAgeVerification: {
      ageVerifiedDescription: "",
      ageVerifiedPrimaryButton: "",
      userCheckConnectedAccountDescription: "",
      userCheckDescription: "",
      userCheckPrimaryButton: "",
      userCheckSecondaryButton: "",
      userCheckTitle: ""
    },
    tvConnection: {
      description: "",
      title: ""
    },
    tvLogin: {
      ageVerifiedTitle: ""
    },
    verificationFailed: {
      buttonLabel: "",
      contactButtonLabel: "",
      contactButtonLinkUrl: "",
      contactLabel: "",
      subtitle: "",
      title: ""
    },
    verificationPending: {
      buttonLabel: "",
      subtitle: "",
      text: "",
      title: ""
    }
  },
  navbar: {
    mainNavigation: "",
    secondaryNavigation: ""
  },
  program: {
    accessible: "",
    ageRestriction_cta: "",
    ageRestriction_label: "",
    audiodescription: "",
    available: "",
    chapter_title: "",
    chapter_title_concert: "",
    duration: "",
    genre: "",
    geoblockedLabel: "",
    geoblockedLinkLabel: "",
    geoblockedLinkUrl: "",
    history_delete: "",
    in_partnership_with: "",
    live_on: "",
    next_broadcast: "",
    premiere_date_format: "",
    showOf: "",
    to_be_continued: "",
    trailer_button: "",
    vod: "",
    vod_discover: ""
  },
  reminder: {
    alert: {
      add: ""
    },
    subscribe: {
      add: "",
      remove: ""
    }
  },
  search: {
    currently_loading: "",
    placeholder: "",
    search_results_for: "",
    searching: "",
    what_are_you_looking_for: ""
  },
  setting: {
    autoplayDescription: "",
    autoplayTitle: "",
    clipAutoplayDescription: "",
    clipAutoplayTitle: "",
    cookieDescription: "",
    cookieLabel: "",
    cookieTitle: "",
    playerAutoplayDescription: "",
    playerAutoplayTitle: "",
    sobrietyMessageAriaLabel: "",
    sobrietyMessageLinkLabel: "",
    sobrietyMessageLinkUrl: "",
    sobrietyMessageText: "",
    sobrietyMessageTitle: "",
    userDescription: "",
    userTargetedCommunicationDescription: "",
    userTargetedCommunicationTitle: "",
    userTitle: "",
    videoQualityAuto: "",
    videoQualityDescription: "",
    videoQualityEco: "",
    videoQualityTitle: ""
  },
  shorts: {
    more_shorts: "",
    title: ""
  },
  teaser: {
    durationUnit: ""
  },
  teasers: {
    carousel: {
      goToSlide: "",
      pause: ""
    },
    continueWatching: "",
    live: "",
    livestream: "",
    moreOptions: "",
    watch: "",
    watchAgain: "",
    watchLive: "",
    watchLivestream: ""
  },
  userPref: {
    message: "",
    setupCookies: ""
  },
  videos: {
    sort_by_label: ""
  }
};

export {
  $$default as default,
}
/* No side effect */
