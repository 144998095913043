// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as UseOnBlur from "../../../hook/UseOnBlur.res.mjs";
import * as UseToggle from "../../../hook/UseToggle.res.mjs";
import * as Util_I18n from "../../../util/Util_I18n.res.mjs";
import * as Atom_Image from "../../atom/Atom_Image.res.mjs";
import * as Emac_Menus from "../../../emac/Emac_Menus.res.mjs";
import * as Type_Image from "../../../type/Type_Image.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Header from "../../../type/Type_Header.res.mjs";
import * as Atom_Divider from "../../atom/Atom_Divider.res.mjs";
import * as Context_I18n from "../../../provider/Context_I18n.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as User_Provider from "../../../user/User_Provider.res.mjs";
import * as Util_Debounce from "../../../util/Util_Debounce.res.mjs";
import * as Particle_zIndex from "../../particle/Particle_zIndex.res.mjs";
import * as UseClickOutside from "../../../hook/UseClickOutside.res.mjs";
import * as Layout_Constants from "../../layout/Layout_Constants.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Atom_FavAlertToaster from "../../atom/Atom_FavAlertToaster.res.mjs";
import * as Organism_Header_Panel from "./Organism_Header_Panel.res.mjs";
import * as Particle_Background_Ds from "../../particle/Particle_Background_Ds.res.mjs";
import * as Molecule_LanguageSwitcher from "../../molecule/Molecule_LanguageSwitcher.res.mjs";
import * as Organism_SearchNavigation from "./Organism_SearchNavigation.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";
import * as Organism_ConcertNavigation from "./concert/Organism_ConcertNavigation.res.mjs";
import * as Organism_Header_Subheaders from "./Organism_Header_Subheaders.res.mjs";
import * as Organism_Concert_SocialLinks from "./concert/Organism_Concert_SocialLinks.res.mjs";
import * as Organism_HeaderRedirectionAnchor from "./Organism_HeaderRedirectionAnchor.res.mjs";

var oneTimeEventRules = Particle_Screen_Ds.make(undefined, undefined, undefined, [
      CssJs.display("flex"),
      CssJs.flexGrow(2)
    ], undefined, [CssJs.display("none")]);

var myVideoDisplay = Particle_Screen_Ds.make(undefined, undefined, undefined, "inlineFlex", undefined, "none");

function make(param) {
  var onClick = param.onClick;
  var search = param.search;
  var oneTimeEvent = param.oneTimeEvent;
  var forcedUserStatus = param.forcedUserStatus;
  var logoBerne = Core__Option.getOr(param.logoBerne, false);
  var isFixed = Core__Option.getOr(param.isFixed, true);
  var displayedSubHeaders = Core__Option.getOr(param.subHeadersToDisplay, []);
  var requiredConsentSubHeaders = Core__Option.getOr(param.requiredConsentSubHeaders, []);
  var match = Context.I18n.getState();
  var locale = match.locale;
  var match$1 = Context.Config.getState();
  var match$2 = match.labels.header;
  var searchNavigation = match$2.searchNavigation;
  var myVideosLink = match$2.myVideosLink;
  var data = Emac_Menus.toDsNavigation(param.menuItems, undefined);
  var languageSwitcherBehavior = onClick !== undefined ? ({
        TAG: "Button",
        _0: onClick
      }) : "Link";
  var concert = data.Concert;
  var match$3 = search !== undefined ? [
      search.isOpen ? "On" : "Off",
      search.onOpen,
      search.onClose,
      search.placeholder,
      Util_Debounce.make(search.onChange, search.debounce),
      search.searchBarStatus,
      search.initialQuery
    ] : [
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      "dynamic",
      undefined
    ];
  var match$4 = UseToggle.make(match$3[0], match$3[1], match$3[2], undefined, undefined, undefined);
  var searchState = match$4.state;
  var searchRef = React.useRef(null);
  var closeSearch = match$3[5] === "dynamic" ? match$4.close : (function () {
        
      });
  Organism_Header_Subheaders.Hook.use(match$1.api.consentStatus, displayedSubHeaders, requiredConsentSubHeaders, param.onCloseSubHeader);
  UseClickOutside.make(searchRef, closeSearch);
  UseOnBlur.make(searchRef, closeSearch);
  var renderOneTimeEvent;
  if (oneTimeEvent !== undefined) {
    var redirectLink = oneTimeEvent.redirectLink;
    renderOneTimeEvent = JsxRuntime.jsx(Atom_Cell.make, {
          justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
          children: JsxRuntime.jsx(Atom_Cta.Link.Block.make, {
                children: JsxRuntime.jsx(Atom_Image.make, {
                      alt: redirectLink.label,
                      autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
                      src: Type_Image.Src.makeFromUrl(oneTimeEvent.imageSrc),
                      style: "PromotionalLogo"
                    }),
                href: redirectLink.href,
                style: Atom_Cta_Ds.Header.oneTimeEvent,
                target: "_blank"
              }),
          rules: Caml_option.some(oneTimeEventRules)
        });
  } else {
    renderOneTimeEvent = null;
  }
  var country = Util_I18n.isEurope(locale) ? "Europe" : "Default";
  var socialRules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.display("flex")], undefined, undefined, [CssJs.display("none")]);
  var zIndexRule = Particle_zIndex.Particle_zIndex_Css.toCss({
        TAG: "Exact",
        _0: "Header"
      });
  var userStatus = User_Provider.Hook.useUserStatus();
  var userStatus$1 = forcedUserStatus !== undefined ? forcedUserStatus : userStatus;
  var positionRule = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, isFixed ? [
          CssJs.position(CssJs.sticky),
          zIndexRule,
          CssJs.top(CssJs.px(0))
        ] : []);
  var centerRightLinks = JsxRuntime.jsx(JsxRuntime.Fragment, {
        children: Caml_option.some(JsxRuntime.jsxs(Atom_Row.make, {
                  children: [
                    JsxRuntime.jsx(Organism_Concert_SocialLinks.make, {
                          socialNetworks: concert.socialNetworks
                        }),
                    JsxRuntime.jsx(Atom_Cell.make, {
                          blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("S"))),
                          children: JsxRuntime.jsx(Atom_Divider.make, {
                                style: Atom_Divider.headerSocialLink
                              })
                        })
                  ],
                  rules: Caml_option.some(socialRules)
                }))
      });
  var userButton = JsxRuntime.jsx(Organism_Header_Panel.make, {
        userStatus: userStatus$1,
        searchState: searchState,
        help: match$2.help,
        myAdvantages: match$2.myAdvantages,
        registration: match$2.registration,
        editProfile: match$2.editProfile
      });
  var myVideosButton;
  var exit = 0;
  switch (userStatus$1) {
    case "Anonymous" :
    case "Connected" :
        exit = 1;
        break;
    case "Disconnected" :
        myVideosButton = null;
        break;
    
  }
  if (exit === 1) {
    myVideosButton = JsxRuntime.jsx(Atom_Cta.Link.make, {
          href: myVideosLink.href,
          label: myVideosLink.label,
          style: Atom_Cta_Ds.Header.makeMyVideo(myVideoDisplay)
        });
  }
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsxs(Atom_Row.make, {
                      background: Caml_option.some(Particle_Background_Ds.header),
                      children: [
                        JsxRuntime.jsx(Organism_HeaderRedirectionAnchor.make, {
                              labels: match$2.redirectionAnchor,
                              searchNavigation: searchNavigation
                            }),
                        JsxRuntime.jsxs(Atom_Row.make, {
                              background: Caml_option.some(Particle_Background_Ds.header),
                              blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeHorizontal("M"), undefined, undefined, Particle_Spacer_Ds.makeNone())),
                              children: [
                                JsxRuntime.jsx(Organism_ConcertNavigation.make, {
                                      labels: concert,
                                      country: country,
                                      logoNavigation: data.LogoNavigation,
                                      searchState: searchState,
                                      logoBerne: logoBerne
                                    }),
                                JsxRuntime.jsxs(Atom_Row.make, {
                                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                                      autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
                                      children: [
                                        renderOneTimeEvent,
                                        centerRightLinks,
                                        myVideosButton,
                                        JsxRuntime.jsx(Organism_SearchNavigation.make, {
                                              searchNavigation: searchNavigation,
                                              onChange: match$3[4],
                                              placeholder: match$3[3],
                                              initialQuery: match$3[6],
                                              state: searchState,
                                              toggle: match$4.toggle
                                            }),
                                        userButton,
                                        JsxRuntime.jsx(Molecule_LanguageSwitcher.make, {
                                              locale: locale,
                                              searchState: searchState,
                                              alternativeLanguages: Belt_Option.map(param.languages, Context_I18n.ensureAlternativeLanguages),
                                              languageSwitcherBehavior: languageSwitcherBehavior
                                            })
                                      ],
                                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentFlexEnd),
                                      wrap: "OnOneLine",
                                      ref_: searchRef
                                    })
                              ],
                              height: Caml_option.some(Type_Header.Constants.heightStyle),
                              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                              wrap: "OnOneLine"
                            })
                      ],
                      height: Caml_option.some(Type_Header.Constants.heightStyle),
                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentSpaceBetween),
                      kind: "Header",
                      maxWidth: Caml_option.some(Layout_Constants.maxWidthMainContentScreen),
                      width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                      wrap: "OnOneLine"
                    }),
                JsxRuntime.jsx(Organism_Header_Subheaders.Toaster.Container.make, {}),
                JsxRuntime.jsx(Atom_FavAlertToaster.Toaster.Container.make, {})
              ],
              rules: Caml_option.some(positionRule)
            });
}

var SubHeader;

export {
  SubHeader ,
  oneTimeEventRules ,
  myVideoDisplay ,
  make ,
}
/* oneTimeEventRules Not a pure module */
