// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Box from "../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var maxWidth = Particle_Screen_Ds.make(undefined, undefined, {
      NAME: "percent",
      VAL: 100 / 3
    }, undefined, undefined, {
      NAME: "percent",
      VAL: 100
    });

var columnBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeVertical("S"));

var boxBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("L"), undefined, Particle_Spacer_Ds.makeVertical("S"));

var rowBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("M", "None"), undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("M", "M"));

var justifyContent = Particle_Screen_Ds.make(undefined, undefined, undefined, "spaceAround", undefined, "center");

var direction = Particle_Screen_Ds.make(undefined, undefined, "column", undefined, undefined, "row");

var containerDirection = Particle_Screen_Ds.make(undefined, undefined, "row", undefined, undefined, "column");

var containerInnerSpace = Particle_Screen_Ds.make(undefined, undefined, "L", undefined, undefined, "None");

var buttonInnerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

function make(param) {
  var onOpenSettings = param.onOpenSettings;
  var onChangeConsent = param.onChangeConsent;
  var match = Context.I18n.getState();
  var match$1 = match.labels.cookie.banner;
  return JsxRuntime.jsxs(Atom_Box.make, {
              background: Caml_option.some(Particle_Background_Ds.white),
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      background: Caml_option.some(Particle_Background_Ds.white),
                      blockSpace: Caml_option.some(columnBlockSpace),
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$1.welcomeLabel),
                              kind: "h2",
                              style: Atom_Text_Ds.CookieBanner.welcomeTitle
                            }),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$1.welcomeSubtitle),
                              style: Atom_Text_Ds.CookieBanner.subtitle
                            })
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")),
                      justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                      maxWidth: Caml_option.some(maxWidth)
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      background: Caml_option.some(Particle_Background_Ds.white),
                      blockSpace: Caml_option.some(columnBlockSpace),
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$1.choiceTitle),
                              kind: "h2",
                              style: Atom_Text_Ds.CookieBanner.title
                            }),
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$1.choiceDescription),
                              style: Atom_Text_Ds.CookieBanner.description
                            })
                      ],
                      height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
                      innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "XS")),
                      maxWidth: Caml_option.some(maxWidth)
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(boxBlockSpace),
                      children: [
                        JsxRuntime.jsxs(Atom_Box.make, {
                              children: [
                                JsxRuntime.jsx(Atom_Cta.Button.make, {
                                      label: match$1.acceptButton,
                                      onClick: (function (param) {
                                          onChangeConsent(true);
                                        }),
                                      style: Atom_Cta_Ds.CookieBanner.choice,
                                      title: match$1.acceptButtonTitle
                                    }),
                                JsxRuntime.jsx(Atom_Cta.Button.make, {
                                      label: match$1.refuseButton,
                                      onClick: (function (param) {
                                          onChangeConsent(false);
                                        }),
                                      style: Atom_Cta_Ds.CookieBanner.choice,
                                      title: match$1.refuseButtonTitle
                                    })
                              ],
                              direction: Caml_option.some(direction),
                              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                              wrap: "MultiLine",
                              innerSpace: Caml_option.some(buttonInnerSpace)
                            }),
                        JsxRuntime.jsx(Atom_Cell.make, {
                              justifyContent: Caml_option.some(justifyContent),
                              children: JsxRuntime.jsx(Atom_Cta.Button.make, {
                                    label: match$1.settingsButton,
                                    onClick: (function (param) {
                                        onOpenSettings();
                                      }),
                                    style: Atom_Cta_Ds.CookieBanner.link,
                                    title: match$1.settingsButtonTitle
                                  })
                            })
                      ]
                    })
              ],
              direction: Caml_option.some(containerDirection),
              justifyContent: Caml_option.some(justifyContent),
              spacer: Caml_option.some(rowBlockSpace),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
              wrap: "MultiLine",
              innerSpace: Caml_option.some(containerInnerSpace)
            });
}

var background = Particle_Background_Ds.white;

export {
  background ,
  maxWidth ,
  columnBlockSpace ,
  boxBlockSpace ,
  rowBlockSpace ,
  justifyContent ,
  direction ,
  containerDirection ,
  containerInnerSpace ,
  buttonInnerSpace ,
  make ,
}
/* maxWidth Not a pure module */
