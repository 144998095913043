// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../provider/Context.res.mjs";
import * as Atom_Box from "../design-system/atom/Atom_Box.res.mjs";
import * as Caml_obj from "@rescript/std/lib/es6/caml_obj.js";
import * as Atom_Cell from "../design-system/atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../design-system/atom/Atom_Text.res.mjs";
import * as Type_Club from "../club/Type_Club.res.mjs";
import * as Zone_Type from "./Zone_Type.res.mjs";
import * as UseVisible from "../hook/UseVisible.res.mjs";
import * as Atom_Column from "../design-system/atom/Atom_Column.res.mjs";
import * as Atom_Loader from "../design-system/atom/Atom_Loader.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Identifiers from "../selector/Identifiers.res.mjs";
import * as Type_String from "../type/Type_String.res.mjs";
import * as Type_Teaser from "../type/Type_Teaser.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Core__Result from "@rescript/core/src/Core__Result.res.mjs";
import * as Type_Category from "../type/Type_Category.res.mjs";
import * as UsePagination from "../hook/UsePagination.res.mjs";
import * as User_Provider from "../user/User_Provider.res.mjs";
import * as Molecule_Banner from "../design-system/molecule/Molecule_Banner.res.mjs";
import * as Particle_zIndex from "../design-system/particle/Particle_zIndex.res.mjs";
import * as User_Observable from "../user/User_Observable.res.mjs";
import * as Layout_Constants from "../design-system/layout/Layout_Constants.res.mjs";
import * as Organism_Carousel from "../design-system/organism/carousel/Organism_Carousel.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Molecule_EventZone from "../design-system/molecule/Molecule_EventZone.res.mjs";
import * as Molecule_TopTeaser from "../design-system/molecule/Molecule_TopTeaser.res.mjs";
import * as Particle_Screen_Ds from "../design-system/particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../design-system/particle/Particle_Spacer_Ds.res.mjs";
import * as Zone_Layout_Constant from "./Zone_Layout_Constant.res.mjs";
import * as Molecule_TopTeaser_Ds from "../design-system/molecule/Molecule_TopTeaser_Ds.res.mjs";
import * as Particle_Animation_Ds from "../design-system/particle/Particle_Animation_Ds.res.mjs";
import * as Molecule_PaginatedGrid from "../design-system/molecule/Molecule_PaginatedGrid.res.mjs";
import * as Organism_Slider_Teaser from "../design-system/organism/slider/teaser/Organism_Slider_Teaser.res.mjs";
import * as Particle_Background_Ds from "../design-system/particle/Particle_Background_Ds.res.mjs";
import * as Organism_NewsletterZone from "../design-system/organism/newsletterZone/Organism_NewsletterZone.res.mjs";
import * as User_Api__Sso__Favorites from "../user/User_Api/User_Api__Sso__Favorites.res.mjs";
import * as Particle_Screen_Constants from "../design-system/particle/Particle_Screen_Constants.res.mjs";
import * as Molecule_ContentCollection from "../design-system/molecule/Molecule_ContentCollection.res.mjs";
import * as User_Api__Sso__LastVieweds from "../user/User_Api/User_Api__Sso__LastVieweds.res.mjs";
import * as User_Api__Sso__PersonalZone from "../user/User_Api/User_Api__Sso__PersonalZone.res.mjs";
import * as Molecule_ContentCollection_Css from "../design-system/molecule/Molecule_ContentCollection_Css.res.mjs";
import * as Particle_Accessibility_Constant from "../design-system/particle/Particle_Accessibility_Constant.res.mjs";
import * as Organism_Slider_Teaser_MetaInformation from "../design-system/organism/slider/teaser/Organism_Slider_Teaser_MetaInformation.res.mjs";

function setDisplayName(make, name) {
  make.displayName = "Zone_" + name;
}

var make = React.memo(function (props) {
      var teaser = props.teaser;
      var teaserTitle = Core__Option.getOr(Type_Teaser.Helper.extractTitle(teaser), Type_String.Teaser.Title.empty);
      return JsxRuntime.jsx(Molecule_Banner.make, {
                  url: teaser.href,
                  title: teaserTitle,
                  images: teaser.imageSrc,
                  trackingPixel: teaser.trackingPixel,
                  blockSpace: Caml_option.some(props.blockSpace)
                });
    });

setDisplayName(make, "Banner");

var Banner = {
  make: make
};

function getButtonAvailabilities(programKind) {
  if (programKind !== undefined) {
    if (programKind === "MAGAZINE") {
      return [
              "Available",
              "NotAvailable"
            ];
    } else if (programKind === "TV_SERIES") {
      return [
              "NotAvailable",
              "Available"
            ];
    } else {
      return [
              "NotAvailable",
              "NotAvailable"
            ];
    }
  } else {
    return [
            "NotAvailable",
            "NotAvailable"
          ];
  }
}

var make$1 = React.memo(function (props) {
      var __favoriteButtonAvailability = props.favoriteButtonAvailability;
      var clip = props.clip;
      var description = props.description;
      var teaser = props.teaser;
      var favoriteButtonAvailability = __favoriteButtonAvailability !== undefined ? __favoriteButtonAvailability : "NotAvailable";
      var match = teaser.program;
      var trailer = match.trailer;
      var id = teaser.id;
      var match$1 = getButtonAvailabilities(match.kind);
      var programId = Type_String.Program.Id.make(Type_String.Teaser.Id.toString(id));
      var teaserTitle = Type_Teaser.Helper.extractTitle(teaser);
      var subtitle = Type_Teaser.Helper.extractSubtitleString(teaser.subtitle);
      var match$2 = teaser.description;
      var description$1 = match$2 !== undefined ? match$2 : (
          description !== undefined ? Type_String.Teaser.Description.make(description) : Type_String.Teaser.Description.empty
        );
      var video = trailer !== undefined ? ({
            TAG: "Trailer",
            _0: trailer,
            _1: Core__Option.getOr(props.trailerBehavior, "Forced")
          }) : (
          clip !== undefined ? ({
                TAG: "Clip",
                _0: clip,
                _1: "Standard"
              }) : undefined
        );
      return JsxRuntime.jsx(Molecule_ContentCollection.make, {
                  partners: teaser.partners,
                  images: teaser.imageSrc,
                  subtitle: subtitle,
                  teaserTitle: teaserTitle,
                  description: description$1,
                  parent: props.parent,
                  id: id,
                  subscribeButtonAvailability: match$1[0],
                  nextEpisodeButtonAvailability: match$1[1],
                  favoriteButtonAvailability: favoriteButtonAvailability,
                  blockSpace: Caml_option.some(props.blockSpace),
                  programId: programId,
                  video: video,
                  teaser: teaser,
                  primaryTitle: props.primaryTitle,
                  identifier: Caml_option.some(Identifiers.Zone_Layout.contentCollection),
                  metas: props.metas,
                  pageKind: props.pageKind
                });
    });

var ContentCollection = {
  getButtonAvailabilities: getButtonAvailabilities,
  make: make$1
};

var make$2 = React.memo(function (props) {
      var teaser = props.teaser;
      var match = Context.Config.getState();
      var trailerUrl = Core__Option.map(props.programId, match.api.getTrailerUrl);
      var teaserTitle = Core__Option.getOr(Type_Teaser.Helper.extractTitle(teaser), Type_String.Teaser.Title.empty);
      var subtitle = Type_Teaser.Helper.extractSubtitleString(teaser.subtitle);
      return JsxRuntime.jsx(Molecule_EventZone.make, {
                  title: teaserTitle,
                  subtitle: Core__Option.getOr(subtitle, Type_String.Teaser.Subtitle.empty),
                  teaserText: teaser.description,
                  images: teaser.imageSrc,
                  href: teaser.href,
                  trackingPixel: teaser.trackingPixel,
                  id: teaser.id,
                  buttonLabel: teaser.callToAction,
                  textPosition: props.textPosition,
                  blockSpace: Caml_option.some(props.blockSpace),
                  trailerUrl: trailerUrl,
                  clip: props.clip,
                  identifier: props.identifier,
                  action: props.action
                });
    });

setDisplayName(make$2, "EventZone");

var EventZone = {
  make: make$2
};

var make$3 = React.memo(function (props) {
      var zone = props.zone;
      var match = Context.I18n.getState();
      var labels = match.labels;
      var match$1 = labels.accessibility;
      var match$2 = labels.common;
      var match$3 = zone.title;
      var title = match$3 !== undefined ? (
          match$3.TAG === "Displayed" ? match$3._0 : undefined
        ) : match$2.all_videos;
      return JsxRuntime.jsx(Molecule_PaginatedGrid.make, {
                  id: "",
                  title: title,
                  seeMoreLabel: match$2.see_more,
                  zone: zone,
                  blockSpace: Caml_option.some(props.blockSpace),
                  relatedZones: props.relatedZones,
                  url: props.url,
                  identifier: props.identifier,
                  seeMoreAriaLabel: match$1.seeMore
                });
    });

setDisplayName(make$3, "PaginatedGrid");

var PaginatedGrid = {
  make: make$3
};

var make$4 = React.memo(function (props) {
      return JsxRuntime.jsx(Organism_Slider_Teaser.make, {
                  blockSpace: props.blockSpace,
                  zone: props.zone,
                  identifier: props.identifier
                });
    });

setDisplayName(make$4, "Slider");

var Slider = {
  make: make$4
};

var make$5 = React.memo(function (props) {
      return JsxRuntime.jsx(Organism_NewsletterZone.make, {
                  blockSpace: Caml_option.some(props.blockSpace),
                  teaser: props.teaser
                });
    });

setDisplayName(make$5, "Newsletter");

var Newsletter = {
  make: make$5
};

var make$6 = React.memo(function (props) {
      var match = Context.Config.getState();
      var trailerUrl = Core__Option.map(props.programId, match.api.getTrailerUrl);
      return JsxRuntime.jsx(Atom_Column.make, {
                  blockSpace: Caml_option.some(props.blockSpace),
                  children: JsxRuntime.jsx(Molecule_TopTeaser.make, {
                        pageKind: props.pageKind,
                        teaser: props.teaser,
                        style: Molecule_TopTeaser_Ds.makeDefault,
                        trailerUrl: trailerUrl,
                        clip: props.clip
                      }),
                  identifier: props.identifier,
                  width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                });
    });

setDisplayName(make$6, "TopTeaser");

var TopTeaser = {
  make: make$6
};

var make$7 = React.memo(function (props) {
      var pageKind = props.pageKind;
      var zone = props.zone;
      var match = React.useState(function () {
            return "Default";
          });
      var setKind = match[1];
      var onSlideChange = function () {
        setKind(function (param) {
              return "WithAnimation";
            });
      };
      var elementsAnimation = match[0] === "WithAnimation" ? Caml_option.some(Particle_Animation_Ds.TopTeaser.element) : undefined;
      return JsxRuntime.jsx(Organism_Carousel.make, {
                  id: zone.id,
                  children: zone.data.map(function (teaser) {
                        return JsxRuntime.jsx(Molecule_TopTeaser.make, {
                                    elementsAnimation: elementsAnimation,
                                    pageKind: pageKind,
                                    teaser: teaser,
                                    style: Molecule_TopTeaser_Ds.makeCarousel
                                  }, Type_String.Teaser.DsId.toString(teaser.dsId));
                      }),
                  kind: Organism_Carousel.Organism_Carousel_Ds.topTeaser(props.category, undefined),
                  label: "multipleTopTeasers",
                  onSlideChange: onSlideChange,
                  blockSpace: Caml_option.some(props.blockSpace),
                  identifier: Caml_option.some(Identifiers.Zone_Layout.topTeaserWithCarousel)
                }, zone.id);
    });

setDisplayName(make$7, "TopTeaserWithCarousel");

var TopTeaserWithCarousel = {
  make: make$7
};

var make$8 = React.memo(function (props) {
      var id = props.id;
      React.useEffect((function () {
              ((window && typeof window.NewsPilotReco === "function" && window.NewsPilotReco()));
            }), []);
      return JsxRuntime.jsxs(Atom_Column.make, {
                  alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                  background: Caml_option.some(Particle_Background_Ds.slider("Classic")),
                  blockSpace: Caml_option.some(props.blockSpace),
                  children: [
                    JsxRuntime.jsx(Organism_Slider_Teaser_MetaInformation.make, {
                          id: "ebubox-" + id,
                          theme: "Classic",
                          title: {
                            TAG: "Hidden",
                            _0: props.title
                          },
                          method: "Static",
                          blockSpace: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("Zero"))
                        }),
                    JsxRuntime.jsx(Atom_Box.make, {
                          kind: {
                            TAG: "EbuBox",
                            _0: {
                              ebuBox: id,
                              className: "pilotreco"
                            }
                          }
                        })
                  ],
                  identifier: Caml_option.some(Identifiers.Zone_Layout.ebuBox),
                  rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [Particle_zIndex.Particle_zIndex_Css.toCss({
                                  TAG: "Exact",
                                  _0: "EbuBox"
                                })])),
                  width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                }, id);
    });

setDisplayName(make$8, "EbuBox");

var EbuBox = {
  make: make$8
};

var make$9 = React.memo(function (props) {
      var zone = props.zone;
      var blockSpace = props.blockSpace;
      var match = Zone_Type.canBeDisplayed(zone);
      if (!match) {
        return JsxRuntime.jsx(Atom_Cell.make, {
                    accessibility: Particle_Accessibility_Constant.ariaHidden,
                    blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("Zero"))),
                    children: null,
                    identifier: Caml_option.some(Identifiers.Zone_Layout.zoneHidden)
                  });
      }
      var variant = zone.NAME;
      if (variant === "TopTeaserWithCarousel") {
        var match$1 = zone.VAL;
        var category = Core__Option.flatMap(match$1[2], Type_Category.getCategory);
        return JsxRuntime.jsx(make$7, {
                    blockSpace: blockSpace,
                    zone: match$1[0],
                    pageKind: match$1[1],
                    category: category
                  });
      }
      if (variant === "TopTeaserWithTrailer") {
        var match$2 = zone.VAL;
        return JsxRuntime.jsx(make$6, {
                    blockSpace: blockSpace,
                    teaser: match$2[0],
                    pageKind: match$2[1],
                    programId: match$2[2],
                    identifier: Caml_option.some(Identifiers.Zone_Layout.topTeaserWithTrailer)
                  });
      }
      if (variant === "TopTeaserWithClip") {
        var match$3 = zone.VAL;
        return JsxRuntime.jsx(make$6, {
                    blockSpace: blockSpace,
                    teaser: match$3[0],
                    pageKind: match$3[1],
                    clip: match$3[2],
                    identifier: Caml_option.some(Identifiers.Zone_Layout.topTeaserWithClip)
                  });
      }
      if (variant === "Banner") {
        return JsxRuntime.jsx(make, {
                    blockSpace: blockSpace,
                    teaser: zone.VAL
                  });
      }
      if (variant === "EventZone") {
        var match$4 = zone.VAL;
        var match$5 = match$4.video;
        var textPosition = match$4.textPosition;
        var teaser = match$4.teaser;
        if (match$5 !== undefined) {
          if (match$5.TAG === "Clip") {
            return JsxRuntime.jsx(make$2, {
                        blockSpace: blockSpace,
                        teaser: teaser,
                        textPosition: textPosition,
                        clip: {
                          TAG: "Clip",
                          _0: match$5._0,
                          _1: "Standard"
                        },
                        identifier: Caml_option.some(Identifiers.Zone_Layout.eventZoneWithClip),
                        action: match$4.action
                      });
          } else {
            return JsxRuntime.jsx(make$2, {
                        blockSpace: blockSpace,
                        teaser: teaser,
                        textPosition: textPosition,
                        programId: match$5._0,
                        identifier: Caml_option.some(Identifiers.Zone_Layout.eventZoneWithTrailer),
                        action: match$4.action
                      });
          }
        } else {
          return JsxRuntime.jsx(make$2, {
                      blockSpace: blockSpace,
                      teaser: teaser,
                      textPosition: textPosition,
                      identifier: Caml_option.some(Identifiers.Zone_Layout.eventZone),
                      action: match$4.action
                    });
        }
      }
      if (variant === "Slider") {
        return JsxRuntime.jsx(make$4, {
                    blockSpace: blockSpace,
                    zone: zone.VAL,
                    identifier: Caml_option.some(Identifiers.Zone_Layout.slider)
                  });
      }
      if (variant === "ContentCollection") {
        var match$6 = zone.VAL;
        return JsxRuntime.jsx(make$1, {
                    blockSpace: blockSpace,
                    teaser: match$6.teaser,
                    description: match$6.description,
                    parent: match$6.pageParent,
                    clip: match$6.clip,
                    favoriteButtonAvailability: match$6.favoriteButtonAvailability,
                    primaryTitle: match$6.primaryTitle,
                    trailerBehavior: match$6.trailerBehavior,
                    metas: match$6.metas,
                    pageKind: match$6.pageKind
                  });
      }
      if (variant === "TopTeaser") {
        var match$7 = zone.VAL;
        return JsxRuntime.jsx(make$6, {
                    blockSpace: blockSpace,
                    teaser: match$7[0],
                    pageKind: match$7[1],
                    identifier: Caml_option.some(Identifiers.Zone_Layout.topTeaser)
                  });
      }
      if (variant === "Newsletter") {
        return JsxRuntime.jsx(make$5, {
                    blockSpace: blockSpace,
                    teaser: zone.VAL
                  });
      }
      if (variant === "PaginatedGrid") {
        var match$8 = zone.VAL;
        var match$9 = match$8.relatedZones;
        var url = match$8.pageUrl;
        var zone$1 = match$8.zone;
        if (match$9 !== undefined) {
          return JsxRuntime.jsx(make$3, {
                      blockSpace: blockSpace,
                      zone: zone$1,
                      relatedZones: match$9.zones,
                      url: url,
                      identifier: Caml_option.some(Identifiers.Zone_Layout.paginatedGridWithRelatedZones)
                    });
        } else {
          return JsxRuntime.jsx(make$3, {
                      blockSpace: blockSpace,
                      zone: zone$1,
                      url: url,
                      identifier: Caml_option.some(Identifiers.Zone_Layout.paginatedGrid)
                    });
        }
      }
      var match$10 = zone.VAL;
      return JsxRuntime.jsx(make$8, {
                  blockSpace: blockSpace,
                  id: match$10[1],
                  title: match$10[0]
                });
    });

setDisplayName(make$9, "Static");

var Zone = {
  make: make$9
};

function favorite(title) {
  return Type_Club.Teaser.emptyConnectedZoneTeaser(title);
}

function lastviewed(title) {
  return Type_Club.Teaser.emptyConnectedZoneTeaser(title);
}

function personalzone(title) {
  return Type_Club.Teaser.emptyConnectedZoneTeaser(title);
}

var EmptyConnectedTeaser = {
  favorite: favorite,
  lastviewed: lastviewed,
  personalzone: personalzone
};

var make$10 = React.memo(function (props) {
      var __displayEmpty = props.displayEmpty;
      var contentId = props.contentId;
      var zone = props.zone;
      var displayEmpty = __displayEmpty !== undefined ? __displayEmpty : "Hide";
      var match = React.useState(function () {
            return [
                    undefined,
                    0
                  ];
          });
      var setLocalZone = match[1];
      var match$1 = match[0];
      var localZone = match$1[0];
      var match$2 = Context.I18n.getState();
      var locale = match$2.locale;
      var mona = match$2.labels.mona;
      var requestKind = React.useMemo((function () {
              if (contentId === "sso-history") {
                return "LastVieweds";
              } else if (contentId === "sso-favorites") {
                return "Favorites";
              } else {
                return "PersonalZone";
              }
            }), [contentId]);
      var status = User_Provider.Hook.useStatus();
      React.useEffect((function () {
              var isMount = {
                contents: true
              };
              var controller = new AbortController();
              var call = async function () {
                var signal = controller.signal;
                var tmp;
                switch (requestKind) {
                  case "Favorites" :
                      tmp = User_Api__Sso__Favorites.Get.call({
                            args: {
                              page: 1,
                              language: locale
                            },
                            signal: signal,
                            locale: locale
                          });
                      break;
                  case "LastVieweds" :
                      tmp = User_Api__Sso__LastVieweds.get({
                            args: {
                              page: 1,
                              language: locale
                            },
                            signal: signal,
                            locale: locale
                          });
                      break;
                  case "PersonalZone" :
                      tmp = User_Api__Sso__PersonalZone.Get.call({
                            args: {
                              page: 1,
                              language: locale
                            },
                            signal: signal,
                            locale: locale
                          });
                      break;
                  
                }
                var data = await tmp;
                var clubData = (function (__x) {
                      return Core__Result.map(__x, (function (response) {
                                    return [
                                            Type_Club.$$Response.getData(response),
                                            Type_Club.$$Response.getNextPage(response)
                                          ];
                                  }));
                    })(data);
                if (clubData.TAG !== "Ok") {
                  return ;
                }
                var match = clubData._0;
                var page = match[1];
                var data$1 = match[0].map(function (__x) {
                      return Type_Club.Teaser.toDsTeaser("min", undefined, __x);
                    });
                if (typeof zone !== "object") {
                  return ;
                }
                var variant = zone.NAME;
                if (variant === "Slider") {
                  var sliderZone = zone.VAL;
                  var newrecord = Caml_obj.obj_dup(sliderZone);
                  newrecord.nextPage = Core__Option.map(page, (function (nextPage) {
                          return {
                                  TAG: "Club",
                                  _0: requestKind,
                                  _1: nextPage
                                };
                        }));
                  var tmp$1;
                  if (data$1.length !== 0 || displayEmpty === "Hide") {
                    tmp$1 = data$1;
                  } else {
                    switch (requestKind) {
                      case "Favorites" :
                          tmp$1 = [Type_Club.Teaser.emptyConnectedZoneTeaser(mona.myVideos.emptyFavoriteTeaserTitle)];
                          break;
                      case "LastVieweds" :
                          tmp$1 = [Type_Club.Teaser.emptyConnectedZoneTeaser(mona.myVideos.emptyHistoryTeaserTitle)];
                          break;
                      case "PersonalZone" :
                          tmp$1 = [Type_Club.Teaser.emptyConnectedZoneTeaser(mona.myVideos.emptyPersonalZoneTeaserTitle)];
                          break;
                      
                    }
                  }
                  newrecord.data = tmp$1;
                  var tmp$2;
                  tmp$2 = data$1.length !== 0 || displayEmpty === "Hide" ? sliderZone.itemTemplate : "LandscapeGenre";
                  newrecord.itemTemplate = tmp$2;
                  var match$1 = data$1.length < 5;
                  var tmp$3;
                  tmp$3 = match$1 && displayEmpty !== "Hide" ? undefined : sliderZone.link;
                  newrecord.link = tmp$3;
                  var newZone = {
                    NAME: "Slider",
                    VAL: newrecord
                  };
                  if (isMount.contents) {
                    return setLocalZone(function (param) {
                                return [
                                        newZone,
                                        1
                                      ];
                              });
                  } else {
                    return ;
                  }
                }
                if (variant !== "PaginatedGrid") {
                  return ;
                }
                var paginatedGridTemplate = zone.VAL;
                var zone$1 = paginatedGridTemplate.zone;
                var newrecord$1 = Caml_obj.obj_dup(paginatedGridTemplate);
                var newrecord$2 = Caml_obj.obj_dup(zone$1);
                newrecord$2.nextPage = Core__Option.map(page, (function (nextPage) {
                        return {
                                TAG: "Club",
                                _0: requestKind,
                                _1: nextPage
                              };
                      }));
                newrecord$2.data = data$1;
                var match$2 = data$1.length;
                newrecord$2.link = match$2 !== 0 ? zone$1.link : undefined;
                newrecord$1.zone = newrecord$2;
                var newZone$1 = {
                  NAME: "PaginatedGrid",
                  VAL: newrecord$1
                };
                if (isMount.contents) {
                  return setLocalZone(function (param) {
                              return [
                                      newZone$1,
                                      1
                                    ];
                            });
                }
                
              };
              call();
              return (function () {
                        isMount.contents = false;
                        controller.abort();
                      });
            }), [
            requestKind,
            status,
            locale
          ]);
      React.useEffect((function () {
              switch (requestKind) {
                case "Favorites" :
                    var observableId = User_Observable.Favorite.Observable.subscribe(function ($$event) {
                          if (typeof $$event !== "object") {
                            return ;
                          }
                          if ($$event.TAG === "Add") {
                            var teaser = $$event._0;
                            return setLocalZone(function (param) {
                                        var key = param[1];
                                        var previousZone = param[0];
                                        if (previousZone === undefined) {
                                          return [
                                                  previousZone,
                                                  key
                                                ];
                                        }
                                        if (typeof previousZone !== "object") {
                                          return [
                                                  previousZone,
                                                  key
                                                ];
                                        }
                                        if (previousZone.NAME !== "Slider") {
                                          return [
                                                  previousZone,
                                                  key
                                                ];
                                        }
                                        if (typeof zone !== "object") {
                                          return [
                                                  previousZone,
                                                  key
                                                ];
                                        }
                                        if (zone.NAME !== "Slider") {
                                          return [
                                                  previousZone,
                                                  key
                                                ];
                                        }
                                        var sliderZone = previousZone.VAL;
                                        var match = sliderZone.itemTemplate;
                                        var match$1 = sliderZone.data;
                                        var datas;
                                        datas = match === "LandscapeGenre" ? (
                                            match$1.length !== 1 ? [teaser].concat(sliderZone.data) : [teaser]
                                          ) : [teaser].concat(sliderZone.data);
                                        var newrecord = Caml_obj.obj_dup(sliderZone);
                                        var newZone = {
                                          NAME: "Slider",
                                          VAL: (newrecord.data = datas, newrecord.itemTemplate = zone.VAL.itemTemplate, newrecord)
                                        };
                                        return [
                                                newZone,
                                                key + 1 | 0
                                              ];
                                      });
                          }
                          var dsId = $$event._0;
                          setLocalZone(function (param) {
                                var key = param[1];
                                var previousZone = param[0];
                                if (previousZone === undefined) {
                                  return [
                                          previousZone,
                                          key
                                        ];
                                }
                                if (typeof previousZone !== "object") {
                                  return [
                                          previousZone,
                                          key
                                        ];
                                }
                                if (previousZone.NAME !== "Slider") {
                                  return [
                                          previousZone,
                                          key
                                        ];
                                }
                                var sliderZone = previousZone.VAL;
                                var datas = sliderZone.data.filter(function (teaser) {
                                      return teaser.dsId !== dsId;
                                    });
                                var newZone;
                                if (datas.length !== 0) {
                                  if (datas.length < 5) {
                                    var newrecord = Caml_obj.obj_dup(sliderZone);
                                    newZone = {
                                      NAME: "Slider",
                                      VAL: (newrecord.data = datas, newrecord.link = undefined, newrecord)
                                    };
                                  } else {
                                    var newrecord$1 = Caml_obj.obj_dup(sliderZone);
                                    newZone = {
                                      NAME: "Slider",
                                      VAL: (newrecord$1.data = datas, newrecord$1)
                                    };
                                  }
                                } else {
                                  var newrecord$2 = Caml_obj.obj_dup(sliderZone);
                                  newZone = {
                                    NAME: "Slider",
                                    VAL: (newrecord$2.data = [Type_Club.Teaser.emptyConnectedZoneTeaser(mona.myVideos.emptyFavoriteTeaserTitle)], newrecord$2.itemTemplate = "LandscapeGenre", newrecord$2)
                                  };
                                }
                                return [
                                        newZone,
                                        key + 1 | 0
                                      ];
                              });
                        });
                    return (function () {
                              User_Observable.Favorite.Observable.unsubscribe(observableId);
                            });
                case "LastVieweds" :
                case "PersonalZone" :
                    break;
                
              }
              var observableId$1 = User_Observable.Progression.Observable.subscribe(function ($$event) {
                    if (typeof $$event !== "object") {
                      return ;
                    }
                    var dsId = $$event._0;
                    setLocalZone(function (param) {
                          var key = param[1];
                          var previousZone = param[0];
                          if (previousZone === undefined) {
                            return [
                                    previousZone,
                                    key
                                  ];
                          }
                          if (typeof previousZone !== "object") {
                            return [
                                    previousZone,
                                    key
                                  ];
                          }
                          if (previousZone.NAME !== "Slider") {
                            return [
                                    previousZone,
                                    key
                                  ];
                          }
                          var sliderZone = previousZone.VAL;
                          var datas = sliderZone.data.filter(function (teaser) {
                                return teaser.dsId !== dsId;
                              });
                          var newZone;
                          if (datas.length !== 0) {
                            if (datas.length < 5) {
                              var newrecord = Caml_obj.obj_dup(sliderZone);
                              newZone = {
                                NAME: "Slider",
                                VAL: (newrecord.data = datas, newrecord.link = undefined, newrecord)
                              };
                            } else {
                              var newrecord$1 = Caml_obj.obj_dup(sliderZone);
                              newZone = {
                                NAME: "Slider",
                                VAL: (newrecord$1.data = datas, newrecord$1)
                              };
                            }
                          } else {
                            var newrecord$2 = Caml_obj.obj_dup(sliderZone);
                            newZone = {
                              NAME: "Slider",
                              VAL: (newrecord$2.data = [Type_Club.Teaser.emptyConnectedZoneTeaser(mona.myVideos.emptyHistoryTeaserTitle)], newrecord$2.itemTemplate = "LandscapeGenre", newrecord$2)
                            };
                          }
                          return [
                                  newZone,
                                  key + 1 | 0
                                ];
                        });
                  });
              return (function () {
                        User_Observable.Progression.Observable.unsubscribe(observableId$1);
                      });
            }), [requestKind]);
      return JsxRuntime.jsx(Atom_Cell.make, {
                  width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                  children: localZone !== undefined ? JsxRuntime.jsx(make$9, {
                          blockSpace: props.blockSpace,
                          zone: localZone
                        }, match$1[1].toString()) : null
                });
    });

setDisplayName(make$10, "Connected");

var ConnectedZone = {
  EmptyConnectedTeaser: EmptyConnectedTeaser,
  make: make$10
};

var make$11 = React.memo(function (props) {
      var restriction = props.restriction;
      var userStatus = User_Provider.Hook.useUserStatus();
      var componentDisplayStatus = React.useMemo((function () {
              switch (restriction) {
                case "Anonymous" :
                    switch (userStatus) {
                      case "Anonymous" :
                      case "Connected" :
                          return "Displayed";
                      case "Disconnected" :
                          return "Hidden";
                      
                    }
                case "Connected" :
                    switch (userStatus) {
                      case "Connected" :
                          return "Displayed";
                      case "Anonymous" :
                      case "Disconnected" :
                          return "Hidden";
                      
                    }
                case "Disconnected" :
                    return "Displayed";
                
              }
            }), [
            userStatus,
            restriction
          ]);
      var tmp;
      tmp = componentDisplayStatus === "Displayed" ? JsxRuntime.jsx(make$9, {
              blockSpace: props.blockSpace,
              zone: props.zone
            }) : null;
      return JsxRuntime.jsx(Atom_Cell.make, {
                  width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                  children: tmp
                });
    });

var RestrictedZone = {
  make: make$11
};

var make$12 = React.memo(function (props) {
      var component = props.component;
      var method = component.method;
      var blockSpace = component.blockSpace;
      var zone = component.template;
      if (typeof method !== "object") {
        return JsxRuntime.jsx(make$9, {
                    blockSpace: blockSpace,
                    zone: zone
                  });
      } else if (method.TAG === "Connected") {
        return JsxRuntime.jsx(make$10, {
                    zone: zone,
                    contentId: method._0,
                    displayEmpty: method._1,
                    blockSpace: blockSpace
                  });
      } else {
        return JsxRuntime.jsx(make$11, {
                    zone: zone,
                    restriction: method._0,
                    blockSpace: blockSpace
                  });
      }
    });

make$12.displayName = "Zone";

var Zone$1 = {
  Zone: Zone,
  ConnectedZone: ConnectedZone,
  RestrictedZone: RestrictedZone,
  make: make$12
};

var footerHeight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [
      CssJs.height(CssJs.vh(75)),
      CssJs.width(CssJs.pct(100))
    ]);

var make$13 = React.memo(function (props) {
      var pageKind = props.pageKind;
      var page = props.page;
      var zones = React.useMemo((function () {
              return Core__Array.filterMap(page.zones, (function (zone) {
                            switch (zone.TAG) {
                              case "Main" :
                                  return zone._0;
                              case "Program" :
                              case "Guide" :
                                  return ;
                              
                            }
                          }));
            }), [page.zones]);
      var triggerNextZonesRef = React.useRef(function () {
            
          });
      var match = UsePagination.use(UsePagination.makeConfig(undefined, Zone_Layout_Constant.chunkSize, {
                TAG: "NumberElement",
                _0: Core__Option.getOr(props.numberZone, Zone_Layout_Constant.defaultZonesNumber)
              }, undefined), zones);
      var chunks = match.data;
      var paginationStatus = match.status;
      triggerNextZonesRef.current = match.loadMore;
      var callback = React.useMemo((function () {
              return UseVisible.Callback.make(Caml_option.some(UseVisible.Callback.makeCallback("Always", triggerNextZonesRef.current)), Caml_option.some(UseVisible.Callback.makeCallback("Always", triggerNextZonesRef.current)), Caml_option.some(UseVisible.Callback.makeCallback("Always", triggerNextZonesRef.current)), undefined, undefined);
            }), []);
      var match$1 = UseVisible.make(undefined, Caml_option.some(callback), undefined);
      var chunks$1 = React.useMemo((function () {
              return chunks.map(function (zone) {
                          return JsxRuntime.jsx(make$12, {
                                      component: zone
                                    }, zone.key);
                        });
            }), [chunks]);
      var minHeight;
      var exit = 0;
      if (typeof pageKind !== "object") {
        if (pageKind === "Event") {
          exit = 1;
        } else {
          minHeight = undefined;
        }
      } else if (pageKind.TAG === "Rc") {
        exit = 1;
      } else {
        minHeight = undefined;
      }
      if (exit === 1) {
        minHeight = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, CssJs.vw(Molecule_ContentCollection_Css.$$Image.widthBreakpointM * 9 / 16)));
      }
      var title = page.title;
      var tmp;
      var exit$1 = 0;
      if (typeof pageKind !== "object") {
        switch (pageKind) {
          case "Concert" :
          case "Home" :
              exit$1 = 1;
              break;
          default:
            tmp = null;
        }
      } else {
        tmp = null;
      }
      if (exit$1 === 1) {
        tmp = JsxRuntime.jsx(Atom_Text.ScreenreadersLabel.make, {
              children: title,
              kind: "h1"
            });
      }
      var tmp$1;
      if (paginationStatus === "Completed") {
        tmp$1 = null;
      } else {
        var tmp$2;
        var tmp$3 = match$1.status;
        tmp$2 = typeof tmp$3 !== "object" ? (
            tmp$3 === "FullVisible" ? "FullVisible" : "NotVisible"
          ) : (
            tmp$3.TAG === "Entering" ? "Entering" : "Leaving"
          );
        var tmp$4;
        tmp$4 = paginationStatus === "FetchingData" ? JsxRuntime.jsx(Atom_Loader.make, {}) : null;
        tmp$1 = JsxRuntime.jsxs(Atom_Cell.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              children: [
                JsxRuntime.jsx(Atom_Cell.make, {
                      display: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "none")),
                      children: tmp$2
                    }),
                tmp$4
              ],
              identifier: Caml_option.some(Identifiers.Zone_Layout.triggerNextZoneContainer),
              rules: Caml_option.some(footerHeight),
              cbRef: match$1.cbRef
            });
      }
      return JsxRuntime.jsxs(Atom_Column.make, {
                  alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                  blockSpace: Caml_option.some(Layout_Constants.blockSpace),
                  children: [
                    tmp,
                    chunks$1,
                    tmp$1
                  ],
                  kind: "Main",
                  maxWidth: Caml_option.some(Layout_Constants.maxWidthMainContentScreen),
                  minHeight: minHeight,
                  width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                });
    });

make$13.displayName = "Zone_Layout";

var defaultZonesNumber = Zone_Layout_Constant.defaultZonesNumber;

var chunkSize = Zone_Layout_Constant.chunkSize;

export {
  setDisplayName ,
  Banner ,
  ContentCollection ,
  EventZone ,
  PaginatedGrid ,
  Slider ,
  Newsletter ,
  TopTeaser ,
  TopTeaserWithCarousel ,
  EbuBox ,
  Zone$1 as Zone,
  footerHeight ,
  defaultZonesNumber ,
  chunkSize ,
  make$13 as make,
}
/* make Not a pure module */
