// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Particle_Screen_Ds from "./Particle_Screen_Ds.res.mjs";

function makeAnimation(x) {
  if (typeof x !== "object") {
    switch (x) {
      case "Nothing" :
          return {
                  keyframes: "Nothing",
                  delay: 0,
                  duration: 700,
                  timingFunction: "EaseInOut",
                  iterationCount: "Infinite",
                  fillMode: "None",
                  direction: "Normal"
                };
      case "FadeIn" :
          return {
                  keyframes: "OpacityIn",
                  delay: 0,
                  duration: 1000,
                  timingFunction: "EaseInOut",
                  iterationCount: "Unique",
                  fillMode: "None",
                  direction: "Normal"
                };
      case "FadeOut" :
          return {
                  keyframes: "OpacityOut",
                  delay: 0,
                  duration: 1000,
                  timingFunction: "EaseInOut",
                  iterationCount: "Unique",
                  fillMode: "Forwards",
                  direction: "Normal"
                };
      case "RightToLeft" :
          return {
                  keyframes: "TranslationLeft",
                  delay: 0,
                  duration: 250,
                  timingFunction: "CubicBezier",
                  iterationCount: "Unique",
                  fillMode: "None",
                  direction: "Normal"
                };
      case "RevealText" :
          return {
                  keyframes: "RevealText",
                  delay: 0,
                  duration: 550,
                  timingFunction: "Linear",
                  iterationCount: "Unique",
                  fillMode: "Forwards",
                  direction: "Normal"
                };
      case "LeftToRight" :
          return {
                  keyframes: "TranslationRight",
                  delay: 0,
                  duration: 1000,
                  timingFunction: "EaseOut",
                  iterationCount: "Unique",
                  fillMode: "None",
                  direction: "Normal"
                };
      case "Pulse" :
          return {
                  keyframes: "Pulse",
                  delay: 0,
                  duration: 1000,
                  timingFunction: "EaseInOut",
                  iterationCount: {
                    TAG: "Count",
                    _0: 6
                  },
                  fillMode: "None",
                  direction: "Normal"
                };
      
    }
  } else {
    switch (x.TAG) {
      case "Open" :
          return {
                  keyframes: {
                    TAG: "Open",
                    _0: x._0
                  },
                  delay: 0,
                  duration: 600,
                  timingFunction: "EaseInOut",
                  iterationCount: "Unique",
                  fillMode: "Forwards",
                  direction: "Normal"
                };
      case "FadeInCustomDuration" :
          return {
                  keyframes: "OpacityIn",
                  delay: 0,
                  duration: x._0,
                  timingFunction: "EaseInOut",
                  iterationCount: "Unique",
                  fillMode: "None",
                  direction: "Normal"
                };
      case "Spinner" :
          return {
                  keyframes: {
                    TAG: "Rotating",
                    _0: x._1
                  },
                  delay: 0,
                  duration: 700,
                  timingFunction: x._0,
                  iterationCount: "Infinite",
                  fillMode: "None",
                  direction: x._2
                };
      
    }
  }
}

var fadeInAnimation = makeAnimation("FadeIn");

var fadeOutAnimation = makeAnimation("FadeOut");

var rightToLeftAnimation = makeAnimation("RightToLeft");

var leftToRightAnimation = makeAnimation("LeftToRight");

var nothingAnimation = makeAnimation("Nothing");

var pulseAnimation = makeAnimation("Pulse");

var fadeIn = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, fadeInAnimation);

var rightToLeft = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, rightToLeftAnimation);

var leftToRight = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, leftToRightAnimation);

var image = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, fadeOutAnimation);

var TopTeaser = {
  element: rightToLeft,
  image: image
};

var TopTeaserPresse = {
  background: fadeIn
};

function delay(duration) {
  return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
              keyframes: "DecreaseWidth",
              delay: 0,
              duration: duration,
              timingFunction: "Linear",
              iterationCount: "Unique",
              fillMode: "None",
              direction: "Normal"
            });
}

var Message = {
  container: fadeIn,
  content: leftToRight,
  delay: delay
};

var content = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, makeAnimation({
          TAG: "FadeInCustomDuration",
          _0: 500
        }));

var SubHeader = {
  content: content
};

var titleHover = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, makeAnimation("RevealText"));

var TeaserGenre = {
  titleHover: titleHover
};

var openAnimation = makeAnimation({
      TAG: "Open",
      _0: 400
    });

var background = Particle_Screen_Ds.make(undefined, undefined, fadeOutAnimation, undefined, undefined, nothingAnimation);

var element = Particle_Screen_Ds.make(undefined, undefined, fadeInAnimation, undefined, undefined, openAnimation);

var On = {
  background: background,
  element: element
};

var initial = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, nothingAnimation);

var openAnimation$1 = makeAnimation({
      TAG: "Open",
      _0: 400
    });

var element$1 = Particle_Screen_Ds.make(undefined, undefined, fadeInAnimation, undefined, undefined, openAnimation$1);

var image$1 = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, fadeOutAnimation);

var On$1 = {
  element: element$1,
  image: image$1
};

var main = (function (__x) {
      return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, __x);
    })(makeAnimation({
          TAG: "Spinner",
          _0: "Linear",
          _1: {
            start: 0,
            end: 360
          },
          _2: "Normal"
        }));

var firstFragment = (function (__x) {
      return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, __x);
    })(makeAnimation({
          TAG: "Spinner",
          _0: {
            TAG: "CubicBezierCustom",
            _0: 0.25,
            _1: 0.1,
            _2: 0.5,
            _3: 1
          },
          _1: {
            start: 60,
            end: 205
          },
          _2: "Alternate"
        }));

var secondFragment = (function (__x) {
      return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, __x);
    })(makeAnimation({
          TAG: "Spinner",
          _0: "Linear",
          _1: {
            start: 30,
            end: -115
          },
          _2: "Alternate"
        }));

var liveDot = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, pulseAnimation);

var Sticker = {
  liveDot: liveDot
};

function view(t) {
  return Particle_Screen_Ds.map(t, (function (param) {
                return {
                        keyframes: param.keyframes,
                        delay: param.delay,
                        duration: param.duration,
                        timingFunction: param.timingFunction,
                        iterationCount: param.iterationCount,
                        fillMode: param.fillMode,
                        direction: param.direction
                      };
              }));
}

var Trailer = {
  On: On,
  initial: initial
};

var Clip = {
  On: On$1
};

var Loader = {
  main: main,
  firstFragment: firstFragment,
  secondFragment: secondFragment
};

export {
  Message ,
  SubHeader ,
  TeaserGenre ,
  TopTeaser ,
  TopTeaserPresse ,
  Trailer ,
  Clip ,
  Loader ,
  Sticker ,
  view ,
}
/* fadeInAnimation Not a pure module */
