// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Box from "../atom/Atom_Box.res.mjs";
import * as Atom_Row from "../atom/Atom_Row.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Type_User_Navigation from "../../type/Type_User_Navigation.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as Organism_User_Navigation from "./Organism_User_Navigation.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("L", "XL"), undefined, undefined, Particle_Spacer_Ds.makeNone());

var height = Particle_Screen_Ds.make(undefined, undefined, CssJs.px(100), undefined, undefined, CssJs.auto);

var navWidth = Particle_Screen_Ds.make(undefined, undefined, CssJs.pct(100), undefined, undefined, "auto");

var navBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeAll("Zero"), undefined, undefined, Particle_Spacer_Ds.make("S", "S", "M", "Zero"));

var Organism_Account_Header_Css = {
  blockSpace: blockSpace,
  height: height,
  navWidth: navWidth,
  navBlockSpace: navBlockSpace
};

var navAriaLabel = "menu-club";

function make(param) {
  var match = Context.I18n.getState();
  var items = Type_User_Navigation.Header.makeItems(match.labels, param.currentKey, match.locale);
  return JsxRuntime.jsx(Atom_Row.make, {
              background: Caml_option.some(Particle_Background_Ds.headerUser),
              blockSpace: Caml_option.some(blockSpace),
              children: JsxRuntime.jsx(Atom_Box.make, {
                    children: Caml_option.some(JsxRuntime.jsx(Organism_User_Navigation.make, {
                              items: items,
                              variant: Organism_User_Navigation.header
                            })),
                    justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentSpaceBetween),
                    kind: {
                      TAG: "Nav",
                      _0: navAriaLabel
                    },
                    spacer: Caml_option.some(navBlockSpace),
                    width: Caml_option.some(navWidth)
                  }),
              height: Caml_option.some(height),
              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentSpaceBetween)
            });
}

export {
  Organism_Account_Header_Css ,
  navAriaLabel ,
  make ,
}
/* blockSpace Not a pure module */
