// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.res.mjs";
import * as CssJs from "../../../CssJs.res.mjs";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Logo from "../../atom/Atom_Logo.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Atom_Image from "../../atom/Atom_Image.res.mjs";
import * as Atom_Video from "../../atom/Atom_Video.res.mjs";
import * as Type_Image from "../../../type/Type_Image.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Zone_Layout from "../../../zone/Zone_Layout.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Molecule_Sticker from "../../molecule/Molecule_Sticker.res.mjs";
import * as Organism_Oops_Css from "./Organism_Oops_Css.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Molecule_TopTeaser_Css from "../../molecule/Molecule_TopTeaser_Css.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var videoPosition = Particle_Screen_Ds.make(undefined, "Relative", undefined, undefined, undefined, {
      TAG: "Fixed",
      _0: undefined
    });

function Organism_Error404(props) {
  return JsxRuntime.jsxs(Atom_Column.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              children: [
                JsxRuntime.jsx(Atom_Cell.make, {
                      display: Caml_option.some(Organism_Oops_Css.Error404.displayVideo),
                      width: Caml_option.some(Organism_Oops_Css.Error404.width("Video")),
                      children: JsxRuntime.jsx(Atom_Video.make, {
                            style: "Error404"
                          }),
                      position: Caml_option.some(videoPosition),
                      rules: Caml_option.some(Organism_Oops_Css.Error404.Rules.video)
                    }),
                JsxRuntime.jsxs(Atom_Row.make, {
                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                      blockSpace: Caml_option.some(Organism_Oops_Css.Error404.blockspace("Content")),
                      children: [
                        JsxRuntime.jsx(Atom_Cell.make, {
                              autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
                              children: JsxRuntime.jsx(Atom_Logo.make, {
                                    logo: {
                                      NAME: "ArteVerticalLogo",
                                      VAL: "ErrorPage"
                                    }
                                  })
                            }),
                        JsxRuntime.jsxs(Atom_Column.make, {
                              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexStart),
                              children: [
                                JsxRuntime.jsx(Atom_Text.make, {
                                      children: Caml_option.some(props.title),
                                      style: Atom_Text_Ds.Oops._404Title
                                    }),
                                JsxRuntime.jsx(Atom_Text.make, {
                                      children: Caml_option.some(props.subTitle),
                                      style: Atom_Text_Ds.Oops._404Subtitle
                                    }),
                                JsxRuntime.jsx(Atom_Column.make, {
                                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                                      blockSpace: Caml_option.some(Organism_Oops_Css.Error404.blockspace("Cta")),
                                      children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                                            href: props.ctaHref,
                                            label: props.cta,
                                            style: Atom_Cta_Ds.backHome404
                                          }),
                                      width: Caml_option.some(Organism_Oops_Css.Error404.width("Cta"))
                                    })
                              ],
                              innerSpace: Caml_option.some(Particle_Screen_Constants.xsInnerSpace)
                            })
                      ],
                      position: Caml_option.some(Particle_Screen_Constants.positionAbsolute),
                      wrap: "OnOneLine"
                    })
              ],
              height: Caml_option.some(Organism_Oops_Css.Error404.height),
              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
              rules: Caml_option.some(Organism_Oops_Css.Error404.Rules.wrapper)
            });
}

function Organism_Error404$Haiku$ResponsiveImageWithGradient(props) {
  var src = props.src;
  var src$1 = Type_Image.Src.make(Core__Option.getOr(Type_Image.Mami.make(src), src));
  return JsxRuntime.jsx(Atom_Row.make, {
              children: JsxRuntime.jsx(Atom_Cell.make, {
                    width: Caml_option.some(Molecule_TopTeaser_Css.$$Image.width),
                    children: JsxRuntime.jsx(Atom_Image.make, {
                          alt: Core__Option.getOr(props.alt, ""),
                          src: src$1,
                          style: "NotFoundPage"
                        }),
                    rules: Caml_option.some(Molecule_TopTeaser_Css.$$Image.rulesAspectRatio)
                  }),
              justifyContent: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, "flexEnd", undefined, undefined, "normal")),
              maxHeight: Caml_option.some(Particle_Screen_Constants.oneHundredPctMaxHeight),
              position: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, {
                        TAG: "Absolute",
                        _0: undefined
                      }, undefined, undefined, "Relative")),
              width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
            });
}

var ResponsiveImageWithGradient = {
  make: Organism_Error404$Haiku$ResponsiveImageWithGradient
};

var layoutMinHeight = Particle_Screen_Ds.make(undefined, undefined, undefined, CssJs.auto, undefined, Css.vh(40));

var contentRules = Particle_Screen_Ds.make(undefined, undefined, [
      CssJs.zIndex(1),
      CssJs.paddingBottom({
            NAME: "rem",
            VAL: 4
          }),
      CssJs.paddingTop({
            NAME: "rem",
            VAL: 6
          })
    ], undefined, undefined, [CssJs.zIndex(1)]);

var logoRules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.display("flex")], undefined, undefined, [CssJs.display("none")]);

var catchBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("L"), undefined, Particle_Spacer_Ds.makeTop("M"));

var justifyContent = Particle_Screen_Ds.make(undefined, undefined, undefined, "flexStart", undefined, "center");

var maxWidth = Particle_Screen_Ds.make(undefined, undefined, CssJs.pct(50), undefined, undefined, CssJs.pct(100));

var logoBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeRight("XXS"));

var titleInnerspace = Particle_Screen_Ds.make(undefined, undefined, "Zero", "XS", undefined, "XS");

var sliderBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeTop("Zero"), undefined, undefined, Particle_Spacer_Ds.makeTop("XL"));

function Organism_Error404$Haiku(props) {
  var sliderZone = props.sliderZone;
  var programTitle = props.programTitle;
  var match = Context.I18n.getState();
  var match$1 = match.labels.error;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsx(Organism_Error404$Haiku$ResponsiveImageWithGradient, {
                              src: props.backgoundImageSrc,
                              alt: programTitle
                            }),
                        JsxRuntime.jsx(Molecule_Sticker.Haiku.make, {
                              text: match$1.iaSticker,
                              position: "TopRight"
                            }),
                        JsxRuntime.jsxs(Atom_Cell.make, {
                              justifyContent: Caml_option.some(justifyContent),
                              maxWidth: Caml_option.some(maxWidth),
                              children: [
                                JsxRuntime.jsx(Atom_Cell.make, {
                                      autoSize: Caml_option.some(Particle_Screen_Constants.autoSizeAllPlaceAvailable),
                                      blockSpace: Caml_option.some(logoBlockSpace),
                                      children: JsxRuntime.jsx(Atom_Logo.make, {
                                            logo: {
                                              NAME: "ArteVerticalLogo",
                                              VAL: "Haiku"
                                            }
                                          }),
                                      rules: Caml_option.some(logoRules)
                                    }),
                                JsxRuntime.jsxs(Atom_Column.make, {
                                      children: [
                                        JsxRuntime.jsx(Atom_Text.make, {
                                              children: Caml_option.some(programTitle),
                                              kind: "h1",
                                              style: Atom_Text_Ds.Oops._404HaikuTitle
                                            }),
                                        JsxRuntime.jsx(Atom_Text.make, {
                                              children: Caml_option.some(match$1.subtitle404),
                                              kind: "h2",
                                              style: Atom_Text_Ds.Oops._404HaikuSubtitle
                                            }),
                                        JsxRuntime.jsx(Atom_Column.make, {
                                              blockSpace: Caml_option.some(catchBlockSpace),
                                              children: JsxRuntime.jsx(Atom_Text.DangerouslySetInnerHTML.make, {
                                                    style: Atom_Text_Ds.Oops._404Haiku,
                                                    dangerouslySetInnerHTML: props.contentHtml
                                                  })
                                            })
                                      ],
                                      innerSpace: Caml_option.some(titleInnerspace)
                                    })
                              ],
                              position: Caml_option.some(Particle_Screen_Constants.positionRelative),
                              rules: Caml_option.some(contentRules)
                            })
                      ],
                      minHeight: Caml_option.some(layoutMinHeight),
                      position: Caml_option.some(Particle_Screen_Constants.positionRelative),
                      width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                    }),
                sliderZone !== undefined && typeof sliderZone === "object" && sliderZone.NAME === "Slider" ? JsxRuntime.jsx(Atom_Cell.make, {
                        maxWidth: Caml_option.some(Particle_Screen_Constants.oneHundredPctMaxWidth),
                        children: JsxRuntime.jsx(Zone_Layout.Slider.make, {
                              blockSpace: sliderBlockSpace,
                              zone: sliderZone.VAL
                            }),
                        position: Caml_option.some(Particle_Screen_Constants.positionRelative)
                      }) : null
              ]
            });
}

var Haiku = {
  ResponsiveImageWithGradient: ResponsiveImageWithGradient,
  layoutMinHeight: layoutMinHeight,
  contentRules: contentRules,
  logoRules: logoRules,
  catchBlockSpace: catchBlockSpace,
  justifyContent: justifyContent,
  maxWidth: maxWidth,
  logoBlockSpace: logoBlockSpace,
  titleInnerspace: titleInnerspace,
  sliderBlockSpace: sliderBlockSpace,
  make: Organism_Error404$Haiku
};

var make = Organism_Error404;

export {
  videoPosition ,
  make ,
  Haiku ,
}
/* videoPosition Not a pure module */
