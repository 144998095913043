// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Box from "../../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as UseOnBlur from "../../../hook/UseOnBlur.res.mjs";
import * as UseToggle from "../../../hook/UseToggle.res.mjs";
import * as User_Type from "../../../user/User_Type.res.mjs";
import * as Atom_Column from "../../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_Avatar from "../../../type/Type_Avatar.res.mjs";
import * as Type_Header from "../../../type/Type_Header.res.mjs";
import * as Atom_Divider from "../../atom/Atom_Divider.res.mjs";
import * as Atom_Icon_Ds from "../../atom/Atom_Icon_Ds.res.mjs";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as User_Provider from "../../../user/User_Provider.res.mjs";
import * as Molecule_Avatar from "../../molecule/Molecule_Avatar.res.mjs";
import * as Particle_zIndex from "../../particle/Particle_zIndex.res.mjs";
import * as UseClickOutside from "../../../hook/UseClickOutside.res.mjs";
import * as User_Observable from "../../../user/User_Observable.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Accessibility from "../../particle/Particle_Accessibility.res.mjs";
import * as Particle_Background_Ds from "../../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var zIndexRule = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [Particle_zIndex.Particle_zIndex_Css.toCss({
            TAG: "Exact",
            _0: "Header"
          })]);

var containerWidth = Particle_Screen_Ds.make(undefined, undefined, {
      NAME: "px",
      VAL: 393
    }, undefined, undefined, {
      NAME: "percent",
      VAL: 100
    });

var containerBlockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "XS"), undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("XS", "S"));

var myAccountBlockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.make("XS", "XS", "S", "None"));

var position = Particle_Screen_Ds.make(undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: {
          NAME: "px",
          VAL: Type_Header.Constants.height.m
        },
        right: {
          NAME: "px",
          VAL: 0
        },
        left: "auto"
      }
    }, undefined, undefined, {
      TAG: "Fixed",
      _0: {
        top: {
          NAME: "px",
          VAL: Type_Header.Constants.height.default
        },
        right: {
          NAME: "px",
          VAL: 0
        },
        left: {
          NAME: "px",
          VAL: 0
        }
      }
    });

var myAdvantageWidth = Particle_Screen_Ds.make(undefined, undefined, {
      NAME: "px",
      VAL: 358
    }, undefined, undefined, {
      NAME: "percent",
      VAL: 100
    });

var myAdvantageAlignItems = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center");

var myAdvantageInnerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

var myAdvantageRules = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.pointerEvents("none")]);

var myVideoRules = Particle_Screen_Ds.make(undefined, undefined, undefined, [CssJs.display("none")], undefined, [CssJs.display("inlineFlex")]);

function Organism_Header_Panel$Item_Tooltip(props) {
  var editProfile = props.editProfile;
  var myAdvantages = props.myAdvantages;
  var help = props.help;
  var userStatus = props.userStatus;
  var match = Context.Config.getState();
  var signinUrl = match.signinUrl;
  var match$1 = Context.I18n.getState();
  var labels = match$1.labels;
  var match$2 = labels.header;
  var settings = match$2.settings;
  var myVideosLink = match$2.myVideosLink;
  var userInfos = User_Observable.UserInfo.All.Hook.use();
  var userAvatar = Core__Option.getOr(Core__Option.flatMap(userInfos, User_Type.Info.avatar), Type_Avatar.defaultAvatar);
  var myAdvantagesButton;
  var exit = 0;
  switch (userStatus) {
    case "Connected" :
        myAdvantagesButton = JsxRuntime.jsx(Atom_Cta.Link.make, {
              href: myAdvantages.href,
              label: myAdvantages.title,
              style: Atom_Cta_Ds.Header.makeHeaderPanel({
                    TAG: "MyAdvantages",
                    _0: "Unlocked"
                  })
            });
        break;
    case "Anonymous" :
    case "Disconnected" :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    myAdvantagesButton = JsxRuntime.jsx(Atom_Cell.make, {
          children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, -1),
                href: myAdvantages.href,
                label: myAdvantages.title,
                style: Atom_Cta_Ds.Header.makeHeaderPanel({
                      TAG: "MyAdvantages",
                      _0: "Locked"
                    })
              }),
          rules: Caml_option.some(myAdvantageRules)
        });
  }
  var myVideosButton;
  var exit$1 = 0;
  switch (userStatus) {
    case "Anonymous" :
    case "Connected" :
        exit$1 = 1;
        break;
    case "Disconnected" :
        myVideosButton = null;
        break;
    
  }
  if (exit$1 === 1) {
    myVideosButton = JsxRuntime.jsx(Atom_Cell.make, {
          children: JsxRuntime.jsx(Atom_Cta.Link.make, {
                href: myVideosLink.href,
                label: myVideosLink.label,
                style: Atom_Cta_Ds.Header.makeHeaderPanel("MyVideo")
              }),
          rules: Caml_option.some(myVideoRules)
        });
  }
  var tmp;
  var exit$2 = 0;
  switch (userStatus) {
    case "Connected" :
        tmp = JsxRuntime.jsxs(Atom_Row.make, {
              alignItems: Caml_option.some(myAdvantageAlignItems),
              children: [
                JsxRuntime.jsx(Molecule_Avatar.make, {
                      avatar: userAvatar,
                      style: "UserPanel"
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(labels.mona.navigation.myAccount),
                              kind: "h2",
                              style: Atom_Text_Ds.Header.userPanelTitle
                            }),
                        JsxRuntime.jsx(Atom_Cta.Link.make, {
                              href: editProfile.href,
                              label: editProfile.title,
                              style: Atom_Cta_Ds.Header.link
                            })
                      ]
                    })
              ],
              innerSpace: Caml_option.some(myAdvantageInnerSpace)
            });
        break;
    case "Anonymous" :
    case "Disconnected" :
        exit$2 = 1;
        break;
    
  }
  if (exit$2 === 1) {
    tmp = JsxRuntime.jsxs(Atom_Column.make, {
          children: [
            JsxRuntime.jsx(Atom_Text.make, {
                  children: Caml_option.some(labels.mona.navigation.myAccount),
                  kind: "h2",
                  style: Atom_Text_Ds.Header.userPanelTitle
                }),
            JsxRuntime.jsxs(Atom_Column.make, {
                  children: [
                    JsxRuntime.jsx(Atom_Text.make, {
                          children: Caml_option.some(labels.mona.navigation.myAccountDescription),
                          style: Atom_Text_Ds.Header.userPanelDescription
                        }),
                    JsxRuntime.jsx(Atom_Cta.Link.make, {
                          href: signinUrl,
                          label: props.registration.title,
                          style: Atom_Cta_Ds.Header.panelRegistration
                        })
                  ],
                  innerSpace: Caml_option.some(myAdvantageInnerSpace)
                })
          ],
          innerSpace: Caml_option.some(myAdvantageInnerSpace)
        });
  }
  var myAccount = JsxRuntime.jsx(Atom_Column.make, {
        blockSpace: Caml_option.some(myAccountBlockSpace),
        children: tmp,
        width: Caml_option.some(myAdvantageWidth)
      });
  if (props.status !== "On") {
    return null;
  }
  var tmp$1;
  var exit$3 = 0;
  switch (userStatus) {
    case "Connected" :
        tmp$1 = JsxRuntime.jsx(Atom_Cta.Button.make, {
              label: labels.mona.navigation.disconnect,
              onClick: User_Provider.disconnect(match.api.onLogoutCallback, undefined, match$1.locale),
              style: Atom_Cta_Ds.Header.makeHeaderPanel("Logout")
            });
        break;
    case "Anonymous" :
    case "Disconnected" :
        exit$3 = 1;
        break;
    
  }
  if (exit$3 === 1) {
    tmp$1 = JsxRuntime.jsx(Atom_Cta.Link.make, {
          href: signinUrl,
          label: props.userLabel,
          style: Atom_Cta_Ds.Header.makeHeaderPanel("Login")
        });
  }
  return JsxRuntime.jsxs(Atom_Column.make, {
              background: Caml_option.some(Particle_Background_Ds.userPanel),
              children: [
                JsxRuntime.jsx(Atom_Column.make, {
                      blockSpace: Caml_option.some(containerBlockSpace),
                      children: myAccount
                    }),
                JsxRuntime.jsx(Atom_Divider.make, {
                      style: Atom_Divider.headerPanel
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(containerBlockSpace),
                      children: [
                        JsxRuntime.jsx(Atom_Cta.Link.make, {
                              href: settings.href,
                              label: settings.title,
                              style: Atom_Cta_Ds.Header.makeHeaderPanel("Setting")
                            }),
                        myAdvantagesButton,
                        myVideosButton,
                        JsxRuntime.jsx(Atom_Cta.Link.make, {
                              href: help.href,
                              label: help.title,
                              style: Atom_Cta_Ds.Header.makeHeaderPanel("Help")
                            })
                      ]
                    }),
                JsxRuntime.jsx(Atom_Divider.make, {
                      style: Atom_Divider.headerPanel
                    }),
                JsxRuntime.jsx(Atom_Column.make, {
                      blockSpace: Caml_option.some(containerBlockSpace),
                      children: tmp$1
                    })
              ],
              position: Caml_option.some(position),
              rules: Caml_option.some(zIndexRule),
              width: Caml_option.some(containerWidth)
            });
}

var Item_Tooltip = {
  zIndexRule: zIndexRule,
  containerWidth: containerWidth,
  containerBlockSpace: containerBlockSpace,
  myAccountBlockSpace: myAccountBlockSpace,
  position: position,
  myAdvantageWidth: myAdvantageWidth,
  myAdvantageAlignItems: myAdvantageAlignItems,
  myAdvantageInnerSpace: myAdvantageInnerSpace,
  myAdvantageRules: myAdvantageRules,
  myVideoRules: myVideoRules,
  make: Organism_Header_Panel$Item_Tooltip
};

function Organism_Header_Panel(props) {
  var userStatus = props.userStatus;
  var ref = React.useRef(null);
  var avatarCtaRef = React.useRef(null);
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.header;
  var club = match$1.club;
  var match$2 = labels.mona;
  var navigation = match$2.navigation;
  var userInfos = User_Observable.UserInfo.All.Hook.use();
  var userAvatar = Core__Option.getOr(Core__Option.flatMap(userInfos, User_Type.Info.avatar), Type_Avatar.defaultAvatar);
  var displayMobileRules;
  displayMobileRules = props.searchState === "On" ? Particle_Screen_Ds.make(undefined, undefined, undefined, [CssJs.display("flex")], undefined, [CssJs.display("none")]) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.display("flex")]);
  var loginButtonId = "login-button";
  var match$3 = UseToggle.make(undefined, undefined, undefined, undefined, undefined, undefined);
  var close = match$3.close;
  var state = match$3.state;
  var toggle = match$3.toggle;
  UseOnBlur.make(ref, close);
  UseClickOutside.make(ref, close);
  var screenReadersLabel;
  switch (userStatus) {
    case "Connected" :
        screenReadersLabel = club.pseudo;
        break;
    case "Anonymous" :
    case "Disconnected" :
        screenReadersLabel = navigation.myAccount;
        break;
    
  }
  var userLabel;
  switch (userStatus) {
    case "Connected" :
        userLabel = club.pseudo;
        break;
    case "Anonymous" :
    case "Disconnected" :
        userLabel = club.login;
        break;
    
  }
  var accessibility = Particle_Accessibility.make(undefined, undefined, undefined, undefined, match$3.stateBoolean, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var userButton;
  var exit = 0;
  switch (userStatus) {
    case "Connected" :
        userButton = JsxRuntime.jsx(Atom_Cta.Button.Block.make, {
              accessibility: accessibility,
              children: JsxRuntime.jsx(Molecule_Avatar.make, {
                    avatar: userAvatar,
                    style: "Icon"
                  }),
              ctaRef: avatarCtaRef,
              id: loginButtonId,
              onClick: toggle,
              screenReadersLabel: screenReadersLabel,
              style: Atom_Cta_Ds.Header.makeAvatar(state, undefined)
            });
        break;
    case "Anonymous" :
    case "Disconnected" :
        exit = 1;
        break;
    
  }
  if (exit === 1) {
    userButton = JsxRuntime.jsx(Atom_Cta.Button.make, {
          accessibility: accessibility,
          ctaRef: avatarCtaRef,
          id: loginButtonId,
          onClick: toggle,
          screenReadersLabel: screenReadersLabel,
          style: Atom_Cta_Ds.Header.makeAvatar(state, {
                TAG: "Icon",
                _0: {
                  position: {
                    TAG: "Left",
                    _0: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "Zero")
                  },
                  icon: Atom_Icon_Ds.user,
                  size: Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S")
                }
              })
        });
  }
  return JsxRuntime.jsx(Atom_Box.make, {
              children: Caml_option.some(JsxRuntime.jsxs(Atom_Row.make, {
                        children: [
                          userButton,
                          JsxRuntime.jsx(Organism_Header_Panel$Item_Tooltip, {
                                status: state,
                                userStatus: userStatus,
                                userLabel: userLabel,
                                help: props.help,
                                myAdvantages: props.myAdvantages,
                                registration: props.registration,
                                editProfile: props.editProfile
                              })
                        ],
                        justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
                        position: Caml_option.some(Particle_Screen_Constants.positionRelative),
                        width: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, {
                                  NAME: "px",
                                  VAL: 56
                                }, undefined, undefined, {
                                  NAME: "px",
                                  VAL: 40
                                }))
                      })),
              rules: Caml_option.some(displayMobileRules),
              ref_: ref
            });
}

var make = Organism_Header_Panel;

export {
  Item_Tooltip ,
  make ,
}
/* zIndexRule Not a pure module */
