'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../../design-system/atom/Atom_Cta.res.mjs";
import * as Atom_Text from "../../../design-system/atom/Atom_Text.res.mjs";
import * as User_Type from "../../../user/User_Type.res.mjs";
import * as Atom_Column from "../../../design-system/atom/Atom_Column.res.mjs";
import * as Atom_Loader from "../../../design-system/atom/Atom_Loader.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Layout_Auth from "../layout/Layout_Auth.res.mjs";
import * as Atom_Text_Ds from "../../../design-system/atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as User_Api__SsoLogin from "../../../user/User_Api/User_Api__SsoLogin.res.mjs";
import * as User_Api__Sso__VerifyEmail from "../../../user/User_Api/User_Api__Sso__VerifyEmail.res.mjs";

function reducer(state, action) {
  return "EmailVerified";
}

function make(baseState) {
  var match = React.useReducer(reducer, Core__Option.getOr(baseState, "Pending"));
  var dispatch = match[1];
  return {
          state: match[0],
          displayEmailVerified: (function () {
              dispatch("DisplayEmailVerified");
            })
        };
}

var UseVerifyStates = {
  reducer: reducer,
  initialState: "Pending",
  make: make
};

function Page_Verify_Email$EmailVerifiedView(props) {
  var match = Context.I18n.getState();
  var match$1 = match.labels.mona.register;
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Atom_Column.make, {
                      children: JsxRuntime.jsx(Atom_Text.make, {
                            children: Caml_option.some(match$1.verifyDescription),
                            style: Atom_Text_Ds.User.text("Auth")
                          }),
                      innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                    }),
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                              label: match$1.verifyMainButton,
                              onClick: props.startAgeVerification,
                              variant: "base"
                            }),
                        JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                              label: match$1.verifySecondaryButton,
                              onClick: props.redirectToProfile,
                              variant: "outline"
                            })
                      ],
                      innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                    }),
                JsxRuntime.jsx(Atom_Column.make, {
                      children: JsxRuntime.jsx(Atom_Text.make, {
                            children: Caml_option.some(match$1.verifyFooter),
                            style: Atom_Text_Ds.User.text("Auth")
                          }),
                      innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                    })
              ],
              innerSpace: Caml_option.some(Layout_Auth.Form_Css.wrapperInnerSpace),
              width: Caml_option.some(Layout_Auth.Form_Css.wrapperWidth)
            });
}

var EmailVerifiedView = {
  make: Page_Verify_Email$EmailVerifiedView
};

async function loginUser(token, locale, signal, isEmailUpdate, onEmailVerified, displayEmailVerified, redirectToLogin) {
  var verifyResponse = await User_Api__Sso__VerifyEmail.verify({
        args: {
          isEmailUpdate: isEmailUpdate,
          verificationToken: token
        },
        signal: signal,
        locale: locale
      });
  if (verifyResponse.TAG === "Ok") {
    var access_token = verifyResponse._0.access_token;
    if (access_token == null) {
      return redirectToLogin();
    }
    var token$1 = User_Type.Token.make(access_token);
    var ssoLoginResponse = await User_Api__SsoLogin.setToken({
          args: {
            token: token$1
          },
          signal: signal,
          locale: locale
        });
    if (ssoLoginResponse.TAG !== "Ok") {
      return redirectToLogin();
    }
    onEmailVerified(access_token);
    return displayEmailVerified();
  }
  return redirectToLogin();
}

function make$1(param) {
  var token = param.token;
  var onEmailVerified = param.onEmailVerified;
  var redirectToLogin = param.redirectToLogin;
  var isEmailUpdate = Core__Option.getOr(param.isEmailUpdate, false);
  var match = Context.I18n.getState();
  var locale = match.locale;
  var labels = match.labels;
  var match$1 = labels.mona.login;
  var match$2 = labels.mona.register;
  var match$3 = labels.mona.register;
  var match$4 = make(param.baseState);
  var displayEmailVerified = match$4.displayEmailVerified;
  var state = match$4.state;
  React.useEffect((function () {
          var controller = new AbortController();
          var signal = controller.signal;
          if (state === "Pending") {
            if (token == null) {
              redirectToLogin();
            } else {
              loginUser(token, locale, signal, isEmailUpdate, onEmailVerified, displayEmailVerified, redirectToLogin);
            }
          }
          
        }), [
        state,
        token
      ]);
  var tmp;
  tmp = state === "Pending" ? match$3.title : match$2.verifySubtitle;
  var tmp$1;
  tmp$1 = state === "Pending" ? JsxRuntime.jsx(Atom_Loader.make, {}) : JsxRuntime.jsx(Page_Verify_Email$EmailVerifiedView, {
          redirectToProfile: param.redirectToProfile,
          startAgeVerification: param.startAgeVerification
        });
  return JsxRuntime.jsx(Layout_Auth.make, {
              title: tmp,
              footerLinkLabel: match$1.footerLinkLabel,
              footerLinkUrl: match$1.footerLinkUrl,
              children: tmp$1
            });
}

export {
  UseVerifyStates ,
  EmailVerifiedView ,
  loginUser ,
  make$1 as make,
}
/* react Not a pure module */
