// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../../CssJs.res.mjs";
import * as Context from "../../../provider/Context.res.mjs";
import * as Atom_Row from "../../atom/Atom_Row.res.mjs";
import * as Atom_Cell from "../../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../../atom/Atom_Text.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Atom_Text_Ds from "../../atom/Atom_Text_Ds.res.mjs";
import * as Layout_Account from "../../layout/Layout_Account.res.mjs";
import * as UsePairingTvCode from "../../../hook/UsePairingTvCode.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../particle/Particle_Screen_Constants.res.mjs";

var rowInnerspace = Particle_Screen_Ds.make(undefined, undefined, "S", undefined, undefined, "XXS");

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("L", "S"), undefined, undefined, Particle_Spacer_Ds.makeVerticalAndHorizontal("S", "XS"));

var cellBlockspace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeAll("Zero"), undefined, undefined, Particle_Spacer_Ds.makeBottom("S"));

var rowRules = Particle_Screen_Ds.make(undefined, undefined, [CssJs.flexWrap("nowrap")], undefined, undefined, [CssJs.flexWrap("wrap")]);

var justifyContent = Particle_Screen_Ds.make(undefined, "center", undefined, "flexStart", undefined, "flexStart");

function make(param) {
  var match = Context.I18n.getState();
  var match$1 = match.labels.mona.tvConnection;
  var code = UsePairingTvCode.make();
  var codeElems = typeof code === "object" ? (
      code.NAME === "Error" ? [] : code.VAL.split("")
    ) : "--------".split("");
  var errorMessage = typeof code === "object" && code.NAME === "Error" ? code.VAL : undefined;
  return JsxRuntime.jsxs(Layout_Account.WithAvatar.make, {
              children: [
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(match$1.title),
                      kind: "h1",
                      style: Atom_Text_Ds.User.title("MyAccount")
                    }),
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(match$1.description),
                      style: Atom_Text_Ds.User.text("MyAccount")
                    }),
                JsxRuntime.jsx(Atom_Row.make, {
                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexStart),
                      blockSpace: Caml_option.some(Particle_Screen_Constants.lTopBlockSpace),
                      children: codeElems.map(function (elem, index) {
                            return JsxRuntime.jsx(Atom_Cell.make, {
                                        blockSpace: Caml_option.some(cellBlockspace),
                                        children: JsxRuntime.jsx(Atom_Text.make, {
                                              blockSpace: Caml_option.some(blockSpace),
                                              children: Caml_option.some(elem),
                                              style: Atom_Text_Ds.User.tvCellFieldNewDesign
                                            })
                                      }, "code" + index.toString());
                          }),
                      innerSpace: Caml_option.some(rowInnerspace),
                      justifyContent: Caml_option.some(justifyContent),
                      rules: Caml_option.some(rowRules)
                    }),
                errorMessage !== undefined ? JsxRuntime.jsx(Atom_Text.make, {
                        children: Caml_option.some(errorMessage),
                        kind: "span",
                        style: Atom_Text_Ds.makeFeedback("Error")
                      }) : null
              ]
            });
}

export {
  rowInnerspace ,
  blockSpace ,
  cellBlockspace ,
  rowRules ,
  justifyContent ,
  make ,
}
/* rowInnerspace Not a pure module */
