// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spice from "@greenlabs/ppx-spice/src/rescript/Spice.res.mjs";
import * as Js_dict from "@rescript/std/lib/es6/js_dict.js";
import * as MyNullable from "../MyNullable.res.mjs";
import * as Type_Image from "../type/Type_Image.res.mjs";
import * as Util_Array from "../util/Util_Array.res.mjs";
import * as Belt_Option from "@rescript/std/lib/es6/belt_Option.js";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.res.mjs";
import * as Type_Common from "../type/Type_Common.res.mjs";
import * as Type_Credit from "../type/Type_Credit.res.mjs";
import * as Type_String from "../type/Type_String.res.mjs";
import * as Type_Teaser from "../type/Type_Teaser.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Type_Sticker from "../type/Type_Sticker.res.mjs";
import * as $$Date from "@artegeie/date";
import * as Type_Progression from "../type/Type_Progression.res.mjs";

function kind_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "code",
                    false,
                    Type_Common.programKind_encode(v.code)
                  ]]));
}

function kind_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Type_Common.programKind_decode(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              code: code._0
            }
          };
  }
  var e = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function mainImage_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "caption",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.caption)
                  ],
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ]
                ]));
}

function mainImage_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var caption = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "caption"), null));
  if (caption.TAG === "Ok") {
    var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
    if (url.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                caption: caption._0,
                url: url._0
              }
            };
    }
    var e = url._0;
    return {
            TAG: "Error",
            _0: {
              path: ".url" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = caption._0;
  return {
          TAG: "Error",
          _0: {
            path: ".caption" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function availability_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    Type_Common.availabilityType_encode(v.type)
                  ],
                  [
                    "start",
                    false,
                    Spice.stringToJson(v.start)
                  ],
                  [
                    "end",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.end)
                  ],
                  [
                    "upcomingDate",
                    false,
                    Spice.stringToJson(v.upcomingDate)
                  ]
                ]));
}

function availability_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type = Type_Common.availabilityType_decode(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type.TAG === "Ok") {
    var start = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
    if (start.TAG === "Ok") {
      var end = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
      if (end.TAG === "Ok") {
        var upcomingDate = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "upcomingDate"), null));
        if (upcomingDate.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    type: type._0,
                    start: start._0,
                    end: end._0,
                    upcomingDate: upcomingDate._0
                  }
                };
        }
        var e = upcomingDate._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".upcomingDate" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = end._0;
      return {
              TAG: "Error",
              _0: {
                path: ".end" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = start._0;
    return {
            TAG: "Error",
            _0: {
              path: ".start" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = type._0;
  return {
          TAG: "Error",
          _0: {
            path: ".type" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function partner_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "title",
                    false,
                    Spice.stringToJson(v.title)
                  ],
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ],
                  [
                    "image",
                    false,
                    MyNullable.t_encode(mainImage_encode)(v.image)
                  ]
                ]));
}

function partner_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var title = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "title"), null));
  if (title.TAG === "Ok") {
    var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
    if (url.TAG === "Ok") {
      var image = MyNullable.t_decode(mainImage_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "image"), null));
      if (image.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  title: title._0,
                  url: url._0,
                  image: image._0
                }
              };
      }
      var e = image._0;
      return {
              TAG: "Error",
              _0: {
                path: ".image" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = url._0;
    return {
            TAG: "Error",
            _0: {
              path: ".url" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = title._0;
  return {
          TAG: "Error",
          _0: {
            path: ".title" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function trailer_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "config",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.config)
                  ]]));
}

function trailer_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var config = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "config"), null));
  if (config.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              config: config._0
            }
          };
  }
  var e = config._0;
  return {
          TAG: "Error",
          _0: {
            path: ".config" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function broadcastDates_encode(v) {
  return Spice.arrayToJson(Spice.stringToJson, v);
}

function broadcastDates_decode(v) {
  return Spice.arrayFromJson(Spice.stringFromJson, v);
}

function genre_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "label",
                    false,
                    Spice.stringToJson(v.label)
                  ],
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ]
                ]));
}

function genre_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var label = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
  if (label.TAG === "Ok") {
    var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
    if (url.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                label: label._0,
                url: url._0
              }
            };
    }
    var e = url._0;
    return {
            TAG: "Error",
            _0: {
              path: ".url" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = label._0;
  return {
          TAG: "Error",
          _0: {
            path: ".label" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function parentCollection_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "kind",
                    false,
                    Type_Common.programKind_encode(v.kind)
                  ]]));
}

function parentCollection_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var kind = Type_Common.programKind_decode(Belt_Option.getWithDefault(Js_dict.get(v, "kind"), null));
  if (kind.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              kind: kind._0
            }
          };
  }
  var e = kind._0;
  return {
          TAG: "Error",
          _0: {
            path: ".kind" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function geoblocking_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "code",
                    false,
                    Type_Common.geoblocking_encode(v.code)
                  ]]));
}

function geoblocking_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Type_Common.geoblocking_decode(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              code: code._0
            }
          };
  }
  var e = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function audioVersion_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "code",
                    false,
                    Spice.stringToJson(v.code)
                  ],
                  [
                    "label",
                    false,
                    Spice.stringToJson(v.label)
                  ]
                ]));
}

function audioVersion_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    var label = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "label"), null));
    if (label.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                code: code._0,
                label: label._0
              }
            };
    }
    var e = label._0;
    return {
            TAG: "Error",
            _0: {
              path: ".label" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function chapter_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "title",
                    false,
                    Spice.stringToJson(v.title)
                  ],
                  [
                    "timecode",
                    false,
                    Spice.intToJson(v.timecode)
                  ]
                ]));
}

function chapter_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var title = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "title"), null));
  if (title.TAG === "Ok") {
    var timecode = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "timecode"), null));
    if (timecode.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                title: title._0,
                timecode: timecode._0
              }
            };
    }
    var e = timecode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".timecode" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = title._0;
  return {
          TAG: "Error",
          _0: {
            path: ".title" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function audioVersions_encode(v) {
  return Spice.arrayToJson(audioVersion_encode, v);
}

function audioVersions_decode(v) {
  return Spice.arrayFromJson(audioVersion_decode, v);
}

function t_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "audioVersions",
                    false,
                    MyNullable.t_encode(audioVersions_encode)(v.audioVersions)
                  ],
                  [
                    "availability",
                    false,
                    MyNullable.t_encode(availability_encode)(v.availability)
                  ],
                  [
                    "broadcastDates",
                    false,
                    MyNullable.t_encode(broadcastDates_encode)(v.broadcastDates)
                  ],
                  [
                    "callToAction",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.callToAction)
                  ],
                  [
                    "clip",
                    false,
                    MyNullable.t_encode(trailer_encode)(v.clip)
                  ],
                  [
                    "credits",
                    false,
                    MyNullable.t_encode(Type_Credit.credits_encode)(v.credits)
                  ],
                  [
                    "duration",
                    false,
                    MyNullable.t_encode(Type_Common.duration_in_sec_from_js_encode)(v.duration)
                  ],
                  [
                    "durationLabel",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.durationLabel)
                  ],
                  [
                    "firstBroadcastDate",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.firstBroadcastDate)
                  ],
                  [
                    "fullDescription",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.fullDescription)
                  ],
                  [
                    "genre",
                    false,
                    MyNullable.t_encode(genre_encode)(v.genre)
                  ],
                  [
                    "geoblocking",
                    false,
                    MyNullable.t_encode(geoblocking_encode)(v.geoblocking)
                  ],
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "mainImage",
                    false,
                    mainImage_encode(v.mainImage)
                  ],
                  [
                    "kind",
                    false,
                    MyNullable.t_encode(kind_encode)(v.kind)
                  ],
                  [
                    "parentCollections",
                    false,
                    MyNullable.t_encode(function (extra) {
                            return Spice.arrayToJson(parentCollection_encode, extra);
                          })(v.parentCollections)
                  ],
                  [
                    "partners",
                    false,
                    MyNullable.t_encode(function (extra) {
                            return Spice.arrayToJson(partner_encode, extra);
                          })(v.partners)
                  ],
                  [
                    "programId",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.programId)
                  ],
                  [
                    "shopUrl",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.shopUrl)
                  ],
                  [
                    "shortDescription",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.shortDescription)
                  ],
                  [
                    "stickers",
                    false,
                    MyNullable.t_encode(Type_Common.stickers_encode)(v.stickers)
                  ],
                  [
                    "subtitle",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.subtitle)
                  ],
                  [
                    "teaserText",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.teaserText)
                  ],
                  [
                    "title",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.title)
                  ],
                  [
                    "trailer",
                    false,
                    MyNullable.t_encode(trailer_encode)(v.trailer)
                  ],
                  [
                    "trackingPixel",
                    false,
                    MyNullable.t_encode(Spice.stringToJson)(v.trackingPixel)
                  ],
                  [
                    "url",
                    false,
                    Spice.stringToJson(v.url)
                  ],
                  [
                    "chapters",
                    false,
                    MyNullable.t_encode(function (extra) {
                            return Spice.arrayToJson(chapter_encode, extra);
                          })(v.chapters)
                  ],
                  [
                    "ageRating",
                    false,
                    MyNullable.t_encode(Spice.intToJson)(v.ageRating)
                  ]
                ]));
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var audioVersions = MyNullable.t_decode(audioVersions_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "audioVersions"), null));
  if (audioVersions.TAG === "Ok") {
    var availability = MyNullable.t_decode(availability_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "availability"), null));
    if (availability.TAG === "Ok") {
      var broadcastDates = MyNullable.t_decode(broadcastDates_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "broadcastDates"), null));
      if (broadcastDates.TAG === "Ok") {
        var callToAction = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "callToAction"), null));
        if (callToAction.TAG === "Ok") {
          var clip = MyNullable.t_decode(trailer_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "clip"), null));
          if (clip.TAG === "Ok") {
            var credits = MyNullable.t_decode(Type_Credit.credits_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "credits"), null));
            if (credits.TAG === "Ok") {
              var duration = MyNullable.t_decode(Type_Common.duration_in_sec_from_js_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "duration"), null));
              if (duration.TAG === "Ok") {
                var durationLabel = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "durationLabel"), null));
                if (durationLabel.TAG === "Ok") {
                  var firstBroadcastDate = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "firstBroadcastDate"), null));
                  if (firstBroadcastDate.TAG === "Ok") {
                    var fullDescription = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "fullDescription"), null));
                    if (fullDescription.TAG === "Ok") {
                      var genre = MyNullable.t_decode(genre_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "genre"), null));
                      if (genre.TAG === "Ok") {
                        var geoblocking = MyNullable.t_decode(geoblocking_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "geoblocking"), null));
                        if (geoblocking.TAG === "Ok") {
                          var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
                          if (id.TAG === "Ok") {
                            var mainImage = mainImage_decode(Belt_Option.getWithDefault(Js_dict.get(v, "mainImage"), null));
                            if (mainImage.TAG === "Ok") {
                              var kind = MyNullable.t_decode(kind_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "kind"), null));
                              if (kind.TAG === "Ok") {
                                var parentCollections = MyNullable.t_decode(function (extra) {
                                        return Spice.arrayFromJson(parentCollection_decode, extra);
                                      })(Belt_Option.getWithDefault(Js_dict.get(v, "parentCollections"), null));
                                if (parentCollections.TAG === "Ok") {
                                  var partners = MyNullable.t_decode(function (extra) {
                                          return Spice.arrayFromJson(partner_decode, extra);
                                        })(Belt_Option.getWithDefault(Js_dict.get(v, "partners"), null));
                                  if (partners.TAG === "Ok") {
                                    var programId = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "programId"), null));
                                    if (programId.TAG === "Ok") {
                                      var shopUrl = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "shopUrl"), null));
                                      if (shopUrl.TAG === "Ok") {
                                        var shortDescription = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "shortDescription"), null));
                                        if (shortDescription.TAG === "Ok") {
                                          var stickers = MyNullable.t_decode(Type_Common.stickers_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "stickers"), null));
                                          if (stickers.TAG === "Ok") {
                                            var subtitle = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "subtitle"), null));
                                            if (subtitle.TAG === "Ok") {
                                              var teaserText = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "teaserText"), null));
                                              if (teaserText.TAG === "Ok") {
                                                var title = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "title"), null));
                                                if (title.TAG === "Ok") {
                                                  var trailer = MyNullable.t_decode(trailer_decode)(Belt_Option.getWithDefault(Js_dict.get(v, "trailer"), null));
                                                  if (trailer.TAG === "Ok") {
                                                    var trackingPixel = MyNullable.t_decode(Spice.stringFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "trackingPixel"), null));
                                                    if (trackingPixel.TAG === "Ok") {
                                                      var url = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "url"), null));
                                                      if (url.TAG === "Ok") {
                                                        var chapters = MyNullable.t_decode(function (extra) {
                                                                return Spice.arrayFromJson(chapter_decode, extra);
                                                              })(Belt_Option.getWithDefault(Js_dict.get(v, "chapters"), null));
                                                        if (chapters.TAG === "Ok") {
                                                          var ageRating = MyNullable.t_decode(Spice.intFromJson)(Belt_Option.getWithDefault(Js_dict.get(v, "ageRating"), null));
                                                          if (ageRating.TAG === "Ok") {
                                                            return {
                                                                    TAG: "Ok",
                                                                    _0: {
                                                                      audioVersions: audioVersions._0,
                                                                      availability: availability._0,
                                                                      broadcastDates: broadcastDates._0,
                                                                      callToAction: callToAction._0,
                                                                      clip: clip._0,
                                                                      credits: credits._0,
                                                                      duration: duration._0,
                                                                      durationLabel: durationLabel._0,
                                                                      firstBroadcastDate: firstBroadcastDate._0,
                                                                      fullDescription: fullDescription._0,
                                                                      genre: genre._0,
                                                                      geoblocking: geoblocking._0,
                                                                      id: id._0,
                                                                      mainImage: mainImage._0,
                                                                      kind: kind._0,
                                                                      parentCollections: parentCollections._0,
                                                                      partners: partners._0,
                                                                      programId: programId._0,
                                                                      shopUrl: shopUrl._0,
                                                                      shortDescription: shortDescription._0,
                                                                      stickers: stickers._0,
                                                                      subtitle: subtitle._0,
                                                                      teaserText: teaserText._0,
                                                                      title: title._0,
                                                                      trailer: trailer._0,
                                                                      trackingPixel: trackingPixel._0,
                                                                      url: url._0,
                                                                      chapters: chapters._0,
                                                                      ageRating: ageRating._0
                                                                    }
                                                                  };
                                                          }
                                                          var e = ageRating._0;
                                                          return {
                                                                  TAG: "Error",
                                                                  _0: {
                                                                    path: ".ageRating" + e.path,
                                                                    message: e.message,
                                                                    value: e.value
                                                                  }
                                                                };
                                                        }
                                                        var e$1 = chapters._0;
                                                        return {
                                                                TAG: "Error",
                                                                _0: {
                                                                  path: ".chapters" + e$1.path,
                                                                  message: e$1.message,
                                                                  value: e$1.value
                                                                }
                                                              };
                                                      }
                                                      var e$2 = url._0;
                                                      return {
                                                              TAG: "Error",
                                                              _0: {
                                                                path: ".url" + e$2.path,
                                                                message: e$2.message,
                                                                value: e$2.value
                                                              }
                                                            };
                                                    }
                                                    var e$3 = trackingPixel._0;
                                                    return {
                                                            TAG: "Error",
                                                            _0: {
                                                              path: ".trackingPixel" + e$3.path,
                                                              message: e$3.message,
                                                              value: e$3.value
                                                            }
                                                          };
                                                  }
                                                  var e$4 = trailer._0;
                                                  return {
                                                          TAG: "Error",
                                                          _0: {
                                                            path: ".trailer" + e$4.path,
                                                            message: e$4.message,
                                                            value: e$4.value
                                                          }
                                                        };
                                                }
                                                var e$5 = title._0;
                                                return {
                                                        TAG: "Error",
                                                        _0: {
                                                          path: ".title" + e$5.path,
                                                          message: e$5.message,
                                                          value: e$5.value
                                                        }
                                                      };
                                              }
                                              var e$6 = teaserText._0;
                                              return {
                                                      TAG: "Error",
                                                      _0: {
                                                        path: ".teaserText" + e$6.path,
                                                        message: e$6.message,
                                                        value: e$6.value
                                                      }
                                                    };
                                            }
                                            var e$7 = subtitle._0;
                                            return {
                                                    TAG: "Error",
                                                    _0: {
                                                      path: ".subtitle" + e$7.path,
                                                      message: e$7.message,
                                                      value: e$7.value
                                                    }
                                                  };
                                          }
                                          var e$8 = stickers._0;
                                          return {
                                                  TAG: "Error",
                                                  _0: {
                                                    path: ".stickers" + e$8.path,
                                                    message: e$8.message,
                                                    value: e$8.value
                                                  }
                                                };
                                        }
                                        var e$9 = shortDescription._0;
                                        return {
                                                TAG: "Error",
                                                _0: {
                                                  path: ".shortDescription" + e$9.path,
                                                  message: e$9.message,
                                                  value: e$9.value
                                                }
                                              };
                                      }
                                      var e$10 = shopUrl._0;
                                      return {
                                              TAG: "Error",
                                              _0: {
                                                path: ".shopUrl" + e$10.path,
                                                message: e$10.message,
                                                value: e$10.value
                                              }
                                            };
                                    }
                                    var e$11 = programId._0;
                                    return {
                                            TAG: "Error",
                                            _0: {
                                              path: ".programId" + e$11.path,
                                              message: e$11.message,
                                              value: e$11.value
                                            }
                                          };
                                  }
                                  var e$12 = partners._0;
                                  return {
                                          TAG: "Error",
                                          _0: {
                                            path: ".partners" + e$12.path,
                                            message: e$12.message,
                                            value: e$12.value
                                          }
                                        };
                                }
                                var e$13 = parentCollections._0;
                                return {
                                        TAG: "Error",
                                        _0: {
                                          path: ".parentCollections" + e$13.path,
                                          message: e$13.message,
                                          value: e$13.value
                                        }
                                      };
                              }
                              var e$14 = kind._0;
                              return {
                                      TAG: "Error",
                                      _0: {
                                        path: ".kind" + e$14.path,
                                        message: e$14.message,
                                        value: e$14.value
                                      }
                                    };
                            }
                            var e$15 = mainImage._0;
                            return {
                                    TAG: "Error",
                                    _0: {
                                      path: ".mainImage" + e$15.path,
                                      message: e$15.message,
                                      value: e$15.value
                                    }
                                  };
                          }
                          var e$16 = id._0;
                          return {
                                  TAG: "Error",
                                  _0: {
                                    path: ".id" + e$16.path,
                                    message: e$16.message,
                                    value: e$16.value
                                  }
                                };
                        }
                        var e$17 = geoblocking._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".geoblocking" + e$17.path,
                                  message: e$17.message,
                                  value: e$17.value
                                }
                              };
                      }
                      var e$18 = genre._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".genre" + e$18.path,
                                message: e$18.message,
                                value: e$18.value
                              }
                            };
                    }
                    var e$19 = fullDescription._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".fullDescription" + e$19.path,
                              message: e$19.message,
                              value: e$19.value
                            }
                          };
                  }
                  var e$20 = firstBroadcastDate._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".firstBroadcastDate" + e$20.path,
                            message: e$20.message,
                            value: e$20.value
                          }
                        };
                }
                var e$21 = durationLabel._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".durationLabel" + e$21.path,
                          message: e$21.message,
                          value: e$21.value
                        }
                      };
              }
              var e$22 = duration._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".duration" + e$22.path,
                        message: e$22.message,
                        value: e$22.value
                      }
                    };
            }
            var e$23 = credits._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".credits" + e$23.path,
                      message: e$23.message,
                      value: e$23.value
                    }
                  };
          }
          var e$24 = clip._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".clip" + e$24.path,
                    message: e$24.message,
                    value: e$24.value
                  }
                };
        }
        var e$25 = callToAction._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".callToAction" + e$25.path,
                  message: e$25.message,
                  value: e$25.value
                }
              };
      }
      var e$26 = broadcastDates._0;
      return {
              TAG: "Error",
              _0: {
                path: ".broadcastDates" + e$26.path,
                message: e$26.message,
                value: e$26.value
              }
            };
    }
    var e$27 = availability._0;
    return {
            TAG: "Error",
            _0: {
              path: ".availability" + e$27.path,
              message: e$27.message,
              value: e$27.value
            }
          };
  }
  var e$28 = audioVersions._0;
  return {
          TAG: "Error",
          _0: {
            path: ".audioVersions" + e$28.path,
            message: e$28.message,
            value: e$28.value
          }
        };
}

function getAvailabilitySticker(timeReference, dateFormater, availability) {
  return Core__Option.map((function (__x) {
                  return Core__Option.flatMap(__x, (function (param) {
                                var start = new Date(param.start);
                                if ($$Date.isAfter(start, timeReference)) {
                                  return {
                                          type: param.type,
                                          start: start
                                        };
                                }
                                
                              }));
                })((availability == null) ? undefined : Caml_option.some(availability)), dateFormater);
}

function getFavoriteStatus(timeReference, kind, availability) {
  var availability$1 = (availability == null) ? undefined : Caml_option.some(availability);
  var isFuture = Core__Option.map(availability$1, (function (param) {
          return $$Date.isAfter(new Date(param.start), timeReference);
        }));
  if (!(kind == null) && kind.code !== "EXTERNAL") {
    if (isFuture !== undefined && isFuture) {
      return {
              TAG: "Upcoming",
              _0: "NotFavorite"
            };
    } else {
      return {
              TAG: "Available",
              _0: "NotFavorite"
            };
    }
  } else {
    return "NotAvailable";
  }
}

function addToStickers(availabilitySticker, stickers, availability) {
  var type_ = availability !== undefined ? availability.type : undefined;
  return Core__Option.getOr(Core__Option.map(availabilitySticker, (function (text) {
                    var match = Type_String.NotEmpty.make(text);
                    if (match === undefined) {
                      return stickers;
                    }
                    var text$1 = Caml_option.valFromOption(match);
                    if (type_ !== undefined && (type_ === "LIVESTREAM_WEB" || type_ === "LIVESTREAM_TV")) {
                      return Type_Sticker.updateTopLeft(stickers, {
                                  NAME: "Livestream",
                                  VAL: text$1
                                });
                    } else {
                      return Type_Sticker.updateTopLeft(stickers, {
                                  NAME: "AvailableOn",
                                  VAL: text$1
                                });
                    }
                  })), stickers);
}

function addAvailabilitySticker(timeReference, teaserKind, dateFormater, availability, stickers) {
  var match = Type_Teaser.Kind.view(teaserKind);
  var match$1 = stickers.topLeft;
  if (match !== "Standard") {
    return stickers;
  }
  if (match$1 !== undefined) {
    if (typeof match$1 !== "object") {
      return stickers;
    }
    if (match$1.NAME !== "Genre") {
      return stickers;
    }
    
  }
  return addToStickers(getAvailabilitySticker(timeReference, dateFormater, availability), stickers, (availability == null) ? undefined : Caml_option.some(availability));
}

function getAvailabilityLabel(timeReference, betweenDatesFormater, start, end_) {
  var start$1 = new Date(start);
  var end_$1 = new Date(end_);
  var isBetween = $$Date.isBetween(start$1, end_$1, timeReference);
  var args = isBetween ? ({
        start: start$1,
        end: end_$1
      }) : undefined;
  return Core__Option.map(args, betweenDatesFormater);
}

function formatBroadcastDates(nextBroadcastDateFormater, broadcastDates, timeReference) {
  return Core__Option.map(broadcastDates, (function (broadcastDates) {
                return Core__Array.reduce(broadcastDates, [], (function (accumulator, broadcastDate) {
                              var date = new Date(broadcastDate);
                              var isFuture = $$Date.isAfter(date, timeReference);
                              if (!isFuture) {
                                return accumulator;
                              }
                              var dateFormat = Type_String.Teaser.BroadcastDate.make(nextBroadcastDateFormater(date));
                              return accumulator.concat([dateFormat]);
                            }));
              }));
}

function getProgramKind(kind, parentCollections) {
  var withParentCollection = function (code) {
    if (code === "MAGAZINE") {
      return "MAGAZINE";
    } else if (parentCollections == null) {
      return code;
    } else {
      return Core__Option.getOr(Core__Option.map(parentCollections.find(function (param) {
                          return param.kind === "MAGAZINE";
                        }), (function (param) {
                        return param.kind;
                      })), code);
    }
  };
  return Core__Option.map(Core__Option.map((kind == null) ? undefined : Caml_option.some(kind), (function (param) {
                    return param.code;
                  })), withParentCollection);
}

function toDsPartner(param) {
  return {
          title: param.title,
          url: param.url,
          imageSrc: Core__Option.map(Caml_option.nullable_to_opt(param.image), (function (param) {
                  return Type_Image.Src.make(param.url);
                }))
        };
}

function getAudioVersions(audioVersions) {
  return Core__Option.map(Core__Option.flatMap(Core__Option.map(Core__Option.flatMap((audioVersions == null) ? undefined : Caml_option.some(audioVersions), Util_Array.NonEmpty.makeFromArray), (function (array) {
                        return Util_Array.NonEmpty.map(array, (function (param) {
                                      var label = param.label;
                                      return Core__Option.map(Type_Common.audioVersionCodeFromJs(param.code), (function (code) {
                                                    return {
                                                            code: code,
                                                            label: label
                                                          };
                                                  }));
                                    }));
                      })), Util_Array.NonEmpty.catOption), Util_Array.NonEmpty.toArray);
}

function toDsTeaser(dateFormater, betweenDatesFormater, nextBroadcastDateFormater, timeReference, zoneId, zoneItemTitle, $staropt$star, domain, $staropt$star$1, param) {
  var ageRating = param.ageRating;
  var kind = param.kind;
  var mainImage = param.mainImage;
  var availability = param.availability;
  var genreStickerBehavior = $staropt$star !== undefined ? $staropt$star : "HideGenreSticker";
  var hoverBehavior = $staropt$star$1 !== undefined ? $staropt$star$1 : "Normal";
  var parentPage = domain !== undefined ? ({
        domain: domain
      }) : ({
        domain: "replay"
      });
  var duration = Core__Option.map(Caml_option.nullable_to_opt(param.duration), Type_Common.jsSecondDurationToDuration);
  var durationLabel = Type_String.Teaser.DurationLabel.makeFromNullable(param.durationLabel);
  var stickers = Core__Option.getOr(Core__Option.map(Caml_option.nullable_to_opt(param.stickers), (function (__x) {
              return Type_Sticker.makeFromCommonStickers(genreStickerBehavior, __x);
            })), Type_Sticker.empty);
  var teaserKind = Type_Teaser.Kind.fromStickers(stickers);
  var stickers$1 = addAvailabilitySticker(timeReference, teaserKind, dateFormater, availability, Type_Teaser.Helper.addDurationSticker(durationLabel, teaserKind, stickers));
  var programId = Type_String.Program.Id.makeFromNullable(param.programId);
  var programKind = getProgramKind(kind, param.parentCollections);
  var teaserId = Type_String.Teaser.Id.make(param.id);
  var dsId = Type_Teaser.Helper.makeDsId(programId, teaserId);
  var key = Type_Teaser.Helper.makeKey(zoneId, dsId);
  var match = Type_String.Teaser.Title.makeFromNullable(param.title);
  var match$1 = Type_String.Teaser.Subtitle.makeFromNullable(param.subtitle);
  var match$2;
  switch (zoneItemTitle) {
    case "Normal" :
        match$2 = match !== undefined ? [
            Core__Option.map(match, (function (x) {
                    return {
                            TAG: "Displayed",
                            _0: x
                          };
                  })),
            Core__Option.map(match$1, (function (x) {
                    return {
                            TAG: "Displayed",
                            label: x
                          };
                  }))
          ] : [
            Core__Option.map(match$1, (function (x) {
                    return {
                            TAG: "Displayed",
                            _0: Type_String.Teaser.Title.make(Type_String.Teaser.Subtitle.toString(x))
                          };
                  })),
            undefined
          ];
        break;
    case "SubtitleAsTitle" :
        match$2 = [
          Core__Option.map(match, (function (x) {
                  return {
                          TAG: "AccessibilityOnly",
                          _0: x
                        };
                })),
          Core__Option.map(match$1, (function (x) {
                  var match = Type_Teaser.Kind.view(teaserKind);
                  return {
                          TAG: "Displayed",
                          label: x,
                          style: match === "Direct" ? "TitleStyle" : "SubtitleStyle"
                        };
                }))
        ];
        break;
    case "HoverOnly" :
        match$2 = [
          Core__Option.map(match, (function (x) {
                  return {
                          TAG: "HoverOnly",
                          _0: x
                        };
                })),
          Core__Option.map(match$1, (function (x) {
                  return {
                          TAG: "HoverOnly",
                          _0: x
                        };
                }))
        ];
        break;
    case "NeverDisplayed" :
        match$2 = [
          undefined,
          undefined
        ];
        break;
    
  }
  var ageRestriction;
  if (ageRating == null) {
    ageRestriction = undefined;
  } else {
    switch (ageRating) {
      case 12 :
          ageRestriction = "12";
          break;
      case 16 :
          ageRestriction = "16";
          break;
      case 18 :
          ageRestriction = "18";
          break;
      default:
        ageRestriction = undefined;
    }
  }
  return {
          audioVersions: getAudioVersions(param.audioVersions),
          availability: Core__Option.map((availability == null) ? undefined : Caml_option.some(availability), (function (param) {
                  var end_ = param.end;
                  var start = param.start;
                  return {
                          type: param.type,
                          label: getAvailabilityLabel(timeReference, betweenDatesFormater, start, Core__Option.getOr((end_ == null) ? undefined : Caml_option.some(end_), "")),
                          start: start,
                          upcomingDate: param.upcomingDate,
                          end: (end_ == null) ? undefined : Caml_option.some(end_)
                        };
                })),
          broadcastDates: formatBroadcastDates(nextBroadcastDateFormater, Caml_option.nullable_to_opt(param.broadcastDates), timeReference),
          callToAction: Type_String.CallToAction.makeFromNullable(param.callToAction),
          parentPage: parentPage,
          credits: Caml_option.nullable_to_opt(param.credits),
          description: Type_String.Teaser.Description.makeFromNullable(param.teaserText),
          dsId: dsId,
          duration: duration,
          durationLabel: durationLabel,
          favoriteStatus: getFavoriteStatus(timeReference, kind, availability),
          href: Type_String.Href.make(param.url),
          geoblocking: Core__Option.map(Caml_option.nullable_to_opt(param.geoblocking), (function (param) {
                  return {
                          code: param.code
                        };
                })),
          id: teaserId,
          imageSrc: programKind === "VOD" ? Type_Image.Src.makeVod(mainImage.url) : Type_Image.Src.make(mainImage.url),
          key: key,
          kind: teaserKind,
          partners: Core__Option.map(Caml_option.nullable_to_opt(param.partners), (function (partners) {
                  return partners.map(toDsPartner);
                })),
          program: {
            clip: Core__Option.flatMap(Caml_option.nullable_to_opt(param.clip), (function (param) {
                    return Type_String.Program.Clip.makeFromNullable(param.config);
                  })),
            description: Caml_option.nullable_to_opt(param.fullDescription),
            firstBroadcastDate: Type_String.Program.FirstBroadcastDate.makeFromNullable(param.firstBroadcastDate),
            genre: Core__Option.map(Caml_option.nullable_to_opt(param.genre), (function (param) {
                    return {
                            label: Type_String.Program.Genre.Label.make(param.label),
                            url: Type_String.Program.Genre.Url.make(param.url)
                          };
                  })),
            id: programId,
            kind: programKind,
            shortDescription: Caml_option.nullable_to_opt(param.shortDescription),
            trailer: Core__Option.flatMap(Caml_option.nullable_to_opt(param.trailer), (function (param) {
                    return Type_String.Program.Trailer.makeFromNullable(param.config);
                  })),
            chapters: Core__Option.flatMap(Caml_option.nullable_to_opt(param.chapters), (function (chapters) {
                    return Util_Array.NonEmpty.makeFromArray(chapters.map(function (chapter) {
                                    return {
                                            title: chapter.title,
                                            timecode: chapter.timecode
                                          };
                                  }));
                  }))
          },
          progression: Type_Progression.noProgression,
          shopUrl: Caml_option.nullable_to_opt(param.shopUrl),
          stickers: stickers$1,
          subtitle: match$2[1],
          title: match$2[0],
          trackingPixel: Type_String.TrackingPixel.makeFromNullable(param.trackingPixel),
          hoverBehavior: programKind === "VOD" ? "Disabled" : hoverBehavior,
          ageRestriction: ageRestriction
        };
}

export {
  kind_encode ,
  kind_decode ,
  mainImage_encode ,
  mainImage_decode ,
  availability_encode ,
  availability_decode ,
  partner_encode ,
  partner_decode ,
  trailer_encode ,
  trailer_decode ,
  broadcastDates_encode ,
  broadcastDates_decode ,
  genre_encode ,
  genre_decode ,
  parentCollection_encode ,
  parentCollection_decode ,
  geoblocking_encode ,
  geoblocking_decode ,
  audioVersion_encode ,
  audioVersion_decode ,
  chapter_encode ,
  chapter_decode ,
  audioVersions_encode ,
  audioVersions_decode ,
  t_encode ,
  t_decode ,
  getAvailabilitySticker ,
  getFavoriteStatus ,
  addToStickers ,
  addAvailabilitySticker ,
  getAvailabilityLabel ,
  formatBroadcastDates ,
  getProgramKind ,
  toDsPartner ,
  getAudioVersions ,
  toDsTeaser ,
}
/* Type_Image Not a pure module */
