'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as UseForm from "../../../hook/UseForm.res.mjs";
import * as Atom_Cta from "../../../design-system/atom/Atom_Cta.res.mjs";
import * as Atom_Text from "../../../design-system/atom/Atom_Text.res.mjs";
import * as Util_I18n from "../../../util/Util_I18n.res.mjs";
import * as Atom_Column from "../../../design-system/atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../../design-system/atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Layout_Auth from "../layout/Layout_Auth.res.mjs";
import * as Type_String from "../../../type/Type_String.res.mjs";
import * as Atom_Divider from "../../../design-system/atom/Atom_Divider.res.mjs";
import * as Atom_Text_Ds from "../../../design-system/atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as UseUserFetch from "../../../hook/UseUserFetch.res.mjs";
import * as Atom_Checkbox from "../../../design-system/atom/Atom_Checkbox.res.mjs";
import * as Atom_Textfield from "../../../design-system/atom/Atom_Textfield.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Molecule_DangerousHtml from "../../../design-system/molecule/Molecule_DangerousHtml.res.mjs";
import * as User_Api__Sso__Register from "../../../user/User_Api/User_Api__Sso__Register.res.mjs";

function reducer(state, action) {
  return "ConfirmEmail";
}

function make() {
  var match = React.useReducer(reducer, "Register");
  var dispatch = match[1];
  return {
          state: match[0],
          displayConfirmEmail: (function () {
              dispatch("DisplayConfirmEmail");
            })
        };
}

var UseRegisterStates = {
  reducer: reducer,
  initialState: "Register",
  make: make
};

function Page_Register$ConfirmEmailView(props) {
  var redirectToLogin = props.redirectToLogin;
  var match = Context.I18n.getState();
  var match$1 = match.labels.mona.login;
  var email = Core__Option.getOr(props.email, "");
  var dangerousHtml = "<p>" + match.formaters.registerConfirmSuccess(email.replace(email, "<b>" + email + "</b>")) + "</p>";
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(JsxRuntime.jsx(Molecule_DangerousHtml.make, {
                                dangerousHtml: dangerousHtml
                              })),
                      kind: "div",
                      style: Atom_Text_Ds.User.subtitle("Auth")
                    }),
                JsxRuntime.jsx(Atom_Column.make, {
                      children: JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                            label: match$1.requestPasswordSuccessButton,
                            onClick: (function (param) {
                                redirectToLogin();
                              }),
                            variant: "base"
                          })
                    })
              ],
              innerSpace: Caml_option.some(Layout_Auth.Form_Css.wrapperInnerSpace),
              width: Caml_option.some(Layout_Auth.Form_Css.wrapperWidth)
            });
}

var ConfirmEmailView = {
  make: Page_Register$ConfirmEmailView
};

function get(values, field) {
  switch (field) {
    case "Email" :
        return values.email;
    case "Password" :
        return values.password;
    case "PasswordConfirmation" :
        return values.passwordConfirmation;
    case "AcceptTos" :
        return values.acceptTos;
    case "RegisterToNewsletter" :
        return values.registerToNewsletter;
    case "AcceptCom" :
        return values.acceptCom;
    
  }
}

function set(values, field, value) {
  switch (field) {
    case "Email" :
        return {
                email: value,
                password: values.password,
                passwordConfirmation: values.passwordConfirmation,
                acceptTos: values.acceptTos,
                registerToNewsletter: values.registerToNewsletter,
                acceptCom: values.acceptCom
              };
    case "Password" :
        return {
                email: values.email,
                password: value,
                passwordConfirmation: values.passwordConfirmation,
                acceptTos: values.acceptTos,
                registerToNewsletter: values.registerToNewsletter,
                acceptCom: values.acceptCom
              };
    case "PasswordConfirmation" :
        return {
                email: values.email,
                password: values.password,
                passwordConfirmation: value,
                acceptTos: values.acceptTos,
                registerToNewsletter: values.registerToNewsletter,
                acceptCom: values.acceptCom
              };
    case "AcceptTos" :
        return {
                email: values.email,
                password: values.password,
                passwordConfirmation: values.passwordConfirmation,
                acceptTos: value,
                registerToNewsletter: values.registerToNewsletter,
                acceptCom: values.acceptCom
              };
    case "RegisterToNewsletter" :
        return {
                email: values.email,
                password: values.password,
                passwordConfirmation: values.passwordConfirmation,
                acceptTos: values.acceptTos,
                registerToNewsletter: value,
                acceptCom: values.acceptCom
              };
    case "AcceptCom" :
        return {
                email: values.email,
                password: values.password,
                passwordConfirmation: values.passwordConfirmation,
                acceptTos: values.acceptTos,
                registerToNewsletter: values.registerToNewsletter,
                acceptCom: value
              };
    
  }
}

var FormFields = {
  get: get,
  set: set
};

function makeInitialState(email) {
  return {
          email: Core__Option.getOr(email, ""),
          password: "",
          passwordConfirmation: "",
          acceptTos: false,
          registerToNewsletter: false,
          acceptCom: false
        };
}

var Form = UseForm.Make({
      set: set,
      get: get
    });

function Page_Register$RegisterForm(props) {
  var onEmailUpdated = props.onEmailUpdated;
  var displayConfirmEmail = props.displayConfirmEmail;
  var match = Context.I18n.getState();
  var locale = match.locale;
  var labels = match.labels;
  var formaters = match.formaters;
  var match$1 = labels.mona.login;
  var passwordPlaceholder = match$1.passwordPlaceholder;
  var controller = new AbortController();
  var signal = controller.signal;
  var match$2 = labels.mona.register;
  var makeSchema = function () {
    return Form.Schema.Validation.schema([
                Form.Schema.Validation.nonEmpty("Email"),
                Form.Schema.Validation.email("Email"),
                Form.Schema.Validation.nonEmpty("Password"),
                Form.Schema.Validation.string(6, undefined, undefined, undefined, "Password"),
                Form.Schema.Validation.password("Password"),
                Form.Schema.Validation.checked("AcceptTos")
              ]);
  };
  var schema = makeSchema();
  var match$3 = labels.mona.profile;
  var errorMessage = match$3.errorMessage;
  var emailPlaceholder = match$3.emailPlaceholder;
  var match$4 = UseUserFetch.make();
  var setLoading = match$4.setLoading;
  var setError = match$4.setError;
  var handleSubmit = async function (param) {
    var match = param.state.values;
    setLoading();
    var response = await User_Api__Sso__Register.post({
          args: {
            email: Type_String.User.Email.toString(match.email),
            password: match.password,
            acceptTOS: match.acceptTos,
            nlarte: match.registerToNewsletter,
            acceptCom: match.acceptCom,
            language: Util_I18n.localeToString(locale)
          },
          signal: signal,
          locale: locale
        });
    if (response.TAG === "Ok") {
      displayConfirmEmail();
    } else {
      setError(errorMessage);
    }
    return {
            TAG: "Ok",
            _0: undefined
          };
  };
  var form = Form.use(makeInitialState(props.email), schema, handleSubmit, undefined, labels, formaters);
  var match$5 = form.values;
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    form.submit();
  };
  var acceptTosLabel = formaters.acceptTosLabel(match$2.acceptTosCguLabel, match$2.acceptTosCguLink, match$2.acceptTosDataLabel, match$2.acceptTosDataLink);
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Textfield.User.Readonly.make, {
                              value: match$5.email,
                              label: emailPlaceholder,
                              name: emailPlaceholder,
                              inputRef: form.getFieldRef({
                                    TAG: "Field",
                                    _0: "Email"
                                  }),
                              action: {
                                TAG: "Unique",
                                onClick: (function (param) {
                                    onEmailUpdated();
                                  }),
                                style: Atom_Cta_Ds.User.textfieldAction,
                                label: match$2.emailActionLabel,
                                accessibilityLabel: match$2.emailAccessibilityLabel
                              }
                            }),
                        JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                              autoComplete: "new-password",
                              type_: "password",
                              value: match$5.password,
                              label: passwordPlaceholder,
                              onChange: (function (__x) {
                                  form.handleChange("Password", __x);
                                }),
                              id: "password",
                              name: passwordPlaceholder,
                              errorMessage: form.getFieldError({
                                    TAG: "Field",
                                    _0: "Password"
                                  }),
                              inputRef: form.getFieldRef({
                                    TAG: "Field",
                                    _0: "Password"
                                  }),
                              action: {
                                TAG: "Toggle",
                                style: Atom_Cta_Ds.User.textfieldAction,
                                labelOn: match$2.passwordActionLabelOn,
                                labelOff: match$2.passwordActionLabelOff,
                                accessibilityLabelOn: match$2.passwordAccessibilityLabelOn,
                                accessibilityLabelOff: match$2.passwordAccessibilityLabelOff
                              },
                              autoFocus: true
                            }),
                        JsxRuntime.jsx(Atom_Checkbox.User.make, {
                              required: true,
                              innerHtml: acceptTosLabel,
                              onClick: (function (val) {
                                  form.handleChange("AcceptTos", val);
                                }),
                              id: "acceptTos",
                              checked: match$5.acceptTos,
                              ref_: Caml_option.some(form.getFieldRef({
                                        TAG: "Field",
                                        _0: "AcceptTos"
                                      }))
                            })
                      ],
                      innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                    }),
                match$4.component,
                JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                      label: match$2.registerButton,
                      variant: "fullWidth",
                      type_: "submit"
                    }),
                JsxRuntime.jsx(Atom_Divider.make, {
                      style: Atom_Divider.user
                    })
              ],
              innerSpace: Caml_option.some(Layout_Auth.Form_Css.wrapperInnerSpace),
              kind: {
                TAG: "Form",
                _0: onSubmit
              },
              width: Caml_option.some(Layout_Auth.Form_Css.wrapperWidth)
            });
}

var RegisterForm = {
  FormFields: FormFields,
  makeInitialState: makeInitialState,
  Form: Form,
  make: Page_Register$RegisterForm
};

function make$1(param) {
  var email = param.email;
  var match = Context.I18n.getState();
  var labels = match.labels;
  var match$1 = labels.mona.register;
  var title = match$1.title;
  var match$2 = make();
  if (match$2.state === "Register") {
    var match$3 = labels.mona.register;
    return JsxRuntime.jsx(Layout_Auth.make, {
                title: title,
                footerLinkLabel: match$3.footerLinkLabel,
                footerLinkUrl: match$3.footerLinkUrl,
                footerLabel: match$3.footerLabel,
                children: JsxRuntime.jsx(Page_Register$RegisterForm, {
                      displayConfirmEmail: match$2.displayConfirmEmail,
                      email: email,
                      onEmailUpdated: param.onEmailUpdated
                    })
              });
  }
  var match$4 = labels.mona.login;
  return JsxRuntime.jsx(Layout_Auth.make, {
              title: title,
              footerLinkLabel: match$4.footerLinkLabel,
              footerLinkUrl: match$4.footerLinkUrl,
              children: JsxRuntime.jsx(Page_Register$ConfirmEmailView, {
                    redirectToLogin: param.redirectToLogin,
                    email: email
                  })
            });
}

export {
  UseRegisterStates ,
  ConfirmEmailView ,
  RegisterForm ,
  make$1 as make,
}
/* Form Not a pure module */
