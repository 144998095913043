import { useTokenStatus } from '@artegeie/design-system/replay';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useStartAuth } from '../../shared/useRedirect';
import { isAuthenticationForbidden, isAuthenticationRequired, type PageCode } from '@replay/types/Restrictions';
import { Locale } from '@replay/types/Locale';

export const usePageRestriction = (pageCode: PageCode, locale: Locale) => {
    const { push } = useRouter();
    const { startAuth } = useStartAuth(locale);
    const loginStatus = useTokenStatus();

    useEffect(() => {
        if (loginStatus === 'Connected' && isAuthenticationForbidden(pageCode)) {
            push(`/${locale}/`);
        }
        if ((loginStatus === 'Disconnected' || loginStatus === 'Anonymous') && isAuthenticationRequired(pageCode)) {
            startAuth({
                shouldVerifyAge: false,
                returnPage: window.location.href,
            });
        }
        // cf. https://github.com/vercel/next.js/issues/18127#issuecomment-950907739
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginStatus, pageCode]);
};
