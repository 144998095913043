// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Box from "../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Util_Array from "../../util/Util_Array.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Particle_zIndex from "../particle/Particle_zIndex.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as Particle_Background_Ds from "../particle/Particle_Background_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var containerBlockspace = Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeAll("S"), Particle_Spacer_Ds.makeAll("M"), undefined, Particle_Spacer_Ds.makeAll("XS")));

function playerCentered(onClose) {
  return {
          contentPosition: "Center",
          closeButton: onClose,
          background: Particle_Background_Ds.playerOverlay,
          blockSpace: containerBlockspace
        };
}

function playerLeft(onClose) {
  return {
          contentPosition: "Left",
          closeButton: onClose,
          background: Particle_Background_Ds.playerOverlay,
          blockSpace: containerBlockspace
        };
}

var $$default = {
  contentPosition: "Center",
  closeButton: undefined,
  background: Particle_Background_Ds.modalOverlay,
  blockSpace: containerBlockspace
};

var eventZoneButton = {
  contentPosition: "Center",
  closeButton: undefined,
  background: Particle_Background_Ds.eventZoneOverlay,
  blockSpace: undefined
};

var eventZonePlayer = {
  contentPosition: "Center",
  closeButton: undefined,
  background: Particle_Background_Ds.transparent,
  blockSpace: undefined
};

var Organism_Overlay_Ds = {
  playerCentered: playerCentered,
  playerLeft: playerLeft,
  $$default: $$default,
  eventZoneButton: eventZoneButton,
  eventZonePlayer: eventZonePlayer
};

var closeButtonWidth = Particle_Screen_Ds.catOption(0, Atom_Cta_Ds.getPredefinedWidth(Atom_Cta_Ds.Player.closeOverlay));

var contentLeftRules = Particle_Screen_Ds.map(closeButtonWidth, (function (width) {
        return [CssJs.paddingRight(CssJs.px(width))];
      }));

var contentCenterRules = Particle_Screen_Ds.map(closeButtonWidth, (function (width) {
        return [
                CssJs.paddingLeft(CssJs.px(width)),
                CssJs.paddingRight(CssJs.px(width))
              ];
      }));

var closeButtonPosition = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: "zero",
        right: "zero"
      }
    });

function Organism_Overlay$Internal(props) {
  var rules = props.rules;
  var __position = props.position;
  var style = props.style;
  var zIndex = props.zIndex;
  var position = __position !== undefined ? Caml_option.valFromOption(__position) : Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
          TAG: "Absolute",
          _0: {
            top: "zero",
            bottom: "zero",
            right: "zero",
            left: "zero"
          }
        });
  var match = Context.I18n.getState();
  var match$1 = match.labels.accessibility;
  var closeAriaLabel = Core__Option.getOr(props.closeButtonAriaLabel, match$1.close);
  var accessibility = Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, closeAriaLabel, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  var rules$1 = zIndex !== undefined ? (
      rules !== undefined ? Caml_option.some(Particle_Screen_Ds.$$Array.concat(Caml_option.valFromOption(rules), Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [Particle_zIndex.Particle_zIndex_Css.toCss(zIndex)]))) : Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [Particle_zIndex.Particle_zIndex_Css.toCss(zIndex)]))
    ) : (
      rules !== undefined ? Caml_option.some(Caml_option.valFromOption(rules)) : undefined
    );
  if (props.status === "Hide") {
    return null;
  }
  var match$2;
  if (style.contentPosition === "Left") {
    var onClose = style.closeButton;
    match$2 = onClose !== undefined ? [
        Caml_option.some(contentLeftRules),
        undefined,
        undefined,
        JsxRuntime.jsx(Atom_Box.make, {
              children: Caml_option.some(JsxRuntime.jsx(Atom_Cta.Button.make, {
                        accessibility: accessibility,
                        onClick: (function (param) {
                            onClose();
                          }),
                        style: Atom_Cta_Ds.Player.closeOverlay
                      })),
              position: Caml_option.some(closeButtonPosition)
            })
      ] : [
        undefined,
        undefined,
        undefined,
        null
      ];
  } else {
    var onClose$1 = style.closeButton;
    match$2 = onClose$1 !== undefined ? [
        Caml_option.some(contentCenterRules),
        Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
        Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
        JsxRuntime.jsx(Atom_Box.make, {
              children: Caml_option.some(JsxRuntime.jsx(Atom_Cta.Button.make, {
                        accessibility: accessibility,
                        onClick: (function (param) {
                            onClose$1();
                          }),
                        style: Atom_Cta_Ds.Player.closeOverlay
                      })),
              position: Caml_option.some(closeButtonPosition)
            })
      ] : [
        undefined,
        Caml_option.some(Particle_Screen_Constants.justifyContentCenter),
        Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
        null
      ];
  }
  return JsxRuntime.jsx(Atom_Column.make, {
              background: Caml_option.some(style.background),
              blockSpace: style.blockSpace,
              children: JsxRuntime.jsxs(Atom_Cell.make, {
                    justifyContent: match$2[1],
                    alignItems: match$2[2],
                    width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                    height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
                    children: [
                      match$2[3],
                      props.children
                    ],
                    position: Caml_option.some(Particle_Screen_Constants.positionRelative),
                    identifier: Caml_option.some(Identifiers.Overlay.closeableContent),
                    rules: match$2[0]
                  }),
              identifier: Caml_option.some(Identifiers.Overlay.closeableContainer),
              position: Caml_option.some(position),
              rules: rules$1
            });
}

var Internal = {
  closeButtonPosition: closeButtonPosition,
  make: Organism_Overlay$Internal
};

function make(param) {
  var style = Core__Option.getOr(param.style, $$default);
  return JsxRuntime.jsx(Organism_Overlay$Internal, {
              children: param.children,
              status: param.isOpen ? "Show" : "Hide",
              zIndex: param.zIndex,
              style: style
            });
}

function make$1(param) {
  var onClose = param.onClose;
  var alignment = Core__Option.getOr(param.alignment, "Left");
  var style;
  style = alignment === "Center" ? playerCentered(onClose) : playerLeft(onClose);
  var rules = Core__Option.map(param.zIndex, (function (zIndex) {
          return Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Util_Array.pure(CssJs.zIndex(zIndex)));
        }));
  return JsxRuntime.jsx(Organism_Overlay$Internal, {
              children: param.children,
              status: param.isOpen ? "Show" : "Hide",
              style: style,
              closeButtonAriaLabel: param.closeButtonAriaLabel,
              rules: rules
            });
}

var Player = {
  make: make$1
};

export {
  containerBlockspace ,
  Organism_Overlay_Ds ,
  closeButtonWidth ,
  contentLeftRules ,
  contentCenterRules ,
  Internal ,
  make ,
  Player ,
}
/* containerBlockspace Not a pure module */
