import { type Codes } from '@replay/slugs-generator';

export type InternalCodes =
    | 'HOME'
    | 'SETTINGS'
    | 'SITEMAP'
    | 'UNKNOWN'
    | 'SHORTS'
    | 'AUTH_LANDING'
    | 'AUTH_LOGIN'
    | 'AUTH_REGISTER'
    | 'AUTH_RESET_PASSWORD'
    | 'AUTH_MAGIC_LINK'
    | 'AUTH_VERIFY_EMAIL'
    | 'AUTH_CALLBACK'
    | 'PROFILE_EVENTS_HOME'
    | 'PROFILE_EVENTS_UNITARY'
    | 'PROFILE_EVENTS_FAVORITES'
    | 'PROFILE_UPDATE_AVATAR'
    | 'PROFILE_DELETE_ACCOUNT'
    | 'PROFILE_UPDATE_EMAIL'
    | 'PROFILE_UPDATE_PROFILE'
    | 'PROFILE_UPDATE_PASSWORD'
    | 'AGE_VERIFICATION_TV'
    | 'PROFILE_TV_CODE'
    | 'AGE_VERIFICATION_FAILED'
    | 'AGE_VERIFICATION_PENDING'
    | 'MY_FAVORITES'
    | 'MY_VIDEOS'
    | 'MY_HISTORY'
    | 'MY_RESUME'
    | 'FEEDBACK_PAGE'
    | 'FEEDBACK_SUCCESS_PAGE'
    | 'FAIL'
    | 'FOOTER_COMPONENT';

export type PageCode = Codes | InternalCodes;

export type AuthenticatedCodes = Extract<
    PageCode,
    | 'MY_ADVANTAGE'
    | 'PROFILE_UPDATE_AVATAR'
    | 'PROFILE_DELETE_ACCOUNT'
    | 'PROFILE_UPDATE_EMAIL'
    | 'PROFILE_UPDATE_PROFILE'
    | 'PROFILE_UPDATE_PASSWORD'
    | 'PROFILE_TV_CODE'
    | 'AGE_VERIFICATION_PENDING'
>;

type Restriction = 'Anonymous' | 'Disconnected' | 'Connected';

const restrictionByCode = (code: PageCode): Restriction[] => {
    switch (code) {
        case 'MY_ADVANTAGE':
        case 'PROFILE_UPDATE_AVATAR':
        case 'PROFILE_DELETE_ACCOUNT':
        case 'PROFILE_UPDATE_EMAIL':
        case 'PROFILE_UPDATE_PROFILE':
        case 'PROFILE_UPDATE_PASSWORD':
        case 'PROFILE_TV_CODE':
        case 'AGE_VERIFICATION_PENDING':
            return ['Connected'];
        case 'AUTH_LANDING':
        case 'AUTH_LOGIN':
        case 'AUTH_REGISTER':
        case 'AUTH_RESET_PASSWORD':
        case 'AUTH_MAGIC_LINK':
        case 'AUTH_VERIFY_EMAIL':
            return ['Disconnected', 'Anonymous'];
        default:
            return ['Disconnected', 'Connected', 'Anonymous'];
    }
};

export const isAuthenticationRequired = (pageCode: PageCode): boolean => {
    return restrictionByCode(pageCode).length === 1 && restrictionByCode(pageCode).includes('Connected');
};

export const isAuthenticationForbidden = (pageCode: PageCode): boolean => {
    return restrictionByCode(pageCode).includes('Connected') === false;
};

export const codeToTrackingSlug = (code: PageCode): string => {
    switch (code) {
        case 'FAIL':
            return '404-ai';
        default:
            return code;
    }
};
