// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as React from "react";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Box from "../atom/Atom_Box.res.mjs";
import * as Atom_Cta from "../atom/Atom_Cta.res.mjs";
import * as SetFocus from "../../hook/SetFocus.res.mjs";
import * as Util_Dom from "../../util/Util_Dom.res.mjs";
import * as Atom_Cell from "../atom/Atom_Cell.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Identifiers from "../../selector/Identifiers.res.mjs";
import * as Atom_Text_Ds from "../atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as UseClickOutside from "../../hook/UseClickOutside.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Organism_Tooltip_Ds from "./Organism_Tooltip_Ds.res.mjs";
import * as Organism_Tooltip_Css from "./Organism_Tooltip_Css.res.mjs";
import * as Particle_Accessibility from "../particle/Particle_Accessibility.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";

var defaultContainerBlockspace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, Particle_Spacer_Ds.makeAll("XS"));

var closeButtonWidth = Particle_Screen_Ds.catOption(0, Atom_Cta_Ds.getPredefinedWidth(Atom_Cta_Ds.Player.closeTooltip));

var contentRules = Particle_Screen_Ds.map(closeButtonWidth, (function (width) {
        return [CssJs.paddingRight(CssJs.px(width))];
      }));

var contentPosition = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, {
      TAG: "Absolute",
      _0: {
        top: "zero",
        right: "zero"
      }
    });

function Organism_Tooltip$Internal(props) {
  var status = props.status;
  var style = props.style;
  var __containerBlockspace = props.containerBlockspace;
  var closeButton = props.closeButton;
  var clickOutsideBehavior = props.clickOutsideBehavior;
  var containerBlockspace = __containerBlockspace !== undefined ? Caml_option.valFromOption(__containerBlockspace) : defaultContainerBlockspace;
  var ref = React.useRef(null);
  var match = Context.I18n.getState();
  var match$1 = match.labels.accessibility;
  var background = style.background;
  var decoration = style.decoration;
  var closeAriaLabel = Core__Option.getOr(props.closeButtonAriaLabel, match$1.close);
  var handleClickOutside = React.useMemo((function () {
          return function () {
            if (status === "Show" && typeof clickOutsideBehavior === "object") {
              return clickOutsideBehavior._0();
            }
            
          };
        }), [
        status,
        clickOutsideBehavior
      ]);
  UseClickOutside.make(ref, handleClickOutside);
  var match$2;
  match$2 = typeof closeButton !== "object" ? [
      undefined,
      null
    ] : [
      Caml_option.some(contentRules),
      JsxRuntime.jsx(Atom_Box.make, {
            children: Caml_option.some(JsxRuntime.jsx(Atom_Cta.Button.make, {
                      accessibility: Particle_Accessibility.make(undefined, undefined, undefined, undefined, undefined, undefined, closeAriaLabel, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                      onClick: closeButton._0,
                      style: Atom_Cta_Ds.Player.closeTooltip
                    })),
            position: Caml_option.some(contentPosition)
          })
    ];
  var display;
  display = status === "Show" ? CssJs.display("block") : CssJs.display("none");
  var arrow;
  if (typeof decoration !== "object") {
    arrow = null;
  } else {
    var position = Organism_Tooltip_Ds.makeArrowPosition(decoration._0, Organism_Tooltip_Css.arrowSize);
    arrow = JsxRuntime.jsx(Atom_Box.make, {
          background: Caml_option.some(background),
          position: Caml_option.some(position),
          rules: Caml_option.some(Organism_Tooltip_Css.arrowRules)
        });
  }
  var containerRules = Organism_Tooltip_Css.makeContainerRules(style, display);
  var tooltipContent = JsxRuntime.jsxs(Atom_Column.make, {
        background: Caml_option.some(background),
        blockSpace: Caml_option.some(containerBlockspace),
        children: [
          JsxRuntime.jsxs(Atom_Cell.make, {
                width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth),
                height: Caml_option.some(Particle_Screen_Constants.oneHundredPctHeight),
                children: [
                  match$2[1],
                  props.content
                ],
                position: Caml_option.some(Particle_Screen_Constants.positionRelative),
                identifier: Caml_option.some(Identifiers.Tooltip.closeableContent),
                rules: match$2[0]
              }),
          arrow
        ],
        identifier: Caml_option.some(Identifiers.Tooltip.closeableContainer),
        position: Caml_option.some(style.position),
        ref_: props.containerRef,
        rules: Caml_option.some(containerRules)
      });
  return JsxRuntime.jsxs(Atom_Box.make, {
              children: [
                tooltipContent,
                props.children
              ],
              onMouseEnter: props.onMouseEnter,
              onMouseLeave: props.onMouseLeave,
              position: Caml_option.some(Particle_Screen_Constants.positionRelative),
              ref_: ref
            });
}

var Internal = {
  defaultContainerBlockspace: defaultContainerBlockspace,
  closeButtonWidth: closeButtonWidth,
  contentRules: contentRules,
  contentPosition: contentPosition,
  make: Organism_Tooltip$Internal
};

function Organism_Tooltip$Player(props) {
  var onClickOutside = props.onClickOutside;
  var onClose = props.onClose;
  var status = props.isOpen ? "Show" : "Hide";
  var match = onClose !== undefined ? (
      onClickOutside !== undefined ? [
          {
            TAG: "WithCloseButton",
            _0: onClose
          },
          {
            TAG: "Close",
            _0: onClickOutside
          }
        ] : [
          {
            TAG: "WithCloseButton",
            _0: onClose
          },
          "DoNothing"
        ]
    ) : (
      onClickOutside !== undefined ? [
          "WithoutCloseButton",
          {
            TAG: "Close",
            _0: onClickOutside
          }
        ] : [
          "WithoutCloseButton",
          "DoNothing"
        ]
    );
  var alignPosition = Particle_Screen_Ds.make(undefined, undefined, props.align, undefined, undefined, "FullWindow");
  var zIndex = Core__Option.map(props.zIndex, (function (zIndex) {
          return {
                  TAG: "Exact",
                  _0: {
                    TAG: "Player",
                    _0: zIndex
                  }
                };
        }));
  var style = Organism_Tooltip_Ds.makeStyle({
        TAG: "Player",
        _0: alignPosition,
        _1: props.distance,
        _2: zIndex
      });
  return JsxRuntime.jsx(Organism_Tooltip$Internal, {
              children: props.children,
              clickOutsideBehavior: match[1],
              closeButton: match[0],
              content: props.content,
              style: style,
              status: status,
              closeButtonAriaLabel: props.closeButtonAriaLabel
            });
}

var Player = {
  make: Organism_Tooltip$Player
};

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.makeHorizontal("M"), undefined, undefined, Particle_Spacer_Ds.makeHorizontalWithLeftAndRight("M", "XXS"));

var maxWidth = Particle_Screen_Ds.make(undefined, undefined, CssJs.px(611), undefined, undefined, CssJs.vw(100));

function Organism_Tooltip$UserPref$Content(props) {
  var loginButtonId = props.loginButtonId;
  var onClose = props.onClose;
  var match = Context.I18n.getState();
  var match$1 = match.labels.userPref;
  var handleSecurityCenterClick = function (param) {
    onClose();
  };
  var onBlur = function (param) {
    SetFocus.ById.make(loginButtonId);
    onClose();
  };
  return JsxRuntime.jsx(Atom_Cell.make, {
              blockSpace: Caml_option.some(blockSpace),
              maxWidth: Caml_option.some(maxWidth),
              children: JsxRuntime.jsxs(Atom_Column.make, {
                    alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsFlexStart),
                    children: [
                      JsxRuntime.jsx(Atom_Text.make, {
                            children: Caml_option.some(match$1.message),
                            style: Atom_Text_Ds.UserPref.userPrefMessage
                          }),
                      JsxRuntime.jsx(Atom_Cta.Link.make, {
                            href: props.settingsHref,
                            id: props.paramButtonId,
                            label: match$1.setupCookies,
                            onBlur: onBlur,
                            onClick: handleSecurityCenterClick,
                            style: Atom_Cta_Ds.UserPref.openSecurityCenter
                          })
                    ],
                    innerSpace: Caml_option.some(Particle_Screen_Constants.sInnerSpace),
                    justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentSpaceBetween)
                  })
            });
}

var Content = {
  make: Organism_Tooltip$UserPref$Content
};

var UserPref = {
  blockSpace: blockSpace,
  maxWidth: maxWidth,
  Content: Content
};

function getTopLeftVisibility(centerHoverRef, tooltipRef) {
  var buttonSize = Util_Dom.getElementSize(Util_Dom.getDomRef(centerHoverRef));
  var tooltipSize = Util_Dom.getElementSize(Util_Dom.getDomRef(tooltipRef));
  var top = buttonSize.top + Organism_Tooltip_Css.arrowSize + buttonSize.height | 0;
  var middle = tooltipSize.width / 2 - buttonSize.width / 2;
  var left = buttonSize.left - middle | 0;
  var visibility = tooltipSize.width === 0 ? "hidden" : "visible";
  return [
          top,
          left,
          visibility
        ];
}

function make(param) {
  var tooltipRef = param.tooltipRef;
  var centerTooltipRef = param.centerTooltipRef;
  var status = param.status;
  var match = React.useState(function () {
        return Organism_Tooltip_Ds.makeStyle({
                    TAG: "PresseDownload",
                    _0: 0,
                    _1: 0,
                    _2: "hidden"
                  });
      });
  var setStyle = match[1];
  React.useEffect((function () {
          var isMount = {
            contents: true
          };
          if (isMount.contents === true) {
            var match = getTopLeftVisibility(centerTooltipRef, tooltipRef);
            var visibility = match[2];
            var left = match[1];
            var top = match[0];
            setStyle(function (param) {
                  return Organism_Tooltip_Ds.makeStyle({
                              TAG: "PresseDownload",
                              _0: top,
                              _1: left,
                              _2: visibility
                            });
                });
          }
          return (function () {
                    isMount.contents = false;
                  });
        }), [
        centerTooltipRef,
        tooltipRef,
        status
      ]);
  return JsxRuntime.jsx(Organism_Tooltip$Internal, {
              children: param.children,
              clickOutsideBehavior: param.clickOutsideBehavior,
              closeButton: "WithoutCloseButton",
              content: param.content,
              style: match[0],
              status: status,
              containerRef: tooltipRef,
              onMouseEnter: param.onMouseEnter,
              onMouseLeave: param.onMouseLeave
            });
}

var Presse = {
  getTopLeftVisibility: getTopLeftVisibility,
  make: make
};

function getTopLeftVisibility$1(centerHoverRef, tooltipRef) {
  var buttonSize = Util_Dom.getElementSize(Util_Dom.getDomRef(centerHoverRef));
  var tooltipSize = Util_Dom.getElementSize(Util_Dom.getDomRef(tooltipRef));
  var top = buttonSize.top - Organism_Tooltip_Css.arrowSize - buttonSize.height - 10 | 0;
  var middle = tooltipSize.width / 2 - buttonSize.width / 2;
  var left = buttonSize.left - middle | 0;
  var visibility = tooltipSize.width === 0 ? "hidden" : "visible";
  return [
          top,
          left,
          visibility
        ];
}

function make$1(param) {
  var tooltipRef = param.tooltipRef;
  var centerTooltipRef = param.centerTooltipRef;
  var status = param.status;
  var match = React.useState(function () {
        return Organism_Tooltip_Ds.makeStyle({
                    TAG: "Program",
                    _0: 0,
                    _1: 0,
                    _2: "hidden"
                  });
      });
  var setStyle = match[1];
  React.useEffect((function () {
          var isMount = {
            contents: true
          };
          if (isMount.contents === true) {
            var match = getTopLeftVisibility$1(centerTooltipRef, tooltipRef);
            var visibility = match[2];
            var left = match[1];
            var top = match[0];
            setStyle(function (param) {
                  return Organism_Tooltip_Ds.makeStyle({
                              TAG: "Program",
                              _0: top,
                              _1: left,
                              _2: visibility
                            });
                });
          }
          return (function () {
                    isMount.contents = false;
                  });
        }), [
        centerTooltipRef,
        tooltipRef,
        status
      ]);
  return JsxRuntime.jsx(Organism_Tooltip$Internal, {
              children: param.children,
              clickOutsideBehavior: param.clickOutsideBehavior,
              closeButton: "WithoutCloseButton",
              content: param.content,
              style: match[0],
              status: status,
              containerRef: tooltipRef,
              onMouseEnter: param.onMouseEnter,
              onMouseLeave: param.onMouseLeave
            });
}

var Program = {
  getTopLeftVisibility: getTopLeftVisibility$1,
  make: make$1
};

export {
  Internal ,
  Player ,
  UserPref ,
  Presse ,
  Program ,
}
/* defaultContainerBlockspace Not a pure module */
