// Generated by ReScript, PLEASE EDIT WITH CARE

import * as MyResult from "../MyResult.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Type_String from "../type/Type_String.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as FeatureFlipping from "../FeatureFlipping.res.mjs";

function isTeaserAvailableDefault(param) {
  return true;
}

function onLogoutCallback() {
  console.log("Account logout");
}

function onPurgeCallback(purgeType) {
  if (purgeType === "History") {
    console.log("History purge");
    return ;
  }
  console.log("Favorites purge");
}

var defaultApi_loadNextPage = (function (param) {
    return Promise.resolve(null);
  });

var defaultApi_isTeaserAvailable = isTeaserAvailableDefault;

var defaultApi_getNextPageUrl = (function (_currentUrl, nextPageUrl) {
    return nextPageUrl;
  });

var defaultApi_getPageUrl = (function (currentUrl, _pageUrl) {
    return currentUrl;
  });

var defaultApi_getTrailerUrl = (function (url) {
    return url + "/trailer";
  });

var defaultApi_getChapterUrl = (function (baseUrl, timecode) {
    return baseUrl + "/?t=" + String(timecode);
  });

var defaultApi_showPrivacyCenter = (function (param) {
    
  });

var defaultApi_subscribeToNewsletter = (function (param, param$1, param$2) {
    return Promise.resolve(MyResult.fromResult({
                    TAG: "Error",
                    _0: Type_String.SubscribeToNewsletter.$$Error.make("default method, please provide a method subscribeToNewsletter")
                  }));
  });

var defaultApi_onLogoutCallback = onLogoutCallback;

var defaultApi_onPurgeCallback = onPurgeCallback;

var defaultApi_consentStatus = "unknown";

var defaultApi = {
  loadNextPage: defaultApi_loadNextPage,
  isTeaserAvailable: defaultApi_isTeaserAvailable,
  getNextPageUrl: defaultApi_getNextPageUrl,
  getPageUrl: defaultApi_getPageUrl,
  getTrailerUrl: defaultApi_getTrailerUrl,
  getChapterUrl: defaultApi_getChapterUrl,
  showPrivacyCenter: defaultApi_showPrivacyCenter,
  subscribeToNewsletter: defaultApi_subscribeToNewsletter,
  onLogoutCallback: defaultApi_onLogoutCallback,
  onPurgeCallback: defaultApi_onPurgeCallback,
  consentStatus: defaultApi_consentStatus
};

function defaultEnsuredApi_loadNextPage(param) {
  return Promise.resolve(null);
}

function defaultEnsuredApi_getNextPageUrl(_currentUrl, nextPageUrl) {
  return nextPageUrl;
}

function defaultEnsuredApi_getPageUrl(currentUrl, _page) {
  return currentUrl;
}

function defaultEnsuredApi_getTrailerUrl(url) {
  return url + "/trailer";
}

function defaultEnsuredApi_getChapterUrl(baseUrl, timecode) {
  return baseUrl + "/?t=" + String(timecode);
}

function defaultEnsuredApi_showPrivacyCenter(param) {
  
}

function defaultEnsuredApi_subscribeToNewsletter(param, param$1, param$2) {
  return Promise.resolve(MyResult.fromResult({
                  TAG: "Error",
                  _0: Type_String.SubscribeToNewsletter.$$Error.make("there is an error")
                }));
}

function defaultEnsuredApi_goBack() {
  console.log("goBack not implemented");
}

function defaultEnsuredApi_startAgeVerification() {
  console.log("startAgeVerification not implemented");
}

var defaultEnsuredApi = {
  isTeaserAvailable: isTeaserAvailableDefault,
  loadNextPage: defaultEnsuredApi_loadNextPage,
  getNextPageUrl: defaultEnsuredApi_getNextPageUrl,
  getPageUrl: defaultEnsuredApi_getPageUrl,
  getTrailerUrl: defaultEnsuredApi_getTrailerUrl,
  getChapterUrl: defaultEnsuredApi_getChapterUrl,
  showPrivacyCenter: defaultEnsuredApi_showPrivacyCenter,
  subscribeToNewsletter: defaultEnsuredApi_subscribeToNewsletter,
  onLogoutCallback: onLogoutCallback,
  onPurgeCallback: onPurgeCallback,
  consentStatus: "unknown",
  goBack: defaultEnsuredApi_goBack,
  startAgeVerification: defaultEnsuredApi_startAgeVerification
};

function ensureApi(api) {
  if (api !== undefined) {
    return {
            isTeaserAvailable: Core__Option.getOr(api.isTeaserAvailable, isTeaserAvailableDefault),
            loadNextPage: Core__Option.getOr(api.loadNextPage, defaultEnsuredApi_loadNextPage),
            getNextPageUrl: Core__Option.getOr(Core__Option.map(api.getNextPageUrl, (function (getNextPageUrl) {
                        return function (baseUrl, nextPage) {
                          return getNextPageUrl(baseUrl, nextPage);
                        };
                      })), defaultEnsuredApi_getNextPageUrl),
            getPageUrl: Core__Option.getOr(Core__Option.map(api.getPageUrl, (function (getPageUrl) {
                        return function (baseUrl, page) {
                          return getPageUrl(baseUrl, page);
                        };
                      })), defaultEnsuredApi_getPageUrl),
            getTrailerUrl: Core__Option.getOr(api.getTrailerUrl, defaultEnsuredApi_getTrailerUrl),
            getChapterUrl: Core__Option.getOr(api.getChapterUrl, defaultEnsuredApi_getChapterUrl),
            showPrivacyCenter: Core__Option.getOr(api.showPrivacyCenter, defaultEnsuredApi_showPrivacyCenter),
            subscribeToNewsletter: Core__Option.getOr(api.subscribeToNewsletter, defaultEnsuredApi_subscribeToNewsletter),
            onLogoutCallback: Core__Option.getOr(api.onLogoutCallback, onLogoutCallback),
            onPurgeCallback: Core__Option.getOr(api.onPurgeCallback, onPurgeCallback),
            consentStatus: Core__Option.getOr(api.consentStatus, "unknown"),
            goBack: Core__Option.getOr(api.goBack, defaultEnsuredApi_goBack),
            startAgeVerification: Core__Option.getOr(api.startAgeVerification, defaultEnsuredApi_startAgeVerification)
          };
  } else {
    return defaultEnsuredApi;
  }
}

var initialState_cdnUrl = Type_String.CdnUrl.empty;

var initialState_featureFlippings = FeatureFlipping.make(undefined);

var initialState_trackPixel = null;

var initialState_trackUserClick = null;

var initialState_serverTime = new Date();

var initialState = {
  api: defaultEnsuredApi,
  baseUrl: "",
  cdnUrl: initialState_cdnUrl,
  createVideoElement: undefined,
  featureFlippings: initialState_featureFlippings,
  signinUrl: "",
  trackPixel: initialState_trackPixel,
  trackUserClick: initialState_trackUserClick,
  vodStoreHomepageUrl: undefined,
  playStoreUrl: undefined,
  appStoreUrl: undefined,
  serverTime: initialState_serverTime,
  apiRestrictionTTL: 1
};

function ensureVodStoreHomepageUrl(string) {
  if (string == null) {
    return ;
  } else {
    return Caml_option.some(string);
  }
}

function ensurePlayStoreUrl(string) {
  return Core__Option.map((string == null) ? undefined : Caml_option.some(string), Type_String.PlayStoreUrl.make);
}

function ensureAppStoreUrl(string) {
  return Core__Option.map((string == null) ? undefined : Caml_option.some(string), Type_String.AppStoreUrl.make);
}

function getFeatureFlippingStatus(requestedFeature, state) {
  return FeatureFlipping.getStatus(requestedFeature, state.featureFlippings);
}

var Helpers = {
  ensureVodStoreHomepageUrl: ensureVodStoreHomepageUrl,
  ensurePlayStoreUrl: ensurePlayStoreUrl,
  ensureAppStoreUrl: ensureAppStoreUrl,
  getFeatureFlippingStatus: getFeatureFlippingStatus
};

var consentStatus = "unknown";

export {
  isTeaserAvailableDefault ,
  onLogoutCallback ,
  onPurgeCallback ,
  consentStatus ,
  defaultApi ,
  defaultEnsuredApi ,
  ensureApi ,
  initialState ,
  Helpers ,
}
/* initialState Not a pure module */
