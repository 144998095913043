// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Atom_Box from "./Atom_Box.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Border_Ds from "../particle/Particle_Border_Ds.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Border_Css from "../particle/Particle_Border_Css.res.mjs";
import * as Particle_Spacer_Css from "../particle/Particle_Spacer_Css.res.mjs";

var default_border = Particle_Border_Ds.Divider.$$default;

var default_blockSpace = Particle_Spacer_Ds.makeNone();

var $$default = {
  border: default_border,
  blockSpace: default_blockSpace,
  opacity: 1.0
};

var menuNavigation_border = Particle_Border_Ds.Divider.menuNavigation;

var menuNavigation_blockSpace = Particle_Spacer_Ds.Outside.makeAll("XS");

var menuNavigation = {
  border: menuNavigation_border,
  blockSpace: menuNavigation_blockSpace,
  opacity: 1.0
};

var headerSocialLink_border = Particle_Border_Ds.Header.socialLink;

var headerSocialLink_blockSpace = Particle_Spacer_Ds.makeNone();

var headerSocialLink = {
  border: headerSocialLink_border,
  blockSpace: headerSocialLink_blockSpace,
  opacity: 1.0
};

var footer_border = Particle_Border_Ds.Divider.footer;

var footer_blockSpace = Particle_Spacer_Ds.Outside.makeAll("XS");

var footer = {
  border: footer_border,
  blockSpace: footer_blockSpace,
  opacity: 1.0
};

var corporateMenu_border = Particle_Border_Ds.Corporate.menuDivider;

var corporateMenu_blockSpace = Particle_Spacer_Ds.Outside.makeVerticalAndHorizontal("Zero", "XXS");

var corporateMenu = {
  border: corporateMenu_border,
  blockSpace: corporateMenu_blockSpace,
  opacity: 1.0
};

var headerPanel_border = Particle_Border_Ds.Header.userPanel;

var headerPanel_blockSpace = Particle_Spacer_Ds.makeNone();

var headerPanel = {
  border: headerPanel_border,
  blockSpace: headerPanel_blockSpace,
  opacity: 0.8
};

var user_border = Particle_Border_Ds.Divider.user;

var user_blockSpace = Particle_Spacer_Ds.Outside.makeVerticalAndHorizontal("XS", "Zero");

var user = {
  border: user_border,
  blockSpace: user_blockSpace,
  opacity: 1.0
};

var presse_border = Particle_Border_Ds.Presse.guide;

var presse_blockSpace = Particle_Spacer_Ds.Outside.makeNone();

var presse = {
  border: presse_border,
  blockSpace: presse_blockSpace,
  opacity: 0.1
};

function settingsMenu(itemDivider) {
  var border = itemDivider === "Normal" ? Particle_Border_Ds.Settings.menu : Particle_Border_Ds.Settings.menuLastItem;
  return {
          border: border,
          blockSpace: Particle_Spacer_Ds.makeNone(),
          opacity: 1.0
        };
}

var player_border = Particle_Border_Ds.Divider.player;

var player_blockSpace = Particle_Spacer_Ds.makeAll("XS");

var player = {
  border: player_border,
  blockSpace: player_blockSpace,
  opacity: 0.1
};

var contentCollectionMeta_border = Particle_Border_Ds.Divider.contentCollectionMeta;

var contentCollectionMeta_blockSpace = Particle_Spacer_Ds.makeNone();

var contentCollectionMeta = {
  border: contentCollectionMeta_border,
  blockSpace: contentCollectionMeta_blockSpace,
  opacity: 1.0
};

var userNavigation_border = Particle_Border_Ds.Divider.userNavigation;

var userNavigation_blockSpace = Particle_Spacer_Ds.makeNone();

var userNavigation = {
  border: userNavigation_border,
  blockSpace: userNavigation_blockSpace,
  opacity: 1.0
};

function view(param) {
  return {
          border: param.border,
          blockSpace: param.blockSpace,
          opacity: param.opacity
        };
}

function toCss(dsStyle) {
  var match = view(dsStyle);
  return [
              CssJs.padding(CssJs.px(0)),
              CssJs.opacity(match.opacity)
            ].concat(Particle_Border_Css.toCss(match.border)).concat(Particle_Spacer_Css.toCss(match.blockSpace));
}

function Atom_Divider(props) {
  var __style = props.style;
  var style = __style !== undefined ? __style : $$default;
  var styleRules = toCss(style);
  return JsxRuntime.jsx(Atom_Box.make, {
              display: props.display,
              kind: props.kind,
              rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, styleRules)),
              width: props.width
            });
}

var make = Atom_Divider;

export {
  menuNavigation ,
  footer ,
  contentCollectionMeta ,
  userNavigation ,
  corporateMenu ,
  settingsMenu ,
  user ,
  headerSocialLink ,
  player ,
  presse ,
  headerPanel ,
  make ,
}
/* default Not a pure module */
