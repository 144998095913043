'use client'
// Generated by ReScript, PLEASE EDIT WITH CARE

import * as User from "../../../user/User.res.mjs";
import * as React from "react";
import * as Context from "../../../provider/Context.res.mjs";
import * as UseForm from "../../../hook/UseForm.res.mjs";
import * as Atom_Cta from "../../../design-system/atom/Atom_Cta.res.mjs";
import * as Atom_Text from "../../../design-system/atom/Atom_Text.res.mjs";
import * as Atom_Column from "../../../design-system/atom/Atom_Column.res.mjs";
import * as Atom_Cta_Ds from "../../../design-system/atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Layout_Auth from "../layout/Layout_Auth.res.mjs";
import * as Atom_Divider from "../../../design-system/atom/Atom_Divider.res.mjs";
import * as Atom_Text_Ds from "../../../design-system/atom/Atom_Text_Ds.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as UseUserFetch from "../../../hook/UseUserFetch.res.mjs";
import * as Atom_Textfield from "../../../design-system/atom/Atom_Textfield.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../design-system/particle/Particle_Screen_Ds.res.mjs";
import * as User_Api__SsoLogin from "../../../user/User_Api/User_Api__SsoLogin.res.mjs";
import * as User_Api__MagicLink from "../../../user/User_Api/User_Api__MagicLink.res.mjs";
import * as User_Api__Sso__Token from "../../../user/User_Api/User_Api__Sso__Token.res.mjs";
import * as Molecule_DangerousHtml from "../../../design-system/molecule/Molecule_DangerousHtml.res.mjs";
import * as User_Api__Sso__Password from "../../../user/User_Api/User_Api__Sso__Password.res.mjs";
import * as Particle_Screen_Constants from "../../../design-system/particle/Particle_Screen_Constants.res.mjs";

function reducer(state, action) {
  switch (action) {
    case "DisplayForgotPassword" :
        return "ForgotPassword";
    case "DisplayForgotPasswordSuccess" :
        return "ForgotPasswordSuccess";
    case "DisplayLogin" :
        return "Login";
    case "DisplayMagicLink" :
        return "MagicLink";
    
  }
}

function make() {
  var match = React.useReducer(reducer, "Login");
  var dispatch = match[1];
  return {
          state: match[0],
          displayForgotPassword: (function (param) {
              dispatch("DisplayForgotPassword");
            }),
          displayForgotPasswordSuccess: (function () {
              dispatch("DisplayForgotPasswordSuccess");
            }),
          displayLogin: (function (param) {
              dispatch("DisplayLogin");
            }),
          displayMagicLink: (function (param) {
              dispatch("DisplayMagicLink");
            })
        };
}

var UseLoginStates = {
  reducer: reducer,
  initialState: "Login",
  make: make
};

var noMailInnerSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "S");

function Page_Login$Success(props) {
  var origin = props.origin;
  var match = Context.I18n.getState();
  var match$1 = match.formaters;
  var match$2 = match.labels.mona.login;
  var email = Core__Option.getOr(props.email, "");
  var match$3 = Context.I18n.getState();
  var match$4 = match$3.labels.mona.magicLink;
  var dangerousHtml;
  dangerousHtml = origin === "ForgotPassword" ? match$1.forgottenPasswordSuccess(email.replace(email, "<b>" + email + "</b>")) : match$1.magicLink(email.replace(email, "<b>" + email + "</b>"));
  var displayLoginButton;
  displayLoginButton = origin === "ForgotPassword" ? JsxRuntime.jsx(Atom_Column.make, {
          children: JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                label: match$2.requestPasswordSuccessButton,
                onClick: props.displayLogin,
                variant: "base"
              })
        }) : null;
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(JsxRuntime.jsx(Molecule_DangerousHtml.make, {
                                dangerousHtml: dangerousHtml
                              })),
                      style: Atom_Text_Ds.User.text("Auth")
                    }),
                displayLoginButton,
                JsxRuntime.jsxs(Atom_Column.make, {
                      alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
                      children: [
                        JsxRuntime.jsx(Atom_Text.make, {
                              children: Caml_option.some(match$4.subtitle),
                              kind: "h2",
                              style: Atom_Text_Ds.User.textBold
                            }),
                        JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                              label: match$4.reSendMailButton,
                              onClick: props.onResendEmail,
                              variant: "link"
                            })
                      ],
                      innerSpace: Caml_option.some(noMailInnerSpace)
                    }),
                JsxRuntime.jsx(Atom_Divider.make, {
                      style: Atom_Divider.user,
                      width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                    })
              ],
              innerSpace: Caml_option.some(Layout_Auth.Form_Css.wrapperInnerSpace),
              width: Caml_option.some(Layout_Auth.Form_Css.wrapperWidth)
            });
}

var Success = {
  noMailInnerSpace: noMailInnerSpace,
  make: Page_Login$Success
};

function get(values, field) {
  return values.email;
}

function set(values, field, value) {
  return {
          email: value
        };
}

var FormFields = {
  get: get,
  set: set
};

function makeInitialState(email) {
  return {
          email: Core__Option.getOr(email, "")
        };
}

var Form = UseForm.Make({
      set: set,
      get: get
    });

function makeSchema() {
  return Form.Schema.Validation.schema([
              Form.Schema.Validation.nonEmpty("Email"),
              Form.Schema.Validation.email("Email")
            ]);
}

function Page_Login$ForgotPasswordForm(props) {
  var displayForgotPasswordSuccess = props.displayForgotPasswordSuccess;
  var onEmailUpdated = props.onEmailUpdated;
  var controller = new AbortController();
  var signal = controller.signal;
  var schema = makeSchema();
  var match = Context.I18n.getState();
  var locale = match.locale;
  var labels = match.labels;
  var match$1 = labels.mona.register;
  var match$2 = labels.mona.profile;
  var errorMessage = match$2.errorMessage;
  var emailPlaceholder = match$2.emailPlaceholder;
  var match$3 = labels.mona.login;
  var match$4 = UseUserFetch.make();
  var setLoading = match$4.setLoading;
  var setError = match$4.setError;
  var handleSubmit = async function (param) {
    var match = param.state.values;
    setLoading();
    var response = await User_Api__Sso__Password.forgot({
          args: {
            email: match.email
          },
          signal: signal,
          locale: locale
        });
    if (response.TAG === "Ok") {
      displayForgotPasswordSuccess();
    } else {
      setError(errorMessage);
    }
    return {
            TAG: "Ok",
            _0: undefined
          };
  };
  var form = Form.use(makeInitialState(props.email), schema, handleSubmit, undefined, labels, match.formaters);
  var match$5 = form.values;
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    form.submit();
  };
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsx(Atom_Text.make, {
                      children: Caml_option.some(match$3.requestPasswordSubtitle),
                      kind: "h2",
                      style: Atom_Text_Ds.User.textBold
                    }),
                JsxRuntime.jsx(Atom_Column.make, {
                      children: JsxRuntime.jsx(Atom_Textfield.User.Readonly.make, {
                            value: match$5.email,
                            label: emailPlaceholder,
                            name: emailPlaceholder,
                            inputRef: form.getFieldRef({
                                  TAG: "Field",
                                  _0: "Email"
                                }),
                            action: {
                              TAG: "Unique",
                              onClick: (function (param) {
                                  onEmailUpdated();
                                }),
                              style: Atom_Cta_Ds.User.textfieldAction,
                              label: match$1.emailActionLabel,
                              accessibilityLabel: match$1.emailAccessibilityLabel
                            }
                          }),
                      innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                    }),
                match$4.component,
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                              label: match$3.requestPasswordButton,
                              variant: "base",
                              type_: "submit"
                            }),
                        JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                              label: match$3.requestPasswordCancelButton,
                              onClick: props.displayLogin,
                              variant: "link"
                            })
                      ],
                      innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                    }),
                JsxRuntime.jsx(Atom_Divider.make, {
                      style: Atom_Divider.user
                    })
              ],
              innerSpace: Caml_option.some(Layout_Auth.Form_Css.wrapperInnerSpace),
              kind: {
                TAG: "Form",
                _0: onSubmit
              },
              width: Caml_option.some(Layout_Auth.Form_Css.wrapperWidth)
            });
}

var ForgotPasswordForm = {
  FormFields: FormFields,
  makeInitialState: makeInitialState,
  Form: Form,
  makeSchema: makeSchema,
  make: Page_Login$ForgotPasswordForm
};

function get$1(values, field) {
  if (field === "Email") {
    return values.email;
  } else {
    return values.password;
  }
}

function set$1(values, field, value) {
  if (field === "Email") {
    return {
            email: value,
            password: values.password
          };
  } else {
    return {
            email: values.email,
            password: value
          };
  }
}

var FormFields$1 = {
  get: get$1,
  set: set$1
};

function makeInitialState$1(email) {
  return {
          email: Core__Option.getOr(email, ""),
          password: ""
        };
}

var Form$1 = UseForm.Make({
      set: set$1,
      get: get$1
    });

function makeSchema$1() {
  return Form$1.Schema.Validation.schema([
              Form$1.Schema.Validation.nonEmpty("Email"),
              Form$1.Schema.Validation.email("Email"),
              Form$1.Schema.Validation.nonEmpty("Password")
            ]);
}

async function loginUser(locale, email, password, callback, invalidCredentials, emailNotVerified, errorMessage) {
  var anonymousToken = User.getAnonymousToken();
  var controller = new AbortController();
  var signal = controller.signal;
  var tokenResponse = await User_Api__Sso__Token.WithPassword.call({
        args: {
          anonymousToken: anonymousToken,
          email: email,
          password: password
        },
        signal: signal,
        locale: locale
      });
  if (tokenResponse.TAG === "Ok") {
    var ssoLoginResponse = await User_Api__SsoLogin.SetCustomToken.call({
          args: {
            token: tokenResponse._0
          },
          signal: signal,
          locale: locale
        });
    if (ssoLoginResponse.TAG !== "Ok") {
      return {
              TAG: "Error",
              _0: {
                TAG: "Form",
                _0: errorMessage
              }
            };
    }
    callback();
    return {
            TAG: "Ok",
            _0: undefined
          };
  }
  var match = tokenResponse._0;
  if (typeof match !== "object") {
    if (match === "InvalidAuthent") {
      return {
              TAG: "Error",
              _0: {
                TAG: "Field",
                field: "Password",
                error: invalidCredentials
              }
            };
    } else {
      return {
              TAG: "Error",
              _0: {
                TAG: "Form",
                _0: errorMessage
              }
            };
    }
  }
  if (match.TAG !== "BadRequest") {
    return {
            TAG: "Error",
            _0: {
              TAG: "Form",
              _0: errorMessage
            }
          };
  }
  switch (match._0) {
    case "EmailNotVerified" :
        return {
                TAG: "Error",
                _0: {
                  TAG: "Field",
                  field: "Email",
                  error: emailNotVerified
                }
              };
    default:
      return {
              TAG: "Error",
              _0: {
                TAG: "Form",
                _0: errorMessage
              }
            };
  }
}

function Page_Login$LoginForm(props) {
  var onEmailUpdated = props.onEmailUpdated;
  var onLoginCallback = props.onLoginCallback;
  var displayMagicLink = props.displayMagicLink;
  var schema = makeSchema$1();
  var match = Context.I18n.getState();
  var locale = match.locale;
  var labels = match.labels;
  var match$1 = labels.mona.login;
  var passwordPlaceholder = match$1.passwordPlaceholder;
  var invalidCredentials = match$1.invalidCredentials;
  var emailNotVerified = match$1.emailNotVerified;
  var match$2 = labels.mona.register;
  var match$3 = labels.mona.profile;
  var errorMessage = match$3.errorMessage;
  var emailPlaceholder = match$3.emailPlaceholder;
  var match$4 = UseUserFetch.make();
  var setLoading = match$4.setLoading;
  var setIdle = match$4.setIdle;
  var ffMagicLinkConnection = Context.Config.getFeatureFlippingStatus("MagicLinkConnection");
  var handleSubmit = async function (param) {
    setLoading();
    var match = param.state.values;
    var result = await loginUser(locale, match.email, match.password, onLoginCallback, invalidCredentials, emailNotVerified, errorMessage);
    setIdle();
    return result;
  };
  var form = Form$1.use(makeInitialState$1(props.email), schema, handleSubmit, undefined, labels, match.formaters);
  var match$5 = form.values;
  var email = match$5.email;
  var displayMagicLink$1 = function (e) {
    var controller = new AbortController();
    var signal = controller.signal;
    User_Api__MagicLink.send({
            args: {
              email: email
            },
            signal: signal,
            locale: locale
          }).then(function (param) {
          return displayMagicLink(e);
        });
  };
  var onSubmit = function ($$event) {
    $$event.preventDefault();
    form.submit();
  };
  var tmp;
  tmp = ffMagicLinkConnection === "On" ? JsxRuntime.jsx(Atom_Cta.User.Button.make, {
          label: match$1.magicLinkButton,
          onClick: displayMagicLink$1,
          variant: "accordion"
        }) : null;
  return JsxRuntime.jsxs(Atom_Column.make, {
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Textfield.User.Readonly.make, {
                              value: email,
                              label: emailPlaceholder,
                              name: emailPlaceholder,
                              inputRef: form.getFieldRef({
                                    TAG: "Field",
                                    _0: "Email"
                                  }),
                              action: {
                                TAG: "Unique",
                                onClick: (function (param) {
                                    onEmailUpdated();
                                  }),
                                style: Atom_Cta_Ds.User.textfieldAction,
                                label: match$2.emailActionLabel,
                                accessibilityLabel: match$2.emailAccessibilityLabel
                              }
                            }),
                        JsxRuntime.jsx(Atom_Textfield.User.WithError.make, {
                              autoComplete: "current-password",
                              type_: "password",
                              value: match$5.password,
                              label: passwordPlaceholder,
                              onChange: (function (__x) {
                                  form.handleChange("Password", __x);
                                }),
                              id: "password",
                              name: passwordPlaceholder,
                              errorMessage: form.getFieldError({
                                    TAG: "Field",
                                    _0: "Password"
                                  }),
                              inputRef: form.getFieldRef({
                                    TAG: "Field",
                                    _0: "Password"
                                  }),
                              action: {
                                TAG: "Toggle",
                                style: Atom_Cta_Ds.User.textfieldAction,
                                labelOn: match$2.passwordActionLabelOn,
                                labelOff: match$2.passwordActionLabelOff,
                                accessibilityLabelOn: match$2.passwordAccessibilityLabelOn,
                                accessibilityLabelOff: match$2.passwordAccessibilityLabelOff
                              },
                              autoFocus: true
                            })
                      ],
                      innerSpace: Caml_option.some(Layout_Auth.Form_Css.formWrapperInnerSpace)
                    }),
                match$4.component,
                JsxRuntime.jsxs(Atom_Column.make, {
                      children: [
                        JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                              label: match$1.loginButton,
                              variant: "fullWidth",
                              type_: "submit"
                            }),
                        JsxRuntime.jsx(Atom_Cta.User.Button.make, {
                              label: match$1.forgotPassword,
                              onClick: props.displayForgotPassword,
                              variant: "accordion",
                              type_: "reset"
                            }),
                        tmp
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M"))
                    }),
                JsxRuntime.jsx(Atom_Divider.make, {
                      style: Atom_Divider.user
                    })
              ],
              innerSpace: Caml_option.some(Layout_Auth.Form_Css.wrapperInnerSpace),
              kind: {
                TAG: "Form",
                _0: onSubmit
              },
              width: Caml_option.some(Layout_Auth.Form_Css.wrapperWidthNewDesign)
            });
}

var LoginForm = {
  FormFields: FormFields$1,
  makeInitialState: makeInitialState$1,
  Form: Form$1,
  makeSchema: makeSchema$1,
  loginUser: loginUser,
  make: Page_Login$LoginForm
};

function make$1(param) {
  var onEmailUpdated = param.onEmailUpdated;
  var email = param.email;
  var match = Context.I18n.getState();
  var match$1 = match.labels.mona.login;
  var match$2 = make();
  var displayLogin = match$2.displayLogin;
  var displayForgotPassword = match$2.displayForgotPassword;
  var state = match$2.state;
  var title;
  switch (state) {
    case "Login" :
        title = match$1.title;
        break;
    case "ForgotPassword" :
        title = match$1.requestPasswordTitle;
        break;
    case "ForgotPasswordSuccess" :
        title = match$1.requestPasswordSuccessTitle;
        break;
    case "MagicLink" :
        title = match$1.magicLinkTitle;
        break;
    
  }
  var tmp;
  switch (state) {
    case "Login" :
        tmp = JsxRuntime.jsx(Page_Login$LoginForm, {
              displayForgotPassword: displayForgotPassword,
              displayMagicLink: match$2.displayMagicLink,
              onLoginCallback: param.onLoginCallback,
              email: email,
              onEmailUpdated: onEmailUpdated
            });
        break;
    case "ForgotPassword" :
        tmp = JsxRuntime.jsx(Page_Login$ForgotPasswordForm, {
              onEmailUpdated: onEmailUpdated,
              displayForgotPasswordSuccess: match$2.displayForgotPasswordSuccess,
              displayLogin: displayLogin,
              email: email
            });
        break;
    case "ForgotPasswordSuccess" :
        tmp = JsxRuntime.jsx(Page_Login$Success, {
              displayLogin: displayLogin,
              email: email,
              origin: "ForgotPassword",
              onResendEmail: displayForgotPassword
            });
        break;
    case "MagicLink" :
        tmp = JsxRuntime.jsx(Page_Login$Success, {
              displayLogin: displayLogin,
              email: email,
              origin: "MagicLink",
              onResendEmail: displayLogin
            });
        break;
    
  }
  return JsxRuntime.jsx(Layout_Auth.make, {
              title: title,
              footerLinkLabel: match$1.footerLinkLabel,
              footerLinkUrl: match$1.footerLinkUrl,
              children: tmp
            });
}

export {
  UseLoginStates ,
  Success ,
  ForgotPasswordForm ,
  LoginForm ,
  make$1 as make,
}
/* noMailInnerSpace Not a pure module */
