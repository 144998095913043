// Generated by ReScript, PLEASE EDIT WITH CARE

import * as CssJs from "../../CssJs.res.mjs";
import * as Context from "../../provider/Context.res.mjs";
import * as Atom_Text from "../atom/Atom_Text.res.mjs";
import * as Type_Page from "../../type/Type_Page.res.mjs";
import * as Atom_Column from "../atom/Atom_Column.res.mjs";
import * as Atom_Loader from "../atom/Atom_Loader.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as Util_String from "../../util/Util_String.res.mjs";
import * as Zone_Layout from "../../zone/Zone_Layout.res.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.res.mjs";
import * as Layout_Constants from "../layout/Layout_Constants.res.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as Molecule_NoResults from "../molecule/Molecule_NoResults.res.mjs";
import * as Particle_Screen_Ds from "../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Constants from "../particle/Particle_Screen_Constants.res.mjs";
import * as Particle_Spacer_Constants from "../particle/Particle_Spacer_Constants.res.mjs";

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Ds.make("XXS", "M", "XL", "XL"), undefined, undefined, Particle_Spacer_Ds.make("XXS", "S", "M", "M"));

function make(param) {
  var isLoading = Core__Option.getOr(param.isLoading, false);
  var match = Context.I18n.getState();
  var formaters = match.formaters;
  var match$1 = Context.Config.getState();
  var match$2 = match.labels.search;
  var page = Type_Page.fromEmac(formaters.availabilityDate, formaters.betweenDates, formaters.nextBroadcastDate, undefined, undefined, "Search", param.domain, match$1.serverTime, param.page);
  var resultCount = Core__Option.getOr(Caml_option.nullable_to_opt(param.resultCount), 0);
  var renderLoading = isLoading ? JsxRuntime.jsxs(Atom_Column.make, {
          alignItems: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "center")),
          blockSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, Particle_Spacer_Constants.Zone.desktop, undefined, undefined, Particle_Spacer_Constants.Zone.mobile)),
          children: [
            JsxRuntime.jsx(Atom_Loader.make, {}),
            JsxRuntime.jsx(Atom_Text.make, {
                  children: Caml_option.some(match$2.currently_loading)
                })
          ],
          innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M"))
        }) : null;
  var match$3 = Util_String.isEmpty(param.searchValue);
  var renderNoResults = resultCount !== 0 || match$3 ? null : JsxRuntime.jsx(Molecule_NoResults.make, {
          message: param.noResultsMessage,
          advices: param.noResultAdvices
        });
  var renderPage = isLoading ? null : JsxRuntime.jsx(Zone_Layout.make, {
          page: page,
          pageKind: "Search"
        });
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(Atom_Column.make, {
                      blockSpace: Caml_option.some(blockSpace),
                      children: [
                        renderLoading,
                        renderNoResults
                      ],
                      innerSpace: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M")),
                      maxWidth: Caml_option.some(Layout_Constants.maxWidthMainContentScreen),
                      rules: Caml_option.some(Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, [CssJs.margin("auto")])),
                      width: Caml_option.some(Particle_Screen_Constants.oneHundredPctWidth)
                    }),
                renderPage
              ]
            });
}

export {
  blockSpace ,
  make ,
}
/* blockSpace Not a pure module */
