// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Context from "../../../../provider/Context.res.mjs";
import * as Atom_Cta from "../../../atom/Atom_Cta.res.mjs";
import * as Atom_Row from "../../../atom/Atom_Row.res.mjs";
import * as Atom_Image from "../../../atom/Atom_Image.res.mjs";
import * as Atom_Cta_Ds from "../../../atom/Atom_Cta_Ds.res.mjs";
import * as Caml_option from "@rescript/std/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Particle_Screen_Ds from "../../../particle/Particle_Screen_Ds.res.mjs";
import * as Particle_Spacer_Ds from "../../../particle/Particle_Spacer_Ds.res.mjs";
import * as Particle_Screen_Constants from "../../../particle/Particle_Screen_Constants.res.mjs";

var blockSpace = Particle_Screen_Ds.make(undefined, undefined, undefined, Particle_Spacer_Ds.makeTop("XL"), undefined, Particle_Spacer_Ds.makeTop("L"));

var gap = Particle_Screen_Ds.make(undefined, undefined, undefined, undefined, undefined, "M");

function Template_ProgramContent_VodDvd$Display(props) {
  return JsxRuntime.jsxs(Atom_Row.make, {
              alignItems: Caml_option.some(Particle_Screen_Constants.alignItemsCenter),
              blockSpace: Caml_option.some(blockSpace),
              children: [
                JsxRuntime.jsx(Atom_Image.ImageFromCdn.make, {
                      alt: "",
                      style: {
                        TAG: "FromCdn",
                        _0: {
                          TAG: "BuyProgram",
                          _0: props.availability
                        }
                      }
                    }),
                JsxRuntime.jsx(Atom_Cta.Link.make, {
                      href: props.url,
                      label: props.label,
                      style: Atom_Cta_Ds.buyProgram,
                      target: "_blank"
                    })
              ],
              gap: Caml_option.some(gap),
              justifyContent: Caml_option.some(Particle_Screen_Constants.justifyContentFlexStart),
              wrap: "OnOneLine"
            });
}

var Display = {
  blockSpace: blockSpace,
  gap: gap,
  make: Template_ProgramContent_VodDvd$Display
};

function Template_ProgramContent_VodDvd(props) {
  var shopUrl = props.shopUrl;
  var match = Context.Config.getState();
  var vodStoreHomepageUrl = match.vodStoreHomepageUrl;
  var match$1 = Context.I18n.getState();
  var locale = match$1.locale;
  var match$2 = match$1.labels.program;
  if (shopUrl !== undefined) {
    if (locale === "Fr") {
      return JsxRuntime.jsx(Template_ProgramContent_VodDvd$Display, {
                  label: match$2.vod,
                  url: shopUrl,
                  availability: "Available"
                });
    } else {
      return null;
    }
  } else if (locale === "Fr" && vodStoreHomepageUrl !== undefined) {
    return JsxRuntime.jsx(Template_ProgramContent_VodDvd$Display, {
                label: match$2.vod_discover,
                url: vodStoreHomepageUrl,
                availability: "NotAvailable"
              });
  } else {
    return null;
  }
}

var make = Template_ProgramContent_VodDvd;

export {
  Display ,
  make ,
}
/* blockSpace Not a pure module */
